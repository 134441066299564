import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../../utils/mainUrl";
import getAccessConfig from "../../config";

const mainUrl = REACT_APP_MAIN_URL;

export const groupSuggestions = createAsyncThunk(
    "groupSuggestions",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.get(`${mainUrl}/api/group/recommendations/`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }

)

const suggestionGroupSlice = createSlice({
    name: "suggestionGroupSlice",
    initialState: {
        loading: false,
        suggestionGroupList: {},
        error: null,
    },
    extraReducers: (builder) => {
        // Get suggestionGroupList List 
        builder.addCase(groupSuggestions.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(groupSuggestions.fulfilled, (state, action) => {
            state.loading = false;
            state.suggestionGroupList = action.payload
            state.error = null;
        });
        builder.addCase(groupSuggestions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
})
export default suggestionGroupSlice.reducer;
