import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../../utils/mainUrl";
import getAccessConfig from "../../config";

const mainUrl = REACT_APP_MAIN_URL;



export const createGroupPost = createAsyncThunk(
    "createGroupPost",
    async ({ groupId, formData }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.post(`${mainUrl}/api/group/${groupId}/posts/`, formData, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)

export const getGroupPosts = createAsyncThunk(
    "getGroupPosts",
    async ({ groupId }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.get(`${mainUrl}/api/group/${groupId}/posts/`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)
export const deleteGroupPost = createAsyncThunk(
    "deleteGroupPost",
    async ({ postId, groupId }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.delete(`${mainUrl}/api/group/${groupId}/posts/${postId}/`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)

export const updateGroupPost = createAsyncThunk(
    "updateGroupPost",
    async ({ postId, groupId, formData }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.patch(`${mainUrl}/api/group/${groupId}/posts/${postId}/`, formData, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)

export const getIndividualGroupPosts = createAsyncThunk(
    "getIndividualGroupPosts",
    async ({ postId, groupId }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.get(`${mainUrl}/api/group/${groupId}/posts/${postId}/`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)


const groupPosts = createSlice({
    name: "groupPosts",
    initialState: {
        loading: false,
        posts: [],
        error: null,
    },
    extraReducers: (builder) => {
        // Create the group posts 
        builder.addCase(createGroupPost.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(createGroupPost.fulfilled, (state, action) => {
            state.loading = false;
            state.posts = action.payload
            state.error = null;
        });
        builder.addCase(createGroupPost.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // Fetch group posts list
        builder.addCase(getGroupPosts.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getGroupPosts.fulfilled, (state, action) => {
            state.loading = false;
            state.posts = action.payload
            state.error = null;
        });
        builder.addCase(getGroupPosts.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // Fetch group posts list
        builder.addCase(getIndividualGroupPosts.pending, (state) => {
            state.loading = true;
            state.posts = {}
            state.error = null;
        });
        builder.addCase(getIndividualGroupPosts.fulfilled, (state, action) => {
            state.loading = false;
            state.posts = action.payload
            state.error = null;
        });
        builder.addCase(getIndividualGroupPosts.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // updateGroupPost group posts list
        builder.addCase(updateGroupPost.pending, (state) => {
            state.loading = true;
               state.error = null;
        });
        builder.addCase(updateGroupPost.fulfilled, (state) => {
            state.loading = false;
            state.error = null;
        });
        builder.addCase(updateGroupPost.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
})
export default groupPosts.reducer;
