import React, { Fragment, useState } from "react";
import Header from "./Header";
import { previousOrders } from "../../data";
import { BiSolidMessageSquareEdit } from "react-icons/bi";
import EditProductModal from "../common/EditProductModal";

const ProductPost = () => {
   const lable = "C / FE / YOUR PRODUCT POST PAGE / V2 / SEP 20, 2024";

   const [editPost, setEditPost] = useState(false);
   const [editPostId, setEditPostId] = useState(null);
   const handleEditPost = (postId) => {
      setEditPostId(postId);
      setEditPost(!editPost);
   }

   return (
      <Fragment>
         <div className="bg-[#d9d9d9] text-center">
            <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p>
         </div>

         <Header />

         <div className="px-2">
            <div className="mb-28">
               {previousOrders.map((data) => (
                  <div className="border-none border-gray-300 bg-[#f0f0f0] rounded-xl mb-4" key={data.id}>
                     <div className="grid grid-cols-12 p-3">
                        <div className="col-span-8 border-none border-gray-300">
                           <div className="flex flex-col">
                              <p className="text-[13px] text-gray-900 mb-[2px]">
                                 Post Placed: <span className="font-bold text-gray-700">{data.date}</span>
                              </p>
                              <p className="text-[13px] text-gray-900 mb-[2px]">
                                 Total: <span className="font-bold text-gray-700">${data.productPrice}</span>
                              </p>
                              <p className="text-[13px] text-gray-900 mb-[2px]">
                                 Sold No: <span className="font-bold text-gray-700">120</span>
                              </p>
                              <p className="text-[13px] text-gray-900 mb-[2px]">
                                 Post No: <span className="font-bold text-gray-700">#{data.productOrderNumber}</span>
                              </p>
                              <p className="text-[13px] text-gray-900 mb-[2px]">
                                 Category: <span className="font-bold text-gray-700">Electronics</span>
                              </p>
                           </div>
                        </div>
                        <div className="col-span-4 relative">
                           <div className="flex flex-col">
                              <img
                                 alt={data.productName}
                                 src={data.productImg}
                                 className="w-full h-[5rem] rounded object-cover img-shadow"
                              />
                              <p className="text-[10px] line-clamp-2 leading-3 pt-1">{data.productName}</p>
                              <BiSolidMessageSquareEdit 
                                 className="text-blue-500 absolute top-1 right-1 cursor-pointer" 
                                 onClick={() => handleEditPost(data?.productOrderNumber)} 
                              />
                           </div>
                        </div>
                     </div>
                  </div>
               ))}
            </div>
            {editPost && <EditProductModal close={handleEditPost} editPostId={editPostId} />}
         </div>
      </Fragment>
   )
}

export default ProductPost




