import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MdGroups } from "react-icons/md";
import { IoMdStar } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { createGroup, getGroupDetails, updateGroup } from "../../redux/apislice/group/userGroupSlice";
import ScaleLoader from "react-spinners/ScaleLoader";
import toast, { Toaster } from "react-hot-toast";
import { groupsTypes } from "../../data";

const GroupCreate = () => {
   const lable = "C / FE / GROUP CREATE / V1 / JULY 15, 2024";
   const dispatch = useDispatch()
   const navigate = useNavigate();
   const params = useParams();
   const { groupId } = params
   const userGroup = useSelector((state) => state.userGroup)
   const [imgUrl, setImgUrl] = useState(null)
   const { loading, groupDetails } = userGroup;
   const [groupData, setGroupData] = useState({
      name: "",
      type: "",
      purpose: "",
      image: null,
      privacy: "",
      background_image: ""
   })
   const [groupUpdate, setGroupUpdate] = useState({})


   /* GET GROUP DETAILS API CALL */
   const fetchGroupDetails = useCallback(async () => {
      try {
         await dispatch(getGroupDetails({ groupId })).unwrap();
      } catch (error) {
         console.error("Failed to fetch group details:", error);
      }
   }, [groupId, dispatch])

   useEffect(() => {
      fetchGroupDetails()
   }, [fetchGroupDetails])

   useEffect(() => {
      if (groupId && groupDetails) {
         setGroupData({
            name: groupDetails.name || "",
            type: groupDetails.type || "",
            purpose: groupDetails.purpose || "",
            description: groupDetails.description || "",
            image: groupDetails.image || null,
            privacy: groupDetails.privacy || "",
            background_image: groupDetails.background_image || ""
         });
      }
   }, [groupId, groupDetails]);


   const handleChange = (e) => {
      const { name, value, files } = e.target;
      if (groupId) {
         setGroupUpdate((prevData) => ({
            ...prevData,
            [name]: files ? files[0] : name === "privacy" ? (value === "Everyone" ? "public" : "private") : value
         }))
      }
      setGroupData((prevData) => ({
         ...prevData,
         [name]: files ? files[0] : name === "privacy" ? (value === "Everyone" ? "public" : "private") : value
      }));

      if (files) {
         const file = files[0]; // Assuming `files` is your file input
         const url = URL.createObjectURL(file);
         setImgUrl(url);
      }
   };

   const handleCreateGroup = async (e) => {
      e.preventDefault();
      const formData = new FormData();
      for (const key in groupData) {
         if (key !== "background_image") {
            formData.append(key, groupData[key]);
         }
      }
      try {
         const response = await dispatch(createGroup({ formData })).unwrap();
         if (response.error) {
            const errorMessage = response?.name[0];
            toast.error(errorMessage);
         } else {
            toast.success("Group created successfully");

            navigate(`/account/owner/group/${response.id}`); // Navigate to the groups page after successful creation
         }
      } catch (response) {
         const errorMessage = response?.name[0];
         toast.error(errorMessage);
      }
   };

   const handleUpdateGroup = async (e) => {
      e.preventDefault();
      const formData = new FormData();
      for (const key in groupUpdate) {
         if ((key === "image" && groupUpdate[key] instanceof File)) {
            formData.append(key, groupUpdate[key]);
         } else if (key !== "image") {
            if (key !== "background_image") {
               formData.append(key, groupUpdate[key]);
            }
         }
      }
      try {
         const response = await dispatch(updateGroup({ groupId, formData })).unwrap();
         if (response.error) {
            // const errorMessage = response?.name[0];
            // toast.error(errorMessage);
         } else {
            toast.success("Group updated successfully");
            navigate(`/account/owner/group/${groupId}`); // Navigate to the groups page after successful creation
         }
      } catch (response) {
         const errorMessage = response?.name[0];
         toast.error(errorMessage);
      }

   }
   return (
      <form onSubmit={(e) => groupId ? handleUpdateGroup(e) : handleCreateGroup(e)}>
         <Toaster position="top-right" />

         <div className="bg-[#d9d9d9] text-center">
            <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p>
         </div>

         <div className="flex items-center justify-between gap-2 px-2 mt-1">
            <p
               onClick={() => navigate(-1)}
               className="text-[12px] text-black hover:text-red-700 font-bold cursor-pointer">
               Cancel
            </p>
            <div className="flex items-center gap-2">
               <p className="text-[14px]">Create Group</p>
               <MdGroups className="text-[24px]" />
            </div>
            {groupId ?
               <button type="submit" className="text-[12px] text-[#49d7e1] hover:text-[#51edf9] font-bold cursor-pointer bg-transparent">
                  Update
               </button>
               : loading ? <ScaleLoader
                  color={"#000"}
                  loading={loading}
                  height={10}
                  aria-label="Loading Spinner"
                  data-testid="loader"
               /> : <button type="submit" className="text-[12px] text-[#49d7e1] hover:text-[#51edf9] font-bold cursor-pointer bg-transparent">
                  Create
               </button>}

         </div>

         <hr className="my-1 h-[0.5px] bg-gray-400 border-0" />

         <div className="px-2">
            <div className="mt-2">
               <p className="text-[12px] leading-[14px] text-gray-700 mt-1">
                  Tell us a little about your Group. You can always changes these details later.
               </p>
               <div className="mt-1">
                  <div className="flex gap-[2px]">
                     <p className="font-bold text-[13px]">Group Name</p>
                     <IoMdStar className="text-[7px] text-[#f33b3b] mt-[1px]" />
                  </div>
                  <input
                     required
                     type="text"
                     name="name"
                     value={groupData.name}
                     onChange={handleChange}
                     placeholder="Enter Group Name"
                     className="w-full border-b border-gray-500 focus:border-black focus:outline-none placeholder:text-[13px] text-[13px] font-normal"
                  />
                  <p className="text-[12px] leading-[14px] text-gray-700 mt-1">
                     Name must be between 3 and 30 chatacters and can't include hashtag or @username
                  </p>
               </div>
               <div className="mt-3">
                  <div className="flex gap-[2px]">
                     <p className="font-bold text-[13px]">Group Type</p>
                     <IoMdStar className="text-[7px] text-[#f33b3b] mt-[1px]" />
                  </div>
                  {/* <input
                     required
                     type="text"
                     name="type"
                     value={groupData.type}
                     onChange={handleChange}
                     placeholder="Enter Group Type"
                     className="w-full border-b border-gray-500 focus:border-black focus:outline-none placeholder:text-[13px] text-[13px] font-normal"
                  /> */}
                  <select
                     required
                     name="type"
                     value={groupData.type}
                     onChange={handleChange}
                     // style={{ height: '50px', width: '100%' }}
                     className="w-full optional:h-20 border-b border-gray-500 focus:border-black focus:outline-none text-[13px] font-normal"
                  >
                     <option value="" disabled>
                        Select Group Type
                     </option>
                     {groupsTypes.map((category) => (
                        <option key={category.id} value={category.name}>
                           {category.name}
                        </option>
                     ))}
                  </select>
                  <p className="text-[12px] leading-[14px] text-gray-700 mt-1">
                     Group type helps people discover your Group... and it's fun to say!
                  </p>
               </div>
               <div className="mt-3">
                  <div className="flex gap-[2px]">
                     <p className="font-bold text-[13px]">Group purpose</p>
                     <IoMdStar className="text-[7px] text-[#f33b3b] mt-[1px]" />
                  </div>
                  <input
                     required
                     type="text"
                     name="purpose"
                     value={groupData.purpose}
                     onChange={handleChange}
                     placeholder="Enter a Group purpose"
                     className="w-full border-b border-gray-500 focus:border-black focus:outline-none placeholder:text-[13px] text-[13px] font-normal"
                  />
                  <p className="text-[12px] leading-[14px] text-gray-700 mt-1">
                     A strong purpose describes the intent of your Group and helps it stand out
                  </p>
               </div>
               <div className="mt-3">
                  <div className="flex items-center justify-between">
                     <div className="flex gap-[2px]">
                        <p className="font-bold text-[13px]">Membership type</p>
                        <IoMdStar className="text-[7px] text-[#f33b3b] mt-[1px]" />
                     </div>
                     <select
                        name="privacy" value={groupData.privacy === "private" ? "Restricted" : "Everyone"}
                        onChange={handleChange}
                        className="focus:outline-none text-[13px]" required>
                        <option>Everyone</option>
                        <option>Restricted</option>
                     </select>
                  </div>
                  <p className="text-[12px] leading-[14px] text-gray-700 mt-1">
                     Control who can join your Group and see your Group's content and members
                  </p>
               </div>
               <div className="mt-3">
                  <div className="flex gap-[2px]">
                     <p className="font-bold text-[13px]">Select Group cover picture</p>
                     <IoMdStar className="text-[7px] text-[#f33b3b] mt-[1px]" />
                  </div>
                  <p className="text-[12px] leading-[14px] text-gray-700 my-1">
                     A cover picture that represents your Group can help people discover it
                  </p>
                  <input
                     onChange={handleChange}
                     required={groupData.image ? false : true}
                     name="image"
                     type="file"
                     accept="image/*"
                     className="w-full focus:outline-none text-[13px] font-normal"
                  />
               </div>
               <div className="mt-3 mb-24">
                  {groupId && (
                     <div className="grid grid-cols-12 w-full h-auto">
                        <div className="col-span-12 w-full">
                           <img src={imgUrl ? imgUrl : groupData.image} alt="groupProfile" className="object-cover h-auto w-[100%]" />
                        </div>
                     </div>
                  )}
               </div>
            </div>
         </div>
      </form>
   )
}

export default GroupCreate