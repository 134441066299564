import React, { useCallback, useEffect, useState } from "react";
import { LuDot } from "react-icons/lu";
import { FaRegComment, FaRegHeart, FaHeart } from "react-icons/fa";
import { LuRepeat2 } from "react-icons/lu";
import { FaRegBookmark, FaBookmark } from "react-icons/fa6";
import { FiBarChart2 } from "react-icons/fi";
import { AiOutlineShareAlt } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAccountPost, postSave } from "../../redux/apislice/postSlice";
import Skeleton from "react-loading-skeleton";
import { TimeFormat } from "../../hooks/TimeFormat";
import { postLike } from "../../redux/apislice/homeSlice";
import { createRepost } from "../../redux/apislice/repostSlice";

const MainPost = ({ user_id, setShareModal, setPostId }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const postState = useSelector(state => state.post);
    const { loading } = postState;

    const [mainPost, setMainPost] = useState([])


    const handleShare = (id) => {
        setShareModal(true)
        setPostId(id)
    }


    const fetchData = useCallback(async () => {
        await dispatch(getAccountPost(user_id)).then((result) => {
            if (result.error) {
                console.log(result.error)
            } else {
                setMainPost(result?.payload?.results?.posts?.filter(data => data.image === null || data.video === null))
            }
        });
    }, [user_id, dispatch])

    useEffect(() => {
        //get other user account post
        fetchData()
    }, [fetchData])


    const handleLike = async (id) => {
        const response = await dispatch(postLike(id));
        if (response.meta.requestStatus === 'fulfilled') {
            setMainPost((prevResults) =>
                prevResults.map((item) => {
                    if (item.id === id) {
                        // Toggle `userliked` and adjust `likes_count`
                        const isLiked = !item.userliked;
                        const likesCount = isLiked
                            ? item.likes_count + 1
                            : item.likes_count - 1;

                        return {
                            ...item,
                            userliked: isLiked,
                            likes_count: likesCount,

                        };
                    }
                    return item;
                })
            );

            // Refresh mainPost data after successful like
        } else {
            console.error('Like action failed');
        }
    };



    const handleSave = (id) => {
        dispatch(postSave(id)).then((result) => {
            if (result.meta.requestStatus === 'fulfilled') {
                setMainPost((prevResults) =>
                    prevResults.map((item) => {
                        if (item.id === id) {
                            // Toggle `usersaved`
                            return {
                                ...item,
                                usersaved: !item.usersaved,
                            };
                        }
                        return item;
                    })
                );
            } else {
                console.error('Save action failed');
            }
        });
    }



    const handleRepost = (id) => {
        const payloadData = { original_post_id: id }
        dispatch(createRepost(payloadData)).then((result) => {
            if (result.meta.requestStatus === 'fulfilled') {

                setMainPost((prevResults) =>
                    prevResults.map((item) => {
                        if (item.id === id) {
                            // Toggle `userreposted` and adjust `reposts_count`
                            const isReposted = !item.userreposted;
                            const repostsCount = isReposted
                                ? item.reposts_count + 1
                                : item.reposts_count - 1;

                            return {
                                ...item,
                                userreposted: isReposted,
                                reposts_count: repostsCount,

                            };
                        }
                        return item;
                    })
                );
            } else {
                console.error('Repost action failed');
            }
        });
    }





    return (
        <div className="px-0">
            {loading ? [1, 2, 3].map((index) => (
                <div className="mb-2 border border-gray-300 p-1" key={index}>
                    <div className="grid grid-cols-12">
                        <div className="col-span-2">
                            <Skeleton height={43} width={43} />
                        </div>
                        <div className="col-span-10">
                            <div className="flex flex-col">
                                <div className="flex justify-between items-center">
                                    <div className="flex gap-1">
                                        <Skeleton width={100} />
                                        <Skeleton width={45} />
                                    </div>
                                    <Skeleton width={28} />
                                </div>
                                <Skeleton width={100} />
                                <Skeleton count={1} />
                            </div>
                            <Skeleton height={125} />
                            <div className="flex justify-between items-center">
                                <Skeleton height={25} width={25} />
                                <Skeleton height={25} width={25} />
                                <Skeleton height={25} width={25} />
                                <Skeleton height={25} width={25} />
                                <Skeleton height={25} width={25} />
                            </div>
                        </div>
                    </div>
                </div>)) :
                mainPost?.map((data) => {
                    const date = TimeFormat(data?.created_at);
                    if (data.image === null && data.video === null) {
                        return (
                            <div className="px-2 py-1 grid grid-cols-12 border-b-[0.4px] border-[#b2b2b2]" key={data.id}>
                                <div className="col-span-2">
                                    <img
                                        alt="profileimg"
                                        className="h-8 w-8 rounded-full object-cover"
                                        src={data.created_by.avatar}
                                    />
                                </div>
                                <div className="col-span-10 -ml-2">
                                    <div className="flex flex-col">
                                        <div className="flex items-center justify-between relative">
                                            <div className="flex flex-col items-start leading-4 truncate mr-3">
                                                <p className="text-black text-[12px] font-bold">{data.created_by.first_name} {data.created_by.last_name}</p>
                                                <div className="flex items-center">
                                                    <p className="text-[#5c5c5c] text-[12px] font-thin">{data.created_by.username}</p>
                                                    <LuDot className="text-[#5c5c5c] text-[12px]" />
                                                    <p className="text-[#5c5c5c] text-[12px] font-thin">{date}</p>
                                                </div>
                                            </div>
                                            <BsThreeDotsVertical
                                                className="text-[#5c5c5c] cursor-pointer absolute -right-2 top-[2px]"
                                            />
                                        </div>
                                        <p className="text-black text-[12px] mt-2 leading-[15px]">
                                            {data.body}
                                        </p>
                                    </div>
                                    <div className="grid grid-cols-10 items-center mt-2 text-[#4d4d4d]">
                                        <div className="col-span-2">
                                            <div className="flex items-center cursor-pointer gap-1" onClick={() => navigate(`/post/comment/${data.id}`)}>
                                                <FaRegComment className="text-[14px]" />
                                                <p className="text-[12px]">{data.comments_count}</p>
                                            </div>
                                        </div>
                                        <div className="col-span-2">
                                            <div className="flex items-center cursor-pointer gap-1">
                                                {data.userliked ? <FaHeart className="text-[14px] text-red-500" onClick={() => handleLike(data.id)} /> : <FaRegHeart className="text-[14px]" onClick={() => handleLike(data.id)} />}
                                                <p className="text-[12px]">{data.likes_count}</p>
                                            </div>
                                        </div>
                                        <div className="col-span-2">
                                            <div className="flex items-center cursor-pointer gap-1">
                                                <LuRepeat2 className={`text-[14px] ${data?.userreposted ? "text-blue-600" : ""}`} onClick={() => handleRepost(data.id)} />

                                                <p className="text-[12px]">{data?.reposts_count}</p>
                                            </div>
                                        </div>
                                        <div className="col-span-2">
                                            <div className="flex items-center cursor-pointer gap-1">
                                                <FiBarChart2 className="text-[14px]" />
                                                <p className="text-[12px]">{data.view_count}</p>
                                            </div>
                                        </div>
                                        <div className="col-span-2">
                                            <div className="flex items-center justify-end gap-2">
                                                {data.usersaved ?
                                                    <FaBookmark
                                                        className="text-[12px] text-blue-500 cursor-pointer"
                                                    /> :
                                                    <FaRegBookmark
                                                        className="text-[12px] cursor-pointer"
                                                        onClick={() => handleSave(data.id)}
                                                    />}
                                                <AiOutlineShareAlt
                                                    onClick={() => handleShare(data.id)}
                                                    className="text-[14px] cursor-pointer"
                                                // onClick={() => onShare("other/user/account/post")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    } else {
                        return null
                    }
                })}
            {mainPost.filter((data) => data.image === null && data.video === null)?.length === 0 && (
                <div className="text-center text-[#5c5c5c] mt-2 text-[13px] h-36 flex justify-center items-center">
                    <p>No post available</p>
                </div>
            )}
        </div>
    )
}

export default MainPost