import React from "react";

export default function BlockModal({ setBlockUser, unblockUser, unblockData, handleUnBlockUser }) {
    const lable = `C / FE / ${unblockUser ? "UNBLOCK" : "BLOCK"} PAGE / V2 / SEP 13, 2024`;
    const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";

    return (
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-80 flex items-start pt-10 justify-center z-50">
            <div className="bg-white p-4 rounded-lg shadow-lg w-11/12 max-w-lg relative">
                {unblockUser ?
                    <div className="flex justify-center items-center w-full">
                        <div className="grid grid-cols-12">
                            <p className="col-span-12 text-[12px] font-semibold text-center py-1 leading-3">
                                Are you sure you want to unblock {unblockData.username}? Once unblocked,
                                they will be able to view your profile and interact with
                                your content. This action cannot be undone immediately.
                            </p>
                            <div className="col-span-12 flex justify-center items-center my-2 gap-3">
                                <button
                                    onClick={() => setBlockUser(false)}
                                    className="bg-black text-white px-10 py-[3px] rounded-full text-[12px] flex items-center justify-center gap-1"
                                >
                                    No
                                </button>
                                <button
                                    onClick={() => handleUnBlockUser(unblockData.id)}
                                    className="rounded-full text-[12px] px-10 h-7 bg-[#d3d3d3] font-bold hover:bg-[#d3d3d3] text-[#000]"
                                >
                                    Yes
                                </button>
                            </div>
                        </div>
                    </div>
                    :
                    <>
                        <div div className="flex justify-start">
                            <p className="text-[16px] text-[black] font-bold">Block @James?</p>
                        </div>
                        <div className="flex justify-center items-center w-full">
                            <div className="grid grid-cols-12">
                                <p className="col-span-12 text-[12px] py-1 leading-4">
                                    If you block @James, they won't be able to contact you, view your profile,
                                    or interact with your posts. The block will be saved, and your settings will
                                    update automatically. You can unblock @James whenever you like.
                                </p>
                                <div className="col-span-12 flex justify-center items-center my-2 gap-3">
                                    <button
                                        onClick={() => setBlockUser(false)}
                                        className="bg-black text-white px-10 py-[3px] rounded-full text-[12px] flex items-center justify-center gap-1"
                                    >
                                        Block
                                    </button>
                                    <button
                                        onClick={() => setBlockUser(false)}
                                        className="rounded-full text-[12px] px-10 h-7 bg-[#d3d3d3] font-bold hover:bg-[#d3d3d3] text-[#000]"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                }
                <div className="text-center leading-3">
                    <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p>
                    <p className="text-black text-[8px]">{copyright}</p>
                </div>
            </div>
        </div>
    )
}

