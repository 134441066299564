import React, { useState } from "react"

// Pages
import HomePage from "../pages/HomePage";
import Header from "../components/Header";
import Footer from "../components/Footer";
import JoinNowPage from "../pages/JoinNowPage";
import SecurityPage from "../pages/SecurityPage";
import CommentPage from "../pages/CommentPage";

// Auth Pages
import LoginPage from "../pages/LoginPage";
import SignupPage from "../pages/SignupPage";
import NewPassPage from "../pages/NewPassPage";
import RecoverPassPage from "../pages/RecoverPassPage";
import SignupByEmailPage from "../pages/SignupByEmailPage";
import EnterOtpPage from "../pages/EnterOtpPage";


// Marketplace Pages
import SearchPage from "../pages/SearchPage";
import MarketPlacePage from "../pages/MarketPlacePage";
import Trending from "../components/marketplace/Trending";
import Discounted from "../components/marketplace/Discounted";
import PastOrders from "../components/marketplace/PastOrders";
import ProductPost from "../components/marketplace/ProductPost";
import ProductDetails from "../components/marketplace/ProductDetails";
import FavoriteProducts from "../components/marketplace/FavoriteProducts";
import FilterSearch from "../components/marketplace/FilterSearch";
import CustomerSupport from "../components/marketplace/CustomerSupport";
import Advertise from "../components/marketplace/Advertise";

// Account Pages
import AccountPage from "../pages/AccountPage";
import Followers from "../components/account/Followers";
import Following from "../components/account/Following";
import EditAccount from "../components/account/EditAccount";
import Recommended from "../components/account/Recommended";

//Hashtag page 
import HashtagSearch from "../components/hashtag/HashtagSearch";
import HashtagPage from "../pages/HashtagPage";

// Other User Account
import OtherAccount from "../pages/OtherAccount";

// Messages Pages
import MessagePage from "../pages/MessagePage";
import MessageChat from "../components/message/MessageChat";
import MessageSettings from "../components/message/MessageSettings";

// Group Pages
import GroupPage from "../pages/GroupPage";
import GroupMember from "../components/group/GroupMember";
import GroupSearch from "../components/group/GroupSearch";
import GroupCreate from "../components/group/GroupCreate";
import GroupUpload from "../components/group/GroupUpload";
import GroupComment from "../components/group/GroupComment";
import GroupJoinedHome from "../components/group/GroupJoinedHome";
import GroupModerators from "../components/group/GroupModerators";
import GroupPostDetails from "../components/group/GroupPostDetails";
import GroupInviteMember from "../components/group/GroupInviteMember";
import GroupJoinedSearch from "../components/group/GroupJoinedSearch";
import GroupNotification from "../components/group/GroupNotification";
import GroupYourList from "../components/group/accountowner/GroupYourList";

// Other Pages
import PostPage from "../pages/PostPage";
import ErrorPage from "../pages/ErrorPage";
import SavedPost from "../pages/SavedPost";
import Reposts from "../pages/Reposts";
import ForYouPage from "../pages/ForYouPage";
import CookiesPage from "../pages/CookiesPage";
import WhoToFollow from "../pages/WhoToFollow";
import LikedPostsPage from "../pages/LikedPostsPage";
import SearchMainPage from "../pages/SearchMainPage";
import WhatsHappening from "../pages/WhatsHappening";
import NewsRead from "../components/searchMain/NewsRead";
import NotificationPage from "../pages/NotificationPage";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage";
import NewsSeeMore from "../components/searchMain/NewsSeeMore";
import TermsPage from "../pages/TermsPage";
import ContectUsPage from "../pages/ContectUsPage";
import AboutusPage from "../pages/AboutusPage";
import GetHelpPage from "../pages/GetHelpPage";


// Private Route
import PrivateRoute from "../utils/PrivateRoute";

//Post View Details
import PostViewDetails from "../components/post/PostViewDetails";

// Router
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

// Account Owner Group Pages
import GroupOwnerHome from "../components/group/accountowner/GroupOwnerHome";
import AccountOwnerGroupComment from "../components/group/accountowner/AccountOwnerGroupComment";
// import AccountOwnerGroupSearch from "../components/group/accountowner/AccountOwnerGroupSearch";
import AccountOwnerGroupUpload from "../components/group/accountowner/AccountOwnerGroupUpload";
import AccountOwnerGroupMember from "../components/group/accountowner/AccountOwnerGroupMember";
import AccountOwnerGroupModerators from "../components/group/accountowner/AccountOwnerGroupModerators";
import AccountOwnerGroupInviteMember from "../components/group/accountowner/AccountOwnerGroupInviteMember";
import AccountOwnerGroupPostDetails from "../components/group/accountowner/AccountOwnerGroupPostDetails";
// import AccountOwnerGroupNotification from "../components/group/accountowner/AccountOwnerGroupNotification";
import ViewMore from "../components/marketplace/ViewMore";

//settings page
import YourFeedSettingsPage from "../pages/YourFeedSettingsPage";
import BlockedUserList from "../components/settings/BlockedUserList";
import SettingsPage from "../pages/SettingsPage";
import ChangePassword from "../components/settings/ChangePassword";
import OtherUserFollowers from "../components/otherUserAccount/OtherUserFollowers";
import OherUserFollowing from "../components/otherUserAccount/OherUserFollowing";
import OtherUserRecommended from "../components/otherUserAccount/OtherUserRecommended";
import ComingSoon from "../pages/ComingSoon";
import BlockedPosts from "../pages/BlockedPosts";


const MainRouter = () => {
   const [lable, setLable] = useState(null);

   const HeaderConditional = () => {
      const location = useLocation();
      const excludedPaths = [
         "/home"
      ];

      if (excludedPaths.includes(location.pathname)) return <Header lable={lable} />;
      return null;
   };

   const FooterConditional = () => {
      const location = useLocation();
      const excludedPaths = [
         "/", "/*", "/joinnow", "/login", "/recoverpassword", "/newpassword",
         "/signup", "/signupbyemail", "/message/userchating", "/enterotp", 
      ];
      if (excludedPaths.includes(location.pathname)) return null;
      return <Footer />;
   };

   return (
      <BrowserRouter>
         <div className="grid grid-cols-11 h-screen">
            <div className="hidden lg:block col-span-4"></div>

            <div className="col-span-11 lg:col-span-3 lg:flex lg:items-center lg:justify-center">
               <div className="relative h-screen lg:h-[87vh] w-full lg:w-[75%] lg:rounded-[1.4rem] lg:shadow-[0_0px_8px_rgb(0,0,0,0.9)] overflow-hidden">
                  <div className="h-full overflow-y-scroll">
                     <div className="sticky top-0 w-full z-50">
                        <HeaderConditional />
                     </div>
                     <Routes>
                        <Route index element={<SecurityPage />} />
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/signup" element={<SignupPage />} />
                        <Route path="/joinnow" element={<JoinNowPage />} />
                        <Route path="/newpassword" element={<NewPassPage />} />
                        <Route path="/recoverpassword" element={<RecoverPassPage />} />
                        <Route path="/enterotp" element={<EnterOtpPage />} />
                        <Route path="/signupbyemail" element={<SignupByEmailPage />} />

                        <Route path="/cookies" element={<CookiesPage />} />
                        <Route path="/privacy" element={<PrivacyPolicyPage />} />
                        <Route path="/terms" element={<TermsPage />} />
                        <Route path="/contactus" element={<ContectUsPage />} />
                        <Route path="/aboutus" element={<AboutusPage />} />
                        <Route path="/gethelp" element={<GetHelpPage />} />
                        <Route path="/soon" element={<ComingSoon />} />

                        <Route path="/" element={<PrivateRoute />} >
                           <Route path="/post" element={<PostPage />} />
                           <Route path="/post/comment/:id" element={<CommentPage />} />
                           <Route path="/post/edit/:postId" element={<PostPage />} />
                           <Route path="/postview/:postId" element={<PostViewDetails />} />
                           <Route path="/liked/posts" element={<LikedPostsPage />} />


                           <Route path="/home" element={<HomePage setLable={setLable} />} />
                           <Route path="/search" element={<SearchMainPage />} />
                           <Route path="/news/seemore" element={<NewsSeeMore />} />
                           <Route path="/news/read" element={<NewsRead />} />

                           <Route path="/group" element={<GroupPage />} />
                           <Route path="/group/search" element={<GroupSearch />} />
                           <Route path="/group/create" element={<GroupCreate />} />
                           <Route path="/group/update/:groupId" element={<GroupCreate />} />
                           <Route path="/group/upload/:groupId" element={<GroupUpload />} />
                           <Route path="/group/:groupId/update/:postId" element={<GroupUpload />} />
                           <Route path="/group/joined/members" element={<GroupMember />} />
                           <Route path="/group/joined/home/:groupId" element={<GroupJoinedHome />} />
                           <Route path="/group/:groupId/comment/:postId" element={<GroupComment />} />
                           <Route path="/group/joined/search" element={<GroupJoinedSearch />} />
                           <Route path="/group/joined/moderators" element={<GroupModerators />} />
                           <Route path="/group/joined/invitemembers" element={<GroupInviteMember />} />
                           <Route path="/group/:groupId/postview/:postId" element={<GroupPostDetails />} />
                           <Route path="/group/notification/settings" element={<GroupNotification />} />
                           <Route path="/group/notification" element={<GroupNotification />} />

                           {/* Group Owner page  */}
                           <Route path="/account/owner/group/:groupId" element={<GroupOwnerHome />} />
                           <Route path="/account/owner/group/comment" element={<AccountOwnerGroupComment />} />
                           <Route path="/group/list" element={<GroupYourList />} />
                           <Route path="/group/post/search/:groupId" element={<GroupJoinedSearch />} />

                           {/* This component need to remove reminder  */}
                           <Route path="/account/owner/group/upload/:groupId" element={<AccountOwnerGroupUpload />} />

                           <Route path="/account/owner/group/members/:groupId" element={<AccountOwnerGroupMember />} />


                           <Route path="/account/owner/group/moderators/:groupId" element={<AccountOwnerGroupModerators />} />
                           <Route path="/account/owner/group/invitemembers/:groupId" element={<AccountOwnerGroupInviteMember />} />

                           <Route path="/account/owner/group/postview" element={<AccountOwnerGroupPostDetails />} />
                           <Route path="/account/owner/group/notification/settings" element={<GroupNotification />} />

                           {/* Hashtag page */}
                           <Route path="/hashtag/search" element={<HashtagSearch />} />
                           <Route path="/hashtag/:tagname" element={<HashtagPage />} />

                           <Route path="/account" element={<AccountPage />} />
                           <Route path="/account/edit" element={<EditAccount />} />
                           <Route path="/account/following" element={<Following />} />
                           <Route path="/account/followers" element={<Followers />} />
                           <Route path="/account/recommended" element={<Recommended />} />

                           <Route path="/otheruser/account/:user_id" element={<OtherAccount />} />
                           <Route path="/otheruser/account/followers/:user_id" element={<OtherUserFollowers />} />
                           <Route path="/otheruser/account/following/:user_id" element={<OherUserFollowing />} />
                           <Route path="/otheruser/account/Recommended/:user_id" element={<OtherUserRecommended />} />

                           <Route path="/message" element={<MessagePage />} />
                           <Route path="/message/userchating" element={<MessageChat />} />
                           <Route path="/message/settings" element={<MessageSettings />} />

                           <Route path="/marketplace" element={<MarketPlacePage />} />
                           <Route path="/marketplace/search" element={<SearchPage />} />
                           <Route path="/marketplace/trending" element={<Trending />} />
                           <Route path="/marketplace/discounted" element={<Discounted />} />
                           <Route path="/marketplace/pastorders" element={<PastOrders />} />
                           <Route path="/marketplace/productpost" element={<ProductPost />} />
                           <Route path="/marketplace/favorite" element={<FavoriteProducts />} />
                           <Route path="/marketplace/productdetails" element={<ProductDetails />} />
                           <Route path="/marketplace/filter" element={<FilterSearch />} />
                           <Route path="/marketplace/customersupport" element={<CustomerSupport />} />
                           <Route path="/marketplace/advertise" element={<Advertise />} />
                           <Route path="/marketplace/viewmore/:id" element={<ViewMore />} />

                           <Route path="/foryou" element={<ForYouPage />} />
                           <Route path="/follow/suggestion" element={<WhoToFollow />} />
                           <Route path="/trending/hashtag" element={<WhatsHappening />} />
                           <Route path="/save/posts" element={<SavedPost />} />
                           <Route path="/reposts" element={<Reposts />} />
                           <Route path="/notification" element={<NotificationPage />} />

                           <Route path="/settings" element={<SettingsPage />} />
                           <Route path="/blockedusers" element={<BlockedUserList />} />
                           <Route path="/blocked/posts" element={<BlockedPosts />} />
                           <Route path="/settings/changepassword" element={<ChangePassword />} />

                           <Route path="/yourfeed/setting" element={<YourFeedSettingsPage />} />

                           <Route path="/*" element={<ErrorPage setLable={setLable} />} />
                        </Route>
                     </Routes>
                     <div className="absolute bottom-0 w-full z-50">
                        <FooterConditional />
                     </div>
                  </div>
               </div>
            </div>

            <div className="hidden lg:block col-span-4"></div>
         </div>
      </BrowserRouter>
   )
}

export default MainRouter