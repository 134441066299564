import React, { useState, useEffect, useRef } from "react";
import { LuDot } from "react-icons/lu";
import { LuRepeat2 } from "react-icons/lu";
import { FiBarChart2 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { AiOutlineShareAlt } from "react-icons/ai";
import { IoCaretBackCircle } from "react-icons/io5";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaRegComment, FaRegHeart } from "react-icons/fa";
import { FaBookmark } from "react-icons/fa6";
import { removeSavedPost } from "../redux/apislice/postSlice";
import { useSelector, useDispatch } from "react-redux";
import ReactPlayer from "react-player";
import { FaPlayCircle, FaPauseCircle } from "react-icons/fa";
import { GoUnmute, GoMute } from "react-icons/go";
import Skeleton from "react-loading-skeleton";
import toast, { Toaster } from "react-hot-toast";
import ReportModal from "../components/common/ReportModal";
import BlockModal from "../components/common/BlockModal";
import { DecryptFn } from "../hooks/DecryptFn";
import { TimeFormat } from "../hooks/TimeFormat";
import ShareModal from "../components/common/ShareModal";
import { getSavedPost } from "../redux/apislice/savedSilce";



const SavedPost = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [visibleMenuId, setVisibleMenuId] = useState(null);
    const menuRef = useRef(null);
    const iconRef = useRef(null);
    const savedPost = useSelector((state) => state.saved);
    const { saved, loading } = savedPost
    const { saved_posts } = saved
    const [reportPost, setReportPost] = useState(false);
    const [blockUser, setBlockUser] = useState(false);
    const [shareModal, setShareModal] = useState(false);
    const decryptUserId = localStorage.getItem("userId") ? DecryptFn(localStorage.getItem("userId")) : null;


    // VIDEO PLAYER FUNCTIONALITY
    const [playing, setPlaying] = useState({});
    const [muted, setMuted] = useState(false);
    const [playedSeconds, setPlayedSeconds] = useState({});
    const [duration, setDuration] = useState({});

    const handlePlayPause = (id) => {
        setPlaying(prev => {
            const newState = { ...prev };
            Object.keys(newState).forEach(key => {
                newState[key] = false;
            });
            newState[id] = !prev[id]; // Toggle between play and pause
            return newState;
        });
    };

    // SOUND FUNCTIONALITY
    const handleMute = () => {
        setMuted(!muted);
    };

    const handleProgress = (id, state) => {
        setPlayedSeconds(prev => ({ ...prev, [id]: state.playedSeconds }));
    };

    const handleDuration = (id, duration) => {
        setDuration(prev => ({ ...prev, [id]: duration }));
    };

    const formatDuration = (seconds) => {
        const date = new Date(0);
        date.setSeconds(seconds);
        const timeString = date.toISOString().substr(14, 5);
        return timeString;
    };



    const toggleMenu = (id) => {
        setVisibleMenuId((prevId) => (prevId === id ? null : id));
    };

    const handleClickOutside = (event) => {
        if (
            menuRef.current &&
            !menuRef.current.contains(event.target) &&
            iconRef.current &&
            !iconRef.current.contains(event.target)
        ) {
            setVisibleMenuId(null);
        }
    };


    const handleDeleteSavedPost = async (postId) => {
        if (postId) {
            dispatch(removeSavedPost(postId)).then((result) => {
                if (result.meta.requestStatus === 'fulfilled') {
                    const { detail } = result.payload
                    toast.success(detail);
                    dispatch(getSavedPost())
                } else {
                    console.error('Save action failed');
                }
            });
        } else {
            console.error('postId is undefined or null.');
        }
    };


    const handleNavigate = (user_id) => {
        if (user_id === decryptUserId) {
            navigate("/account");
        } else {
            navigate(`/otheruser/account/${user_id}`);
        }
    };



    useEffect(() => {
        dispatch(getSavedPost())
    }, [dispatch])

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="mb-24">
            <Toaster position="top-right" />
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-700 text-[10px] uppercase font-bold">
                    C / FE / FOR YOU / V1 / AUG 14, 2024
                </p>
           
            </div>

            <div className="sticky top-0 bg-[#FF9900] border-b-[0.4px] border-[#b2b2b2] z-50">
                <div className="grid grid-cols-12 px-2 py-[6px]">
                    <div className="col-span-2">
                        <IoCaretBackCircle
                            onClick={() => navigate(-1)}
                            className="h-6 w-6 cursor-pointer text-black"
                        />
                    </div>
                    <div className="col-span-8 flex items-center justify-center">
                        <p className="text-[16px] font-semibold text-[#000000]">Saved Posts</p>
                    </div>
                </div>
            </div>
            {loading ?
                [1, 2, 3].map((index) => (
                    <div className="mb-2 border border-gray-300 p-1" key={index}>
                        <div className="grid grid-cols-12">
                            <div className="col-span-2">
                                <Skeleton height={43} width={43} />
                            </div>
                            <div className="col-span-10">
                                <div className="flex flex-col">
                                    <div className="flex justify-between items-center">
                                        <div className="flex gap-1">
                                            <Skeleton width={100} />
                                            <Skeleton width={45} />
                                        </div>
                                        <Skeleton width={28} />
                                    </div>
                                    <Skeleton width={100} />
                                    <Skeleton count={1} />
                                </div>
                                <Skeleton height={125} />
                                <div className="flex justify-between items-center">
                                    <Skeleton height={25} width={25} />
                                    <Skeleton height={25} width={25} />
                                    <Skeleton height={25} width={25} />
                                    <Skeleton height={25} width={25} />
                                    <Skeleton height={25} width={25} />
                                </div>
                            </div>
                        </div>
                    </div>
                ))
                : <>
                    {saved_posts?.map((data) => {
                        const date = TimeFormat(data?.created_at);
                        return (
                            <div
                                className="px-2 py-1 grid grid-cols-12 border-b-[0.4px] border-[#b2b2b2]"
                                key={data.id}
                            >
                                <div className="col-span-2">
                                    <img
                                        onClick={() => handleNavigate(`${data.created_by.id}`)}
                                        alt={data?.created_by?.first_name}
                                        src={data.created_by?.avatar}
                                        className="h-8 w-8 rounded-full object-cover"
                                    />
                                </div>
                                <div className="col-span-10 -ml-2">
                                    <div className="flex flex-col">
                                        <div className="flex items-center justify-between">
                                            <div className="flex items-center gap-2">
                                                <p className="text-black text-[12px] font-bold cursor-pointer" onClick={() => handleNavigate(`${data.created_by.id}`)}>{data?.created_by?.first_name}{data?.created_by?.last_name}</p>
                                                <div className="flex items-center">
                                                    <p className="text-[#5c5c5c] text-[12px] font-thin cursor-pointer" onClick={() => handleNavigate(`${data.created_by.id}`)}>{data?.created_by?.username}</p>
                                                    <LuDot className="text-[#5c5c5c] text-[12px]" />
                                                    <p className="text-[#5c5c5c] text-[12px] font-thin">{date}</p>
                                                </div>
                                            </div>
                                            <div className="relative" ref={iconRef}>
                                                <BsThreeDotsVertical
                                                    className="text-[#5c5c5c] cursor-pointer absolute -right-2 -top-2"
                                                    onClick={() => toggleMenu(data.id)}
                                                />
                                                {visibleMenuId === data.id && (
                                                    <div className="absolute top-[-8px] bg-[#ebebeb] rounded-md right-[-2px]" ref={menuRef}>
                                                        <p className="px-2 py-[4px] hover:text-red-600 text-[10px] border-b-[0.4px] border-[#b2b2b2] whitespace-nowrap cursor-pointer" onClick={() => handleDeleteSavedPost(data.id)}>
                                                            unsave
                                                        </p>
                                                        <p className="px-2 py-[4px] hover:text-red-600 text-[10px] border-b-[0.4px] border-[#b2b2b2] whitespace-nowrap cursor-pointer" onClick={() => setReportPost(true)}>
                                                            Report
                                                        </p>
                                                        <p className="px-2 py-[4px] hover:text-red-600 text-[10px] whitespace-nowrap cursor-pointer" onClick={() => setBlockUser(true)}>
                                                            Block
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <p className="text-black text-[12px]">{data?.body}</p>
                                    </div>
                                    {data.image === null ? null : (
                                        <img
                                            onClick={() => navigate(`/postview/${data?.id}`)}
                                            alt={data?.image}
                                            src={data?.image}
                                            className="w-full h-auto object-cover rounded-[6px] cursor-pointer"
                                        />
                                    )}

                                    {data?.video &&
                                        <div className="mt-1 rounded-sm relative" >
                                            <ReactPlayer
                                                onClick={() => navigate(`/postview/${data?.id}`)}
                                                url={data.video}
                                                playing={playing[data?.id] || false}
                                                muted={muted}
                                                onProgress={(state) => handleProgress(data?.id, state)}
                                                onDuration={(duration) => handleDuration(data?.id, duration)}
                                                width="100%"
                                                height="100%"
                                                className="cursor-pointer"
                                            />
                                            <button
                                                onClick={() => handlePlayPause(data?.id)}
                                                className="absolute z-30 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#00000052] text-[18px] p-[6px] rounded-full"
                                            >
                                                {playing[data?.id] ? <FaPauseCircle className="text-[#ffffff84]" /> : <FaPlayCircle className="text-[#FF9900]" />}
                                            </button>
                                            <button
                                                onClick={handleMute}
                                                className="absolute z-30 bottom-2 right-2 text-[13px] text-white bg-[#0000008b] p-[4px] rounded-full"
                                            >
                                                {muted ? <GoMute /> : <GoUnmute />}
                                            </button>
                                            <button className="absolute bottom-2 left-2 bg-[#0000008b] text-[8px] text-white px-[4px] py-[2px] rounded-md">
                                                {formatDuration(duration[data?.id] - playedSeconds[data?.id] || 0)}
                                            </button>
                                        </div>
                                    }
                                    <div className="flex items-center justify-between mt-2 text-[#4d4d4d]">
                                        <div className="flex items-center cursor-pointer gap-1">
                                            <FaRegComment className="text-[14px]" />
                                            <p className="text-[12px]">{data?.comments_count}</p>
                                        </div>
                                        <div className="flex items-center cursor-pointer gap-1">
                                            <FaRegHeart className="text-[14px]" />
                                            <p className="text-[12px]">{data?.likes_count}</p>
                                        </div>
                                        <div className="flex items-center cursor-pointer gap-1">
                                            <LuRepeat2 className="text-[14px]" />
                                            <p className="text-[12px]">{data?.postRepost}</p>
                                        </div>
                                        <div className="flex items-center cursor-pointer gap-1">
                                            <FiBarChart2 className="text-[14px]" />
                                            <p className="text-[12px]">{data?.postImpression}</p>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <FaBookmark
                                            onClick={() => handleDeleteSavedPost(data.id)}
                                                className="text-[12px] text-blue-500 cursor-pointer"
                                            />
                                            <AiOutlineShareAlt className="text-[14px] cursor-pointer" onClick={() => setShareModal(true)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    )}
                    {saved_posts?.length === 0 && <div className="h-96 flex justify-center items-center">
                        <p className="text-center text-[12px]  text-gray-600 font-bold mt-2">No saved post found</p>
                    </div>}
                </>
            }
            {/* <ReportModal /> */}
            {reportPost && <ReportModal setReportPost={setReportPost} />}
            {/* BlockModal */}
            {blockUser && <BlockModal setBlockUser={setBlockUser} />}
            {/* ShareModal */}
            {shareModal && <ShareModal url={"https/chirp/saved"} onClose={() => setShareModal(false)} />}
        </div>
    )
}

export default SavedPost