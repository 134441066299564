import React, { Fragment } from "react";
import { post } from "../../data";
import { LuDot } from "react-icons/lu";
import SideMenu from "../SideMenu";
import { useNavigate } from "react-router-dom";
import { RiSettings5Line } from "react-icons/ri";
import { IoCaretBackCircle } from "react-icons/io5";

const MessageList = () => {
   const lable = "C / FE / MESSAGEPAGE / V1 / MAY 06, 2024";

   const navigate = useNavigate();

   return (
      <Fragment>
         <div className="sticky top-0 bg-white z-50">
            <div className="bg-[#d9d9d9] text-center">
               <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p>
            </div>

            <div className="flex items-center justify-between gap-2 px-2 mt-1">
               <IoCaretBackCircle
                  onClick={() => navigate(-1)}
                  className="text-black cursor-pointer text-[32px]"
               />
               <SideMenu />
               <input
                  type="text"
                  placeholder="Search Messages"
                  className="w-full h-8 px-3 border-none transition-all duration-200 rounded-full text-white focus:outline-none focus:bg-[#dedede] focus:text-black bg-black"
               />
               <RiSettings5Line
                  className="h-9 w-9 cursor-pointer transition-all duration-300 hover:rotate-45"
                  onClick={() => navigate("/message/settings")}
               />
            </div>

            <hr className="my-1 h-[0.5px] bg-gray-500 border-0" />
         </div>

         <div className="mt-1">
            {post.map((data) => (
               <div
                  key={data.id}
                  className="flex gap-2 mb-2 hover:bg-gray-300 hover:cursor-pointer px-2 py-1"
                  onClick={() => navigate("/message/userchating")}
               >
                  <img
                     alt={data.userName}
                     src={data.profileImage}
                     className="h-10 w-10 rounded-full object-cover"
                  />
                  <div className="flex flex-col">
                     <div className="flex items-center">
                        <p className="text-sm font-bold">{data.firstName}</p>
                        <p className="text-xs pl-1">{data.userName}</p>
                        <LuDot className="h-3 w-3" />
                        <p className="text-xs">{data.postTimeAgo}</p>
                     </div>
                     <p className="text-[10px] text-gray-600 leading-3 line-clamp-2 mr-4">{data.postMessage}</p>
                  </div>
               </div>
            ))}
         </div>
      </Fragment>
   )
}

export default MessageList