import axios from "axios";
import getAccessConfig from "../config";
import { REACT_APP_MAIN_URL } from "../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const mainUrl = REACT_APP_MAIN_URL

export const fetchTotalUser = createAsyncThunk(
    "fetchTotalUser",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.get(`${mainUrl}/api/total_users_list/`,config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


const totalUserSlice = createSlice({
    name: "totalUserSlice",
    initialState: {
        loading: false,
        totalUser: [],
        error: null,
    },
    extraReducers: (builder) => {
        // Fetch fetchTotalUser
        builder.addCase(fetchTotalUser.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(fetchTotalUser.fulfilled, (state, action) => {
            state.loading = false;
            state.totalUser = action.payload.users;
            state.error = null;
        });
        builder.addCase(fetchTotalUser.rejected, (state) => {
            state.loading = false;
            state.totalUser = null;
            state.error = true;
        });
    }
});

export default totalUserSlice.reducer;