import React, { Fragment, useEffect, useState } from "react";
import MainPost from "../components/post/MainPost";
import ShareModal from "../components/common/ShareModal";
import ReportModal from "../components/common/ReportModal";
import PostBlockModal from "../components/common/PostBlockModal";
import { useDispatch } from "react-redux";
import { postBlock } from "../redux/apislice/postBlockSlice";
// import { setLabel } from "../redux/apislice/copyRightsSlice";

const HomePage = ({ setLable }) => {
   const dispatch = useDispatch();
   const [sharePost, setSharePost] = useState(false);
   const [reportPost, setReportPost] = useState(false);
   const [postId, setPostId] = useState(null);
   const [postData, setPostData] = useState(null);
   const [blockUser, setBlockUser] = useState(false);
   const [MainData, setMainData] = useState([])

   const handleBlockPost = async (postId) => {
      setPostId(postId);

      try {
         const response = await dispatch(postBlock({ postId }));
         if (postBlock.fulfilled.match(response)) {
            setMainData((prevData) => prevData.filter((data) => data.id !== postData?.id));
            setBlockUser(false);
         }
      } catch (error) {
         console.log(error)

      }

   }


   useEffect(() => {
      setLable("C / FE / HomePage / V6 / SEP 05, 2024");
   }, [setLable])
   return (
      <Fragment>
         <div className="absolute h-[-webkit-fill-available] w-full">
            <div className="px-2 h-[-webkit-fill-available] overflow-hidden relative">
               <MainPost
                  setSharePost={setSharePost}
                  setReportPost={setReportPost}
                  setPostId={setPostId}
                  setPostData={setPostData}
                  setBlockUser={setBlockUser}
                  setMainData={setMainData}
                  MainData={MainData}
               />
            </div>

            {sharePost &&
               <ShareModal
                  url={`https://newtestpro.com/postview/${postId}`}
                  onClose={() => setSharePost(false)}
               />
            }

            {/* Report Modal */}
            {reportPost &&
               <ReportModal
                  setReportPost={setReportPost}
                  postId={postId}
                  postData={postData}
               />
            }

            {/* Block Modal */}
            {blockUser &&
               <PostBlockModal
                  setBlockUser={setBlockUser}
                  postData={postData}
                  handleSubmit={handleBlockPost}
               />
            }
         </div>
      </Fragment>
   )
}

export default HomePage