import React, { Fragment, useEffect, useRef, useState } from "react"
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoCaretBackCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

export default function YourFeedSettingsPage() {
    const navigate = useNavigate();
    const threeDotsRef = useRef(null);
    const [menuOpen, setMenuOpen] = useState(false);

    const [isChecked1, setIsChecked1] = useState(false);
    const [isChecked2, setIsChecked2] = useState(false);
    const [isChecked3, setIsChecked3] = useState(false);
    const handleCheckbox1 = () => { setIsChecked1(!isChecked1) };
    const handleCheckbox2 = () => { setIsChecked2(!isChecked2) };
    const handleCheckbox3 = () => { setIsChecked3(!isChecked3) };
    const [showMore, setShowMore] = useState(false);
    const [checkPersonalization, setCheckPersonalization] = useState(
        [
            { id: "1", name: "Technology", isChecked: false },
            { id: "2", name: "Health", isChecked: false },
            { id: "3", name: "Entertainment", isChecked: false },
            { id: "4", name: "Automotive", isChecked: false },
            { id: "5", name: "Home", isChecked: false },
            { id: "6", name: "Beauty", isChecked: false },
            { id: "7", name: "Fashion", isChecked: false },
            { id: "8", name: "Travel", isChecked: false },
            { id: "9", name: "Food", isChecked: false },
            { id: "10", name: "Sports", isChecked: false },
            { id: "11", name: "Music", isChecked: false },
            { id: "12", name: "Education", isChecked: false },
            { id: "13", name: "Finance", isChecked: false },
            { id: "14", name: "Pets", isChecked: false },
            { id: "15", name: "Fitness", isChecked: false }
        ]
    );

    const handleCheck = (e) => {
        const { id, checked } = e.target;
        setCheckPersonalization((prevState) => prevState.map((item) => {
            if (item.id === id) {
                return { ...item, isChecked: checked };
            }
            return item;
        }));
    }

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    }

    const handleClickOutside = (event) => {
        if (
            threeDotsRef.current && !threeDotsRef.current.contains(event.target)
        ) {
            setMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    const lable = "C / FE / YOUR FEED SETTING PAGE / V2 / SEP 13, 2024";

    return (
        <Fragment>
            <div className="h-screen relative">
                <div className="bg-[#d9d9d9] text-center">
                    <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p>
                </div>
                <nav className="bg-[black] flex px-1 py-1 sticky top-0 z-30 justify-between items-center">
                    <div className="grid grid-cols-12 w-full items-center">
                        <div className="col-span-2 flex items-center justify-center bg-[#00000085] hover:bg-[#0000009d] h-6 w-6 rounded-full">
                            <IoCaretBackCircle
                                className="text-white cursor-pointer text-[18px]"
                                onClick={() => navigate(-1)}
                            />
                        </div>
                        <p className="col-span-8 text-white font-bold text-center text-[14px]">YourFeed Settings</p>
                        <div className="col-span-2 flex justify-end relative">
                            <BsThreeDotsVertical className="text-[1rem] cursor-pointer text-white"
                                onClick={toggleMenu}
                                ref={threeDotsRef}
                            />
                        </div>
                    </div>
                </nav>
                {menuOpen && (
                    <div
                        className="absolute top-15 right-3 bg-white text-black rounded-md border-[0.4px] border-[#adadad] z-50"
                        ref={threeDotsRef}
                    >
                        <div className="flex items-center justify-between gap-3 px-3 py-1 border-b-[0.4px] border-[#b2b2b2] hover:font-bold">
                            <p className="text-[13px] cursor-pointer hover:text-green-400">Auto Setting</p>
                        </div>
                    </div>
                )}
                <div className="px-2 py-2 bg-gray-100 pb-24">
                    <div className="px-2 py-2 bg-white rounded-lg shadow-md mb-6">
                        <h2 className="text-[14px] font-semibold mb-4">Personalization</h2>
                        {checkPersonalization.map((data) => (
                            <>
                                {!showMore && data.id < 5 ?
                                    <div className={`flex justify-between items-center mb-2 w-full p-2 border border-gray-300 rounded-md text-gray-700 hover:bg-black  hover:text-white outline-none hover:items-center transition-all duration-300 ease-in-out cursor-pointer ${data.isChecked ? "bg-black text-white outline-none mb-1 items-center" : ""}`} key={data.id}>
                                        <label className="ml-[3px] text-[13px] font-medium">{data.name}</label>
                                        <input
                                            id={data.id}
                                            type="checkbox"
                                            onChange={handleCheck} checked={data.isChecked}
                                            className="rounded-full w-4 h-4 accent-green-700 cursor-pointer"
                                        />
                                    </div>
                                    : null
                                }
                                {showMore && (
                                    <div className={`flex justify-between items-center mb-2 w-full p-2 border border-gray-300 rounded-md text-gray-700 hover:bg-black  hover:text-white outline-none hover:items-center transition-all duration-300 ease-in-out cursor-pointer ${data.isChecked ? "bg-black text-white outline-none mb-1 items-center" : ""}`} key={data.id}>
                                        <label className="ml-[3px] text-[13px] font-medium">{data.name}</label>
                                        <input
                                            id={data.id}
                                            type="checkbox"
                                            onChange={handleCheck} checked={data.isChecked}
                                            className="rounded-full w-[12px] h-[14px] accent-green-700 cursor-pointer"
                                        />
                                    </div>
                                )}
                            </>
                        ))
                        }
                        <div className="flex justify-between items-center mb-2 px-[4px]">
                            <p
                                onClick={() => setShowMore(!showMore)}
                                className="text-[14px] text-blue-500 cursor-pointer"
                            >
                                {showMore ? "hide" : "More"}
                            </p>
                        </div>
                    </div>

                    <div className="px-2 py-2 bg-white rounded-lg shadow-md mb-6">
                        <h2 className="text-[14px] font-semibold mb-2">Notification Preferences</h2>
                        <div className="flex justify-between items-center mb-2 px-2">
                            <label htmlFor="email-notifications" className="text-[13px] font-medium text-gray-700">Email Notifications</label>
                            <div className="col-span-2">
                                <label className="relative inline-flex cursor-pointer select-none items-center">
                                    <input
                                        type="checkbox"
                                        name="autoSaver"
                                        className="sr-only"
                                        checked={isChecked1}
                                        onChange={handleCheckbox1}
                                    />
                                    <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked1 ? "bg-[#2ffd62]" : "bg-[#d9d9d9]"}`}>
                                        <span
                                            className={`dot h-[13px] w-[13px] rounded-full ${isChecked1
                                                ? "bg-[#ffffff]"
                                                : "bg-black"} shadow-2xl duration-200 ${isChecked1 ? "translate-x-5" : ""}`}
                                        />
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div className="flex items-center justify-between px-2">
                            <label htmlFor="sms-notifications" className="text-[13px] font-medium text-gray-700">SMS Notifications</label>

                            <div className="col-span-2">
                                <label className="relative inline-flex cursor-pointer select-none items-center">
                                    <input
                                        type="checkbox"
                                        name="autoSaver"
                                        className="sr-only"
                                        checked={isChecked2}
                                        onChange={handleCheckbox2}
                                    />
                                    <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked2 ? "bg-[#2ffd62]" : "bg-[#d9d9d9]"}`}>
                                        <span
                                            className={`dot h-[13px] w-[13px] rounded-full ${isChecked2
                                                ? "bg-[#ffffff]"
                                                : "bg-black"} shadow-2xl duration-200 ${isChecked2 ? "translate-x-5" : ""}`}
                                        />
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="px-2 py-2 bg-white rounded-lg shadow-md mb-6">
                        <h2 className="text-[14px] font-semibold">Privacy Settings</h2>
                        <select className="w-full p-2 mt-2 border border-gray-300 rounded-md text-[13px] outline-none">
                            <option>Public</option>
                            <option>Friends Only</option>
                            <option>Private</option>
                        </select>
                    </div>

                    <div className="px-2 py-2 bg-white rounded-lg shadow-md mb-6">
                        <h2 className="text-[14px] font-semibold mb-2">Content Filters</h2>
                        <div className="flex justify-between items-center mb-4 px-2">
                            <label htmlFor="hide-sensitive" className="text-sm font-medium text-gray-700">Hide Sensitive Content</label>
                            <label className="relative inline-flex cursor-pointer select-none items-center">
                                <input
                                    type="checkbox"
                                    name="autoSaver"
                                    className="sr-only"
                                    checked={isChecked3}
                                    onChange={handleCheckbox3}
                                />
                                <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked3 ? "bg-[#2ffd62]" : "bg-[#d9d9d9]"}`}>
                                    <span
                                        className={`dot h-[13px] w-[13px] rounded-full ${isChecked3
                                            ? "bg-[#ffffff]"
                                            : "bg-black"} shadow-2xl duration-200 ${isChecked3 ? "translate-x-5" : ""}`}
                                    />
                                </span>
                            </label>
                        </div>
                    </div>

                    <div className="px-2 py-2 bg-white rounded-lg shadow-md mb-6">
                        <h2 className="text-[14px] font-semibold mb-2">Account Management</h2>
                        <div className="w-full flex justify-center items-center">
                            <button className="px-4 py-2 text-white bg-blue-600 rounded-lg text-[12px] hover:bg-blue-700">
                                Manage Linked Accounts
                            </button>
                        </div>
                    </div>

                    <div className="px-2 py-2 bg-white rounded-lg shadow-md mb-6">
                        <h2 className="text-[14px] font-semibold mb-2">Feedback and Support</h2>
                        <textarea className="w-full p-2 border border-gray-300 outline-none rounded-md resize-none" rows="4" placeholder="Write your feedback..."></textarea>
                        <div className="h-full w-full flex justify-center items-center">
                            <button className="mt-2 px-4 py-2 bg-black font-bold rounded-lg text-[12px] text-white hover:bg-green-700">
                                Submit Feedback
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    )
}

