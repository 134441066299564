import axios from "axios";
import getAccessConfig from "../config";
import { REACT_APP_MAIN_URL } from "../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const mainUrl = REACT_APP_MAIN_URL

export const postBlock = createAsyncThunk(
    "postBlock",
    async ({ postId }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.post(`${mainUrl}/api/post/posts/block/${postId}/`, {}, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const postUnblock = createAsyncThunk( "postUnblock",
    async ({ postId }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.delete(`${mainUrl}/api/post/posts/unblock/${postId}/`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getBlockedPosts = createAsyncThunk(
    "getBlockedPosts",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.get(`${mainUrl}/api/post/block_list/`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


const PostBlockSlice = createSlice({
    name: "PostBlockSlice",
    initialState: {
        loading: false,
        data: [],
        error: null,
    },
    extraReducers: (builder) => {
        // create Repost
        builder.addCase(postBlock.pending, (state) => {
            state.loading = true;
            state.data = null;
            state.error = null;
        });
        builder.addCase(postBlock.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = null;
        });
        builder.addCase(postBlock.rejected, (state) => {
            state.loading = false;
            state.data = null;
            state.error = true;
        });
        // get bloxked posts
        builder.addCase(getBlockedPosts.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getBlockedPosts.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = null;
        });
        builder.addCase(getBlockedPosts.rejected, (state) => {
            state.loading = false;
            state.data = null;
            state.error = true;
        });
        // unblock posts list
        builder.addCase(postUnblock.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(postUnblock.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
        });
        builder.addCase(postUnblock.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });

    }
});

export default PostBlockSlice.reducer;