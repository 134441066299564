import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoCaretBackCircle } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserNotifications, readNotifications } from "../redux/apislice/notificationsSlice";
import { TimeFormat } from "../hooks/TimeFormat";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Skeleton from "react-loading-skeleton";

dayjs.extend(relativeTime);

const NotificationPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const notifications = useSelector(state => state.notifications)
    const { data, loading } = notifications;
    const [groupedNotifications, setGroupedNotifications] = useState([]);

    useEffect(() => {
        dispatch(fetchUserNotifications())
    }, [dispatch])

    const groupNotificationsByRelativeTime = useCallback(() => {
        const today = dayjs();
        const yesterday = today.subtract(1, 'day');
        const lastWeek = today.subtract(1, 'week');
        const lastMonth = today.subtract(1, 'month');

        const groups = [
            { date: "Today", data: [] },
            { date: "Yesterday", data: [] },
            { date: "Last Week", data: [] },
            { date: "Last Month", data: [] },
            { date: "Older", data: [] },
        ];

        data?.forEach(notification => {
            const createdAt = dayjs(notification.created_at);

            if (createdAt.isSame(today, 'day')) {
                groups[0].data.push({ ...notification });
            } else if (createdAt.isSame(yesterday, 'day')) {
                groups[1].data.push({ ...notification });
            } else if (createdAt.isAfter(lastWeek)) {
                groups[2].data.push({ ...notification });
            } else if (createdAt.isAfter(lastMonth)) {
                groups[3].data.push({ ...notification });
            } else {
                groups[4].data.push({ ...notification });
            }
        })

        // Filter out empty data groups
        const filteredGroups = groups.filter(group => group.data.length > 0);

        setGroupedNotifications(filteredGroups);
    }, [data])

    useEffect(() => {
        groupNotificationsByRelativeTime();
    }, [data, groupNotificationsByRelativeTime]);

    return (
        <div className="mb-24">
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-700 text-[10px] uppercase font-bold">
                    C / FE / Notification / V2 / SEP 10, 2024
                </p>
               
            </div>
            <div className="sticky top-0 bg-[#FF9900] border-b-[0.4px] border-[#b2b2b2] z-50">
                <div className="grid grid-cols-12 px-2 py-[6px]">
                    <div className="col-span-2">
                        <IoCaretBackCircle
                            onClick={() => navigate(-1)}
                            className="h-6 w-6 cursor-pointer text-black"
                        />
                    </div>
                    <div className="col-span-8 flex items-center justify-center">
                        <p className="text-[16px] font-semibold text-[#000000]">Notification's</p>
                    </div>
                </div>
            </div>
            <div className="mt-2 mb-3 px-2" >
                {loading && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => (
                    <div className="mb-2 border border-gray-300 p-1" key={index}>
                        <div className="grid grid-cols-12">
                            <div className="col-span-2 rounded-full">
                                <Skeleton height={35} width={35} style={{ borderRadius: "50%" }} />
                            </div>
                            <div className="col-span-10">
                                <div className="flex flex-col">
                                    <div className="flex justify-between items-center">
                                        <div className="flex gap-1">
                                            <Skeleton width={120} />
                                            {/* <Skeleton width={45} /> */}
                                        </div>
                                    </div>
                                    <div className="flex justify-between items-center" >
                                        <Skeleton width={180} />
                                        <Skeleton width={40} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                ))}
                {groupedNotifications.map((item) => (
                    <>
                        <p className="font-bold text-[13px] my-2">{item?.date}</p>
                        {item.data?.map((datas) => {

                            const date = TimeFormat(datas.created_at)
                            if (!datas.is_read) {
                                dispatch(readNotifications(datas.id)).then((res) => {
                                    if (res.error) {
                                        console.log(res.error, "error")
                                    } else {
                                        dispatch(fetchUserNotifications())
                                    }
                                })
                            }
                            return (
                                <div className="grid grid-cols-12 my-1 items-center" key={datas.id}>
                                    <img
                                        alt="img"
                                        className="col-span-2 h-7 w-7 rounded-full object-cover cursor-pointer"
                                        src={datas.created_by?.avatar}
                                        onClick={() => navigate(`/otheruser/account/${datas?.created_by?.id}`)}
                                    />
                                    <div className="col-span-10 grid grid-cols-12 items-center -ml-4">
                                        <div className="col-span-12 items-center">
                                            <p className="font-bold text-start text-[12px] cursor-pointer" onClick={() => navigate(`/otheruser/account/${datas?.created_by?.id}`)}>{datas.created_by.first_name} {datas.created_by.last_name}</p>
                                        </div>
                                        <div className="col-span-12 grid grid-cols-12  items-center">
                                            <p className="col-span-9 text-start text-[12px] cursor-pointer" onClick={() => navigate(`/postview/${datas.post}`)}>{datas.message}</p>
                                            <p className="col-span-3 text-[8px] font-bold text-[#00A3FF] text-end">{date}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        )}
                    </>
                ))}
                {groupedNotifications?.length === 0 && !loading && <p className="text-center">No Notification Found</p>}
            </div>
        </div>
    )
}

export default NotificationPage

