import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserNotifications, readNotifications } from "../../redux/apislice/notificationsSlice";
import { TimeFormat } from "../../hooks/TimeFormat";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

const Reviews = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate()
   const notifications1 = useSelector(state => state.notifications)
   const { loading, data } = notifications1;
   useEffect(() => {
      dispatch(fetchUserNotifications())
   }, [dispatch])

   return (
      <div className="mt-2 " >
         {!loading && <p className="font-bold text-[13px]">Today</p>}
         {loading && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => (
            <div className="mb-2 border border-gray-300 p-1" key={index}>
               <div className="grid grid-cols-12">
                  <div className="col-span-2 rounded-full">
                     <Skeleton height={35} width={35} style={{ borderRadius: "50%" }} />
                  </div>
                  <div className="col-span-10">
                     <div className="flex flex-col">
                        <div className="flex justify-between items-center">
                           <div className="flex gap-1">
                              <Skeleton width={120} />
                              {/* <Skeleton width={45} /> */}
                           </div>
                        </div>
                        <div className="flex justify-between items-center" >
                           <Skeleton width={180} />
                           <Skeleton width={40} />
                        </div>
                     </div>

                  </div>
               </div>
            </div>
         ))}
         {data?.map((item) => {
            const date = TimeFormat(item.created_at)
            if (!item.is_read) {
               dispatch(readNotifications(item.id)).then((res) => {
                  if (res.error) {
                     console.log(res.error, "error")
                  } else {
                     dispatch(fetchUserNotifications())
                  }
               })
            }
            return (
               <div className="grid grid-cols-12 mt-2 items-center" key={item.id}>
                  <div className=" h-full w-full col-span-1 md:col-span-2 flex justify-start items-start">
                     <img
                        alt="img"
                        className=" h-7 w-7  rounded-full object-cover cursor-pointer"
                        src={item.created_by?.avatar}
                        onClick={() => navigate(`/otheruser/account/${item?.created_by?.id}`)}
                     />
                  </div>
                  <div className="col-span-11 pl-2 md:p-0 md:col-span-10 md:-ml-2 grid grid-cols-12 justify-start items-start">
                     <div className="col-span-12 items-center -mt-1">
                        <p className="font-bold text-start text-[12px] cursor-pointer" onClick={() => navigate(`/otheruser/account/${item?.created_at?.id}`)}>{item.created_by.first_name} {item.created_by.last_name}</p>
                     </div>
                     <div className="col-span-12 grid grid-cols-12  items-center">
                        <p className="col-span-9 text-start text-[12px] cursor-pointer" onClick={() => navigate(`/postview/${item.post}`)}>{item.message}</p>
                        <p className="col-span-3 text-[8px] font-bold text-[#00A3FF] text-end">{date}</p>
                     </div>
                  </div>
               </div>
            )
         }
         )}
         {data?.length === 0 && <p className="text-center">No Notification Found</p>}
      </div>
   )
}

export default Reviews