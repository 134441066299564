import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FaCrown } from "react-icons/fa";
import { IoCaretBackCircle, IoPersonAddSharp, IoSearch } from "react-icons/io5";
import { getAdminList } from "../../../redux/apislice/group/groupAdminSlice";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { startFollow } from "../../../redux/apislice/profileSlice";

const AccountOwnerGroupModerators = () => {
    const label = "C / FE / ACCOUNT OWNER GROUP / MODERATORS / V1 / JULY 20, 2024";

    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch()
    const { groupId } = params;
    const [searchValue, setSearchValue] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const adminList = useSelector((state) => state.groupAdmin)


    const fetchData = useCallback(async () => {
        try {
            const response = await dispatch(getAdminList({ groupId }));
            if (response.meta.requestStatus === 'fulfilled') {
                setFilteredData(response.payload.admins);
            }
        } catch (error) {
            console.error("Failed to fetch group members:", error);
        }
    }, [dispatch, groupId])


    const handleFollow = async (user_id) => {

        try {
            dispatch(startFollow(user_id))?.then((result) => { 
                if (result.error) {
                    // toast.success(result.error)
                } else {
                    // toast.success(result.payload.message)
                    setFilteredData((prev) => prev.map((data) => data.id === user_id ? { ...data, user_follow: !data.user_follow } : data));

                }
            });
        } catch (error) {
            console.error("error", error);
        }
    }

    useEffect(() => {
        if (searchValue === '') {
            fetchData()
        } else {
            setFilteredData((prev) =>
                prev?.filter
                    ((data) => data.first_name.toLowerCase().includes(searchValue.toLowerCase()) || data.last_name.toLowerCase().includes(searchValue.toLowerCase()) || `${data.username}`.toLowerCase().includes(searchValue.toLowerCase()))
            );
        }
    }, [searchValue, fetchData , filteredData]);

    return (<div className="bg-white h-full overflow-y-scroll pb-[6.5rem]">
        <div className="bg-[#d9d9d9] text-center">
            <p className="text-red-700 text-[10px] uppercase font-bold">{label}</p>
        </div>

        <div className="sticky top-0 w-full bg-black z-50">
            <div className="px-2 flex justify-between items-center gap-3 pt-1 border-b-[0.4px] border-[#242424] pb-2">
                <IoCaretBackCircle
                    onClick={() => navigate(-1)}
                    className="h-8 w-8 cursor-pointer text-white"
                />
                <div className="relative w-full">
                    <input
                        type="text"
                        value={searchValue}
                        placeholder="Search moderators"
                        onChange={(e) => setSearchValue(e.target.value)}
                        className="w-full focus:outline-none rounded-full pl-3 pr-9 py-1 text-white bg-[#2e2e2e]"
                    />
                    {searchValue.length >= 1 && (
                        <IoSearch
                            className="absolute top-1/2 right-1 transform -translate-y-1/2 text-[24px] text-black bg-white rounded-full p-1 cursor-pointer"
                        />
                    )}
                </div>
                <IoPersonAddSharp
                    onClick={() => navigate(`/account/owner/group/invitemembers/${groupId}`)}
                    className="h-7 w-7 cursor-pointer text-white"
                />
            </div>

            <div className="flex items-center justify-around border-b-[0.4px] border-[#242424] py-[6px]">
                <p
                    onClick={() => navigate('/group/joined/moderators')}
                    className="text-white border-b-2 border-blue-500 text-[14px] cursor-pointer"
                >
                    Moderators
                </p>
                <p
                    onClick={() => navigate(`/account/owner/group/members/${groupId}`)}
                    className="text-gray-400 text-[14px] cursor-pointer"
                >
                    Members
                </p>
            </div>
        </div>

        {adminList.loading ?
            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((data) => (
                <div key={data} className="grid grid-cols-12 px-2">
                    <div className="col-span-2">
                        <Skeleton height={30} width={30} circle />
                    </div>
                    <div className="col-span-8">
                        <Skeleton height={20} width={100} />
                        <Skeleton height={30} width={150} />
                    </div>
                    <div className="col-span-2 flex justify-center items-center">
                        <Skeleton height={30} width={50} />
                    </div>

                </div>
            )) : filteredData.length > 0 ? (
                filteredData.map((data) => (
                    <div className="grid grid-cols-12 px-2 py-1 border-b-[0.4px] border-[#b2b2b2]" key={data.id}>
                        <div className="col-span-2">
                            <img
                                alt={data.username}
                                src={data.avatar_url}
                                className="h-8 w-8 object-cover rounded-full cursor-pointer"
                            />
                        </div>
                        <div className="col-span-10">
                            <div className="flex justify-between items-start">
                                <div className="flex flex-col leading-[16px]">
                                    <div className="flex items-center gap-2">
                                        <p className="text-black text-[14px]">{data.first_name} {data.last_name}</p>
                                        <FaCrown className="text-[14px] text-yellow-900" />
                                    </div>
                                    <p className="text-[#5c5c5c] text-[12px]">{data.username}</p>
                                </div>
                                <button className="px-3 py-[2px] text-[12px] hover:font-bold font-normal rounded-full bg-black text-white" oClick={()=>handleFollow(data.id)}>
                                    {data.user_follow ? "Unfollow":"Follow"}
                                </button>
                            </div>
                            <p className="text-black text-[12px] leading-[14px] pt-1">{data?.userAbout}</p>
                        </div>
                    </div>
                ))
            ) : (
                <div className="text-center py-4">
                    <p className="text-gray-700 overflow-x-scroll">No results for "{searchValue}"</p>
                </div>
            )}
    </div>
    );
};

export default AccountOwnerGroupModerators;


