import React, { useCallback, useEffect, useRef, useState } from "react"
import LikedPosts from "../components/account/LikedPosts";
import { IoCaretBackCircle } from "react-icons/io5";
import { IoSearch } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { FaFilter } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getLikedPosts } from "../redux/apislice/LikedPostsSlice";

export default function LikedPostsPage() {
    const navigate = useNavigate();
    const [showFilter, setShowFilter] = useState(false);
    const lable = "C / FE / Liked posts page / V2 / SEP 17, 2024";

    const dispatch = useDispatch()

    const { posts, loading } = useSelector(state => state.likedPost);

    const [filteredData, setFilteredData] = useState(posts?.Liked_Posts);
    const menuRef = useRef(null);

    const filterData = (option) => {
        const now = new Date();
        let startDate;

        switch (option) {
            case "24Hours":
                startDate = new Date(now);
                startDate.setDate(now.getDate() - 1);
                break;
            case "Week":
                startDate = new Date(now);
                startDate.setDate(now.getDate() - 7);
                break;
            case "Month":
                startDate = new Date(now);
                startDate.setMonth(now.getMonth() - 1);
                break;
            case "YearToDate":
                startDate = new Date(now.getFullYear(), 0, 1); // Jan 1 of the current year
                break;
            case "All":
                return setFilteredData(posts?.Liked_Posts);
            default:
        }

        const result = posts?.Liked_Posts.filter((item) => new Date(item.created_at) >= startDate);
        setFilteredData(result);
    };

    const handleOutsideClick = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setShowFilter(false);
        }
    };




    useEffect(() => {
        if (showFilter) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [showFilter]);

    const fetchData = useCallback(async () => {
        await dispatch(getLikedPosts()).then((result) => {
            if (result.error) {
                console.log("Error fetching data");
                return;
            } else {
                setFilteredData(result.payload.Liked_Posts);
            }
        })
    }, [dispatch]);

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <div className="px-0 pb-20">
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p>
                <div className="sticky top-0 w-full grid grid-cols-12 px-2 py-2 bg-black z-10 text-gray-400 text-[14px] border-b-[0.4px] border-[#424242]">
                    <div className="col-span-2 flex justify-start items-center">
                        <IoCaretBackCircle
                            onClick={() => navigate(-1)}
                            className="text-white cursor-pointer text-[20px]"
                        />
                    </div>
                    <div className="col-span-8 flex justify-center text-center">
                        <p className="cursor-pointer text-[14px] items-center font-extrabold">
                            Liked Posts <span className="text-[1rem]">👍</span>
                        </p>
                    </div>
                    <p className="col-span-2 flex gap-x-3 justify-end items-center relative">
                        <IoSearch className="text-[20px] text-white cursor-pointer" />
                        <FaFilter
                            onClick={() => setShowFilter(!showFilter)}
                            className="text-[16px] text-white cursor-pointer"
                        />

                        {/* Filter dropdown */}
                        {showFilter &&
                            <div ref={menuRef} className="absolute flex flex-col items-start w-32 right-0 top-10 bg-gray-900 shadow-lg rounded-md overflow-hidden text-[14xp]">
                                <p className="px-4 py-2 hover:bg-gray-300 hover:text-black w-full cursor-pointer" onClick={() => filterData("24Hours")}>Past 24 Hours</p>
                                <p className="px-4 py-2 hover:bg-gray-300 hover:text-black w-full cursor-pointer" onClick={() => filterData("Week")}>Past Week</p>
                                <p className="px-4 py-2 hover:bg-gray-300 hover:text-black w-full cursor-pointer" onClick={() => filterData("Month")}>Past Month</p>
                                <p className="px-4 py-2 hover:bg-gray-300 hover:text-black w-full cursor-pointer" onClick={() => filterData("YearToDate")}>Year to Date</p>
                                <p className="px-4 py-2 hover:bg-gray-300 hover:text-black w-full cursor-pointer" onClick={() => filterData("All")}>All</p>
                            </div>
                        }
                    </p>
                </div>
                <LikedPosts filteredData={filteredData} loading={loading} />
            </div>
        </div>
    )
}
