import React from "react";
import { GiFullMotorcycleHelmet } from "react-icons/gi";
import { useNavigate } from "react-router-dom";

const JoinNowPage = () => {
   const navigate = useNavigate();

   return (
      <div className="bg-[#fff]">
         <div className="text-center">
            <p className="text-red-700 text-[10px] uppercase font-bold text-center">
               C / FE / Join Now / V2 / MAY 15, 2024
            </p>
            <p className="text-black text-[9px] text-center">
               Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc
            </p>
         </div>

         <div className="flex flex-col justify-between mx-8 mt-1 bg-[#D9D9D9] h-[91vh] md:h-[79vh] rounded-md overflow-y-scroll">
            <div className="flex flex-col">
               <p className="text-center text-[8rem] md:text-[7.5rem] mt-1">🐈</p>

               <p className="text-center text-[#11A098] pt-1 md:pt-0 px-3 text-[2.5rem] md:text-[1.5rem] font-bold">
                  Join Creative
               </p>

               <p className="text-center font-bold px-3 pt-2 md:pt-4 text-[1.3rem] md:text-[1rem]">
                  Be part of something bigger
               </p>

               <div className="flex flex-col mt-[4rem] md:mt-6 mx-8">
                  <button className="bg-black text-white w-10% mb-5 text-[18px] md:text-[13px] py-3 rounded-[5px]"
                     onClick={() => navigate("/signup")}
                  >
                     Join Creative
                  </button>
                  <button
                     className="bg-black text-white w-10% mb-5 text-[18px] md:text-[13px] py-3 rounded-[5px]"
                     onClick={() => navigate("/login")}
                  >
                     Log In
                  </button>
               </div>
               <div className="flex justify-between mx-8 mt-[4rem] md:mt-4">
                  <p className="bg-[#FFF500] w-[6rem] md:w-[4rem] h-[6rem] md:h-[4rem] flex justify-center items-center cursor-pointer rounded-[5px]">
                     <GiFullMotorcycleHelmet className="text-[3rem] md:text-[2rem] text-red-500" />
                  </p>
                  <p className="bg-[#FFF500] w-[6rem] md:w-[4rem] h-[6rem] md:h-[4rem] flex justify-center items-center text-[3rem] md:text-[1.9rem] font-extrabold cursor-pointer rounded-[5px]">
                     S
                  </p>
               </div>
            </div>

            <div className="mx-8 mb-2 mt-1 flex justify-between text-[18px] md:text-[13px] font-semibold">
               <div className="flex flex-col gap-y-2 text-end w-[6rem] md:w-[4rem]">
                  <p className="cursor-pointer">Help</p>
                  <p className="cursor-pointer">Privacy</p>
               </div>
               <div className="flex flex-col gap-y-2 text-start w-[6rem] md:w-[4rem]">
                  <p className="cursor-pointer">Safety</p>
                  <p className="cursor-pointer">Settings</p>
               </div>
            </div>

         </div>
      </div>
   )
}

export default JoinNowPage