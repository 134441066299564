import React, { useState } from 'react';
import { IoCaretBackCircle } from 'react-icons/io5';
import { useNavigate } from "react-router-dom";
import RightSideMenu from './RightSideMenu';


export default function CustomerSupport() {
    const navigate = useNavigate();
    const [feedback, setFeedback] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission
    };

    const label = "C / FE / CUSTOMER SUPPORT PAGE / V2 / SEP 18, 2024";

    return (
        <div className="relative mb-28 w-full">
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-700 text-[10px] uppercase font-bold">{label}</p>
            </div>
            <nav className="bg-blue-500 flex px-2 sticky top-0 p-2 justify-between items-center">
                <IoCaretBackCircle
                    onClick={() => navigate(-1)}
                    className="text-white cursor-pointer text-[18px]"
                />
                <h1 className="text-white text-[16px] text-center font-bold">
                    Customer Support
                </h1>
                <RightSideMenu />
            </nav>
            <div className="h-full">
                <div className="px-2 mb-4">
                    {/* Header Section */}
                    <div className="mb-4 text-center">
                        <p className="text-[10px] text-gray-600">
                            24x7 Customer Care Support – Here Whenever You Need Us
                        </p>
                    </div>

                    {/* Introduction */}
                    <div className="mb-4">
                        <p className="text-[12px] text-justify">
                            Welcome to our Help Center! Our dedicated team is available around the clock to assist you with any questions,
                            concerns, or issues you may have. Whether you need help troubleshooting a problem, have a question about your
                            account, or need assistance with a recent purchase, we’re here to help.
                        </p>
                    </div>

                    {/* Support Channels */}
                    <div className="mb-4">
                        <h2 className="text-[20px] font-semibold mb-1">
                            Support Channels
                        </h2>

                        <div className="mb-4">
                            <h3 className="text-[14px] font-bold">Live Chat Support</h3>
                            <p className="text-[12px] font-bold">Available 24/7</p>
                            <p className="text-[12px] text-justify leading-tight py-1">Our live chat agents are ready to assist you anytime, day or night. Click the chat icon at the bottom right of the screen to start a conversation with one of our support specialists.</p>
                        </div>

                        <div className="mb-4">
                            <h3 className="text-[14px] font-bold">Phone Support</h3>
                            <p className="text-[12px] font-bold">24x7 Customer Care Hotline: +1 (800) 123-4567</p>
                            <p className="text-[12px] text-justify leading-tight py-1">Prefer to speak directly with us? Give us a call anytime, and one of our friendly representatives will be happy to assist you.</p>
                        </div>

                        <div className="mb-4">
                            <h3 className="text-[14px] font-bold">Email Support</h3>
                            <p className="text-[12px] font-bold">Email Us: <p href="mailto:support@yourcompany.com" className="text-blue-500">support@yourcompany.com</p></p>
                            <p className="text-[12px] text-justify leading-tight py-1">If your inquiry isn’t urgent, feel free to send us an email. Our team aims to respond within 24 hours.</p>
                        </div>
                    </div>

                    {/* Self-Service Resources */}
                    <div className="mb-4">
                        <h2 className="text-[20px] font-semibold mb-1">
                            Self-Service Resources
                        </h2>

                        <div className="mb-4">
                            <h3 className="text-[14px] font-bold">Frequently Asked Questions (FAQs)</h3>
                            <p className="text-[12px] text-justify leading-tight py-1">Find quick answers to common questions in our FAQ div. Whether you need help with billing, account management, or product information, our FAQs are a great place to start.</p>
                        </div>

                        <div className="mb-4">
                            <h3 className="text-[14px] font-bold">Guides & Tutorials</h3>
                            <p className="text-[12px] text-justify leading-tight py-1">Access step-by-step guides and tutorials designed to help you make the most of our services. From getting started to advanced troubleshooting, we’ve got you covered.</p>
                        </div>

                        <div className="mb-4">
                            <h3 className="text-[14px] font-bold">Troubleshooting Articles</h3>
                            <p className="text-[12px] text-justify leading-tight py-1">If you’re experiencing issues, our troubleshooting articles can help you resolve problems quickly and efficiently. Search by topic or keyword to find the information you need.</p>
                        </div>
                    </div>

                    {/* Account Management */}
                    <div className="mb-4">
                        <h2 className="text-[20px] font-semibold mb-1">
                            Account Management
                        </h2>

                        <div className="mb-4">
                            <h3 className="text-[14px] font-bold">Manage Your Account</h3>
                            <p className="text-[12px] text-justify leading-tight py-1">Easily update your account information, change your password, or review your order history in the Account Settings div.</p>
                        </div>

                        <div className="mb-4">
                            <h3 className="text-[14px] font-bold">Billing & Payments</h3>
                            <p className="text-[12px] text-justify leading-tight py-1">Need help with billing or payment issues? Visit our Billing div for detailed information on how to view invoices, manage payment methods, and understand charges.</p>
                        </div>
                    </div>

                    {/* Order Support */}
                    <div className="mb-4">
                        <h2 className="text-[20px] font-semibold mb-1">Order Support</h2>

                        <div className="mb-4">
                            <h3 className="text-[14px] font-bold">Track Your Order</h3>
                            <p className="text-[12px] text-justify leading-tight py-1">Wondering where your order is? Use our Order Tracking tool to see the status of your shipment and get real-time updates.</p>
                        </div>

                        <div className="mb-4">
                            <h3 className="text-[14px] font-bold">Returns & Exchanges</h3>
                            <p className="text-[12px] text-justify leading-tight py-1">If you need to return or exchange a product, visit our Returns & Exchanges div for detailed instructions on how to initiate the process.</p>
                        </div>
                    </div>

                    {/* Community Support */}
                    <div className="mb-4">
                        <h2 className="text-[20px] font-semibold mb-1">Community Support</h2>
                        <p className="text-[12px] text-justify leading-tight py-1">Join our community forums to connect with other customers, share experiences, and get advice. Our community is here to support you, too!</p>
                    </div>

                    {/* Feedback & Suggestions */}
                    <div className="mb-4">
                        <h2 className="text-[20px] font-semibold mb-1">Feedback & Suggestions</h2>
                        <p className="text-[12px] text-justify leading-tight py-1">We value your feedback! Let us know how we can improve your experience by submitting your thoughts through our Feedback form. Your input helps us serve you better.</p>
                        <form onSubmit={handleSubmit} className="flex flex-col justify-center items-center bg-gray-200 rounded-md p-2">
                            <textarea
                                rows={5}
                                value={feedback}
                                placeholder="Enter your feedback here ..."
                                onChange={(e) => setFeedback(e.target.value)}
                                className="w-full h-24 border p-2 rounded-lg resize-none focus:outline-none placeholder:text-[14px]"
                            />
                            <button
                                type="submit"
                                className="bg-blue-500 text-white px-4 py-2 mt-2 text-[13px] rounded-sm hover:bg-blue-600"
                            >
                                Submit Feedback
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}