import React, { Fragment, useState } from "react";
import Header from "./Header";
import { trendingPostData } from "../../data";
import { IoHeartSharp } from "react-icons/io5";
import { FaArrowDownLong } from "react-icons/fa6";
import { IoStar, IoStarHalf, IoStarOutline } from "react-icons/io5";

export default function FavoriteProducts() {
    const lable = "C / FE / FAVORITE PRODUCT POST PAGE / V2 / SEP 19, 2024";

    const [trendingPost, setTrendingPost] = useState([]);

    const handleClick = (data) => {
        if (trendingPost.find((item) => item === data)) {
            setTrendingPost(trendingPost.filter((item) => item === data));
        } else {
            setTrendingPost([...trendingPost, data]);
        }
    }

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p>
            </div>

            <Header />
            
            <div className="grid grid-cols-3 md:grid-cols-2 gap-3 mb-24">
                {trendingPostData.map((product) => (
                    <div className="col-span-12 grid grid-cols-12 px-2 pb-2 border-b-[0.4px] border-[#bbbbbb]">
                        <div className="col-span-4 relative">
                            <img 
                                src={product.image} 
                                alt={product.name} 
                                className="w-full h-[5.8rem] object-cover rounded-lg"
                            />
                            <div className="absolute right-2 top-2 h-6 w-6 bg-gray-100 rounded-3xl bg-opacity-50 flex justify-center items-center">
                                <IoHeartSharp 
                                    className="cursor-pointer" 
                                    onClick={() => handleClick(product.ratings)} 
                                    color={trendingPost.find((item) => item === product.ratings) ? "red" : "black"} 
                                />
                            </div>
                        </div>
                        <div className="col-span-8">
                            <div className="px-1 py-[1px]">
                                <h3 className="text-[12px] text-black font-semibold line-clamp-1">
                                    {product.name}
                                </h3>
                                <p className="text-[10px] text-gray-600 line-clamp-2 leading-3">
                                    {product.description}
                                </p>
                                <div className="flex justify-between items-center my-[4px]">
                                    <div className="flex justify-between gap-2 items-center w-full" >
                                        <div className="flex items-center gap-x-2">
                                            <div className="flex items-center gap-x-[1px] text-green-500">
                                                <FaArrowDownLong className="text-[13px]" /> 
                                                <p className="text-[13px] font-bold">
                                                    {product.offer_percentage}%
                                                </p>
                                            </div>
                                            <span className="text-[13px] font-bold text-black">
                                                ${product.sale_price}
                                            </span>
                                        </div>
                                        <span className="text-[13px] font-bold text-gray-400 line-through">
                                            ${product.original_price}
                                        </span>
                                    </div>
                                </div>

                                <div className="flex items-center justify-between">
                                    <span className="text-black text-[10px] flex gap-1 font-semibold">{product.ratings}
                                        <spna className="text-yellow-500">
                                            <div className="flex justify-start items-center text-[11px] pt-[1px]">
                                                <IoStar className="text-yellow-800" />
                                                <IoStar className="text-yellow-800" />
                                                <IoStar className="text-yellow-800" />
                                                <IoStarHalf className="text-yellow-800" />
                                                <IoStarOutline className="text-yellow-800" />
                                            </div>
                                        </spna>
                                    </span>
                                    <span className="text-blue-600 text-[13px] font-bold">
                                        {product.offer_percentage}% off
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </Fragment>
    )
}


