import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import { userLogin } from "../../redux/apislice/authSlice";
import PulseLoader from "react-spinners/PulseLoader";
import CryptoJS from 'crypto-js';
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

const Login = () => {
   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
   const [rememberMe, setRememberMe] = useState(false);
   const [passwordShown, setPasswordShown] = useState(false);


   const navigate = useNavigate();

   const dispatch = useDispatch();
   const { loading } = useSelector((state) => state.auth);


   const togglePasswordVisiblity = () => {
      setPasswordShown(passwordShown ? false : true);
   }

   // Load remember me state and decrypt email/password from local storage on component mount
   useEffect(() => {
      const rememberMeState = localStorage.getItem("rememberMe");
      if (rememberMeState) {
         setRememberMe(JSON.parse(rememberMeState));
         const storedEmail = localStorage.getItem("email");
         const storedPassword = localStorage.getItem("password");
         if (storedEmail && storedPassword) {
            const decryptedEmail = decryptData(storedEmail);
            const decryptedPassword = decryptData(storedPassword);
            setEmail(decryptedEmail);
            setPassword(decryptedPassword);
         }
      }
   }, []);

   const handleSubmit = (e) => {
      e.preventDefault();
      const loginDetails = { email, password };

      dispatch(userLogin(loginDetails)).then((result) => {
         if (result.error) {
            toast.error(result.payload.detail);
         } else {
            toast.success("Login Successful");
            setTimeout(() => navigate("/home"), 1000);
            // Store email and password in local storage if remember me is checked
            if (rememberMe) {
               const encryptedEmail = encryptData(email);
               const encryptedPassword = encryptData(password);
               localStorage.setItem("email", encryptedEmail);
               localStorage.setItem("password", encryptedPassword);
               localStorage.setItem("rememberMe", JSON.stringify(rememberMe));
            } else {
               // If remember me is unchecked, remove email and password from local storage
               localStorage.removeItem("email");
               localStorage.removeItem("password");
               localStorage.removeItem("rememberMe");
            }
         }
      });
   }

   // Encrypt data using AES encryption
   const encryptData = (data) => {
      return CryptoJS.AES.encrypt(data, "secret_key").toString();
   }

   // Decrypt data using AES decryption
   const decryptData = (encryptedData) => {
      const bytes = CryptoJS.AES.decrypt(encryptedData, "secret_key");
      return bytes.toString(CryptoJS.enc.Utf8);
   }

   return (
      <div className="flex flex-col justify-center items-center px-4 mt-[4rem]">
         <p className="text-[#7000FF] font-bold text-[3.3rem] md:text-[40px] mb-3">Creative</p>
         <div className="h-[43vh] w-full rounded-[20px] p-4 bg-white overflow-hidden">
            <Toaster
               position="top-right"
               reverseOrder={false}
            />
            <p className="font-bold text-[18px] md:text-[15px] text-center">
               Watch post read and know more
            </p>
            <form className="mt-5" onSubmit={handleSubmit}>
               <div className="mb-3">
                  <p className="text-gray-800 text-[15px] md:text-[13px]">Email</p>
                  <input
                     required
                     name="email"
                     type="text"
                     value={email}
                     onChange={(e) => setEmail(e.target.value)}
                     placeholder="Enter your email address"
                     className="w-full focus:outline-none border-gray-700 px-[8px] py-[8px] md:py-[4px] border-[1px] rounded-md"
                  />
               </div>
               <div className="mb-2 relative">
                  <p className="text-gray-800 text-[15px] md:text-[13px]">Password</p>
                  <input
                     required
                     name="password"
                     type={passwordShown ? "text" : "password"}
                     value={password}
                     onChange={(e) => setPassword(e.target.value)}
                     placeholder="Enter your password"
                     className="w-full text-[14px] focus:outline-none border-gray-700 px-[8px] pr-10 py-[8px] md:py-[4px] border-[1px] rounded-md"
                  />
                  <div className="absolute md:top-6 top-8 right-2 cursor-pointer" onClick={() => togglePasswordVisiblity()}>
                     {passwordShown ? (
                        <EyeIcon className="h-5 w-5" />
                     ) : (
                        <EyeSlashIcon className="h-5 w-5" />
                     )}
                  </div>

               </div>
               <div className="flex justify-between items-center">
                  <div className="flex items-center gap-1">
                     <input
                        type="checkbox"
                        className="rounded-full accent-green-700 cursor-pointer"
                        checked={rememberMe}
                        onChange={() => setRememberMe(!rememberMe)} // Toggle remember me state
                     />
                     <p className="text-[13px] md:text-[11px]">Remember me</p>
                  </div>
                  <p className="text-[#7000FF] font-bold text-[13px] md:text-[11px] cursor-pointer" onClick={() => navigate("/recoverpassword")}>Recover password</p>
               </div>
               <button type="submit" className="w-full bg-[#7000FF] text-white rounded-md py-[10px] md:py-[5px] mt-4">
                  {loading ?
                     <PulseLoader
                        color={"#fff"}
                        loading={loading}
                        size={10}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                     />
                     : "Login"}
               </button>
               <div className="flex justify-between items-center mt-2">
                  <div className="flex items-center gap-1">
                     <p className="text-[13px] md:text-[11px]">Can’t Login?</p>
                     <p className="text-[#7000FF] font-bold text-[13px] md:text-[11px] cursor-pointer" onClick={() => navigate('/gethelp')}>Get help</p>
                  </div>
                  <p className="text-[#7000FF] font-bold text-[13px] md:text-[11px] cursor-pointer" onClick={() => navigate("/signup")}>Create New account</p>
               </div>
            </form>
         </div>
      </div>
   )
}

export default Login;
