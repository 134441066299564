import React, { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoArrowBackCircle } from "react-icons/io5";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import toast, { Toaster } from "react-hot-toast";
import { fetchFollowingList, fetchProfile, startFollow } from "../../redux/apislice/profileSlice";
import BlockModal from "../common/BlockModal";


const OherUserFollowing = () => {

    const lable = "C / FE /OTHER  FOLLOWING PAGE / V1 / SEP 03, 2024";

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [hoveredId, setHoveredId] = useState(null);
    const [menuPostId, setMenuPostId] = useState(null);
    const [blockUser, setBlockUser] = useState(false);
    const menuRef = useRef(null);
    const { loading, following, user } = useSelector(state => state.profile);


    const toggleMenu = (id) => {
        if (menuPostId === id) {
            setMenuPostId(null); // Close menu if already open
        } else {
            setMenuPostId(id); // Open menu for this comment
        }
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setMenuPostId(null); // Close menu if clicked outside
        }
    };

    const handleScroll = () => {
        setMenuPostId(null); // Close menu when scrolling
    };

    const handleRemoveFollow = (userId) => {
        dispatch(startFollow(userId))?.then((result) => {
            if (result.error) {
                toast.success(result.error)
            } else {
                toast.success(result.payload.message)
                dispatch(fetchFollowingList())
                dispatch(fetchProfile())
            }
        });
    }

    const handleNavigate = (user_id) => {
        navigate(`/otheruser/account/${user_id}`);
    }
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        window.addEventListener("scroll", handleScroll); // Add scroll event listener

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            window.removeEventListener("scroll", handleScroll); // Remove scroll event listener on cleanup
        };
    }, []);

    const handleMouseEnter = (id) => {
        setHoveredId(id);
    };

    const handleMouseLeave = () => {
        setHoveredId(null);
    };
    useEffect(() => {
        dispatch(fetchFollowingList())
        dispatch(fetchProfile())
    }, [dispatch])


    return (
        <Fragment>
            <Toaster position="top-right" />
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p>
            </div>

            <div className="sticky top-4 bg-white px-2 z-50  pt-2">
                <IoArrowBackCircle
                    onClick={() => navigate(-1)}
                    className="text-[1.5rem] mt-1 cursor-pointer"
                />
                {/* <p className="text-center text-[15px] mb-2 mt-[-15px] font-bold">{user?.username}</p> */}

                <p className="text-center text-[15px] mb-2 mt-[-15px] font-bold">{user?.username}</p>
                <div className="grid grid-cols-12 border-gray-400 border text-center">
                    <p className="col-span-4 text-[12px] p-1 font-bold border-r border-gray-400 cursor-pointer" onClick={() => navigate(`/otheruser/account/followers/${user?.id}`)}>Followers</p>
                    <p className="col-span-4 text-[12px] p-1 font-bold border-r border-gray-400 cursor-pointer text-purple-600" onClick={() => navigate(`/otheruser/account/following/${user?.id}"`)}>Following</p>
                    <p className="col-span-4 text-[12px] p-1 font-bold cursor-pointer" onClick={() => navigate(`/otheruser/account/Recommended/${user?.id}`)}>Recommended</p>
                </div>
            </div>
            {loading ?
                [1, 2, 3, 4, 5, 6, 7, 8].map((index) => (
                    <div className="mb-2 border border-gray-300 p-1 mx-2" key={index}>
                        <div className="grid grid-cols-12">
                            <div className="col-span-2">
                                <Skeleton height={38} width={38} style={{ borderRadius: "50%" }} />
                            </div>
                            <div className="col-span-10">
                                <div className="flex flex-col">
                                    <div className="flex justify-between items-center">
                                        <div className="flex justify-between gap-x-20">
                                            <Skeleton width={100} />
                                            <Skeleton width={45} />
                                        </div>
                                        <Skeleton width={28} />
                                    </div>
                                    <Skeleton count={1} width={130} />
                                </div>
                            </div>
                        </div>
                    </div>
                ))
                : <div className="border-gray-400 border-r border-l mb-32 mx-2">
                    {following?.map((data) => (
                        <div className="flex items-center justify-between px-2 py-[6px] border-b border-gray-400" key={data.id}>
                            <div className="flex items-center gap-2">
                                <img
                                    alt="img"
                                    src={data.following_avatar
                                    }
                                    onClick={() => handleNavigate(data.following_id)}
                                    className="h-9 w-9 rounded-full object-cover"
                                />
                                <div className="flex flex-col items-start">
                                    <p className="text-[11px]" onClick={() => handleNavigate(data.following_id)}>{data.following_first_name} {data.following_last_name}</p>
                                    <p className="text-[11px] mt-[-3px]" onClick={() => handleNavigate(data.following_id)}>{data?.following_username}</p>
                                </div>
                            </div>
                            <div className="flex items-center gap-2 relative">
                                <button
                                    className={`bg-black text-white px-3 py-[3px] rounded-full text-[11px] ${hoveredId === data.id ? 'hover:bg-red-500' : ''}`}
                                    onMouseEnter={() => handleMouseEnter(data.id)}
                                    onMouseLeave={handleMouseLeave}
                                    onClick={() => handleRemoveFollow(data.following_id)}
                                >
                                    Remove
                                </button>
                                <HiOutlineDotsHorizontal
                                    onClick={() => toggleMenu(data.id)}
                                    className="text-[20px] cursor-pointer"
                                />
                                {menuPostId === data.id &&
                                    <div ref={menuRef} className="absolute right-0 top-6 rounded bg-[#d9d9d9]">
                                        <p className="text-[13px] px-3 py-[2px]  cursor-pointer" onClick={() => setBlockUser(true)}>Block</p>
                                    </div>
                                }
                            </div>
                        </div>
                    ))}
                </div>}
            {following.length === 0 && (<div className="flex justify-center items-center">
                <p className="text-center text-[14px] mt-3 text-gray-500 font-bold">No Following</p>
            </div>)}
            {blockUser && <BlockModal setBlockUser={() => setBlockUser(false)} />}
        </Fragment>
    )
}

export default OherUserFollowing