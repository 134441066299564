import axios from "axios";
import getAccessConfig from "../config";
import { REACT_APP_MAIN_URL } from "../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const mainUrl = REACT_APP_MAIN_URL

export const blockUser = createAsyncThunk(
    "blockUser",
    async ({ user_id }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.post(`${mainUrl}/api/users/block/${user_id}/`, {}, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const userUnblock = createAsyncThunk("userUnblock",
    async ({ user_id }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.delete(`${mainUrl}/api/users/unblock/${user_id}/`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getBlockedUsers = createAsyncThunk(
    "getBlockedUsers",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.get(`${mainUrl}/api/users/blocked/`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


const blockUserSlice = createSlice({
    name: "blockUserSlice",
    initialState: {
        loading: false,
        data: [],
        error: null,
    },
    extraReducers: (builder) => {
        // user block 
        builder.addCase(blockUser.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(blockUser.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = null;
        });
        builder.addCase(blockUser.rejected, (state) => {
            state.loading = false;
            state.data = null;
            state.error = true;
        });
        builder.addCase(getBlockedUsers.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getBlockedUsers.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = null;
        });
        builder.addCase(getBlockedUsers.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
    }
});

export default blockUserSlice.reducer;