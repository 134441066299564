

import React, { useState, useEffect } from 'react';
import { MdWork } from "react-icons/md";
import { RiCake2Fill } from "react-icons/ri";
import { IoPersonSharp } from "react-icons/io5";
import { VscMention } from "react-icons/vsc";
import { FaInfoCircle } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";

const About = ({ user_details }) => {
    const [edit, setEdit] = useState(false);
    const [userInfo, setUserInfo] = useState({
        name: "Emily Johnson",
        tagName: "@emilyjohnson",
        about: "Sprinkling kindness everywhere I go",
        location: "USA, California",
        birthDate: "19 FEB, 1991",
        work: "Software Engineer"
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserInfo({ ...userInfo, [name]: value });
    };

    const handleSave = (e) => {
        e.preventDefault();
        setEdit(false);
    };
    useEffect(() => {
        setUserInfo(() => ({ ...userInfo, name: user_details.first_name + user_details.last_name, tagName: user_details.username, about: user_details.about_me, location: user_details.location, birthDate: user_details.date_of_birth, work: "Software Engineer" }))
    }, [user_details, userInfo])

    return (
        <div>
            {edit ?
                <form onSubmit={handleSave} className="px-2 flex flex-col gap-1">
                    <div className="grid grid-cols-12 items-center">
                        <div className="col-span-3 flex items-center gap-1 text-[#1c1c1c]">
                            <IoPersonSharp className="h-3 w-3" />
                            <label className="text-[12px] font-semibold">Name</label>
                        </div>
                        <input
                            type="text"
                            name="name"
                            value={userInfo.name}
                            onChange={handleChange}
                            className="col-span-9 text-[12px] text-[#787878] border p-1"
                        />
                    </div>
                    <div className="grid grid-cols-12 items-center">
                        <div className="col-span-3 flex items-center gap-1 text-[#1c1c1c]">
                            <VscMention className="h-3 w-3" />
                            <label className="text-[12px] font-semibold">TagName</label>
                        </div>
                        <input
                            type="text"
                            name="tagName"
                            value={userInfo.tagName}
                            onChange={handleChange}
                            className="col-span-9 text-[12px] text-[#787878] border p-1"
                        />
                    </div>
                    <div className="grid grid-cols-12 items-center">
                        <div className="col-span-3 flex items-center gap-1 text-[#1c1c1c]">
                            <FaInfoCircle className="h-3 w-3" />
                            <label className="text-[12px] font-semibold">About</label>
                        </div>
                        <input
                            type="text"
                            name="about"
                            value={userInfo.about}
                            onChange={handleChange}
                            className="col-span-9 text-[12px] text-[#787878] border p-1"
                        />
                    </div>
                    <div className="grid grid-cols-12 items-center">
                        <div className="col-span-3 flex items-center gap-1 text-[#1c1c1c]">
                            <FaLocationDot className="h-3 w-3" />
                            <label className="text-[12px] font-semibold">Location</label>
                        </div>
                        <input
                            type="text"
                            name="location"
                            value={userInfo.location}
                            onChange={handleChange}
                            className="col-span-9 text-[12px] text-[#787878] border p-1"
                        />
                    </div>
                    <div className="grid grid-cols-12 items-center">
                        <div className="col-span-3 flex items-center gap-1 text-[#1c1c1c]">
                            <RiCake2Fill className="h-3 w-3" />
                            <label className="text-[12px] font-semibold">Born Date</label>
                        </div>
                        <input
                            type="text"
                            name="birthDate"
                            value={userInfo.birthDate}
                            onChange={handleChange}
                            className="col-span-9 text-[12px] text-[#787878] border p-1"
                        />
                    </div>
                    <div className="grid grid-cols-12 items-center">
                        <div className="col-span-3 flex items-center gap-1 text-[#1c1c1c]">
                            <MdWork className="h-3 w-3" />
                            <label className="text-[12px] font-semibold">Work</label>
                        </div>
                        <input
                            type="text"
                            name="work"
                            value={userInfo.work}
                            onChange={handleChange}
                            className="col-span-9 text-[12px] text-[#787878] border p-1"
                        />
                    </div>
                    <button
                        type="submit"
                        className="bg-[#1c1c1c] text-white text-[14px] w-full py-1 rounded mt-5"
                    >
                        Save
                    </button>
                    <button
                        type="button"
                        onClick={() => setEdit(false)}
                        className="bg-gray-500 text-white text-[14px] w-full py-1 rounded mt-2"
                    >
                        Cancel
                    </button>
                </form>
                :
                <div className="px-2 flex flex-col gap-y-2">
                    <div className="grid grid-cols-12 items-center">
                        <div className="col-span-3 flex items-center gap-1 text-[#1c1c1c]">
                            <IoPersonSharp className="h-3 w-3" />
                            <p className="text-[12px] font-semibold">Name</p>
                        </div>
                        <p className="col-span-9 text-[12px] text-[#787878]">{userInfo.name}</p>
                    </div>
                    <div className="grid grid-cols-12 items-center">
                        <div className="col-span-3 flex items-center gap-1 text-[#1c1c1c]">
                            <VscMention className="h-3 w-3" />
                            <p className="text-[12px] font-semibold">TagName</p>
                        </div>
                        <p className="col-span-9 text-[12px] text-[#787878]">{userInfo.tagName}</p>
                    </div>
                    <div className="grid grid-cols-12 items-center">
                        <div className="col-span-3 flex items-center gap-1 text-[#1c1c1c]">
                            <FaInfoCircle className="h-3 w-3" />
                            <p className="text-[12px] font-semibold">About</p>
                        </div>
                        <p className="col-span-9 text-[12px] text-[#787878]">{userInfo.about}</p>
                    </div>
                    <div className="grid grid-cols-12 items-center">
                        <div className="col-span-3 flex items-center gap-1 text-[#1c1c1c]">
                            <FaLocationDot className="h-3 w-3" />
                            <p className="text-[12px] font-semibold">Location</p>
                        </div>
                        <p className="col-span-9 text-[12px] text-[#787878]">{userInfo.location}</p>
                    </div>
                    <div className="grid grid-cols-12 items-center">
                        <div className="col-span-3 flex items-center gap-1 text-[#1c1c1c]">
                            <RiCake2Fill className="h-3 w-3" />
                            <p className="text-[12px] font-semibold">Born Date</p>
                        </div>
                        <p className="col-span-9 text-[12px] text-[#787878]">{userInfo.birthDate}</p>
                    </div>
                    <div className="grid grid-cols-12 items-center">
                        <div className="col-span-3 flex items-center gap-1 text-[#1c1c1c]">
                            <MdWork className="h-3 w-3" />
                            <p className="text-[12px] font-semibold">Work</p>
                        </div>
                        <p className="col-span-9 text-[12px] text-[#787878]">{userInfo.work}</p>
                    </div>
                    {/* <button
                        onClick={() => setEdit(true)}
                        className="bg-[#1c1c1c] text-white text-[14px] w-full py-1 rounded mt-5"
                    >
                        Edit
                    </button> */}
                </div>
            }
        </div>
    );
}

export default About;
