import React, { useEffect, useState } from "react";

const YesNoModal = ({ onYes, heading, onClose, type }) => {

    const reportList = [
        { id: 1, reason: "Inappropriate Content", checked: false },
        { id: 2, reason: "Spam or Advertising", checked: false },
        { id: 3, reason: "Harassment or Hate Speech", checked: false },
        { id: 4, reason: "Misinformation", checked: false }
    ];

    const [reportData, setReportData] = useState(reportList);

    const handleCheckbox = (id) => {
        setReportData((prevData) =>
            prevData.map((data) =>
                data.id === id ? { ...data, checked: !data.checked } : { ...data, checked: false }
            )
        );
    };

    useEffect(() => {
        setReportData(reportList);
    }, []);



    return (
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-80 flex items-center justify-center z-50" onClick={onClose}>
            <div className="bg-white px-1 py-4 rounded-lg shadow-lg w-11/12 max-w-lg" onClick={(e) => e.stopPropagation()}>
                <p className="text-center text-[14px] uppercase font-bold">
                    {heading}
                </p>

                {type === "report" && (
                    <div className="mt-4 px-2">
                        {
                            reportData.map((data) => (
                                <label key={data.id} className="flex items-center mb-2">
                                    <input type="checkbox" checked={data.checked} className="mr-2" onChange={() => handleCheckbox(data?.id)} />
                                    {data.reason}
                                </label>
                            ))

                        }
                    </div>
                )}

                <div className="flex items-center justify-around mt-5">
                    <button
                        onClick={() => onYes((type === "report") ? reportData?.filter((data) => data.checked) : null)}
                        className="uppercase text-[12px] px-4 py-2 rounded-md bg-black hover:bg-[#101010] text-white"
                    >
                        YES
                    </button>
                    <button
                        onClick={onClose}
                        className="uppercase text-[12px] px-4 py-2 rounded-md bg-black hover:bg-[#101010] text-white"
                    >
                        NO
                    </button>
                </div>
            </div>
        </div>
    );
};

export default YesNoModal;
