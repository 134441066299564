import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IoCaretBackCircle } from "react-icons/io5";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaRegComment, FaRegHeart, FaHeart, FaPauseCircle, FaPlayCircle } from "react-icons/fa";
import { LuRepeat2 } from "react-icons/lu";
import { FaRegBookmark, FaBookmark } from "react-icons/fa6";
import { FiBarChart2 } from "react-icons/fi";
import { AiOutlineShareAlt } from "react-icons/ai";
import { deleteGroupPost, getIndividualGroupPosts } from "../../redux/apislice/group/groupPostSlice";
import { useDispatch, useSelector } from "react-redux";
import ReactPlayer from "react-player";
import { GoMute, GoUnmute } from "react-icons/go";
import { getComments, getReply } from "../../redux/apislice/commentSlice";

const GroupPostDetails = ({ onShare }) => {
    const label = "C / FE / JOINED GROUP / POST DETAILS / V1 / JULY 27, 2024";


    const dispatch = useDispatch()
    const navigate = useNavigate();
    const params = useParams();
    const { groupId, postId } = params;

    const postDetails = useSelector((state) => state.groupPosts)
    const groupDetail = useSelector((state) => state.userGroup)
    const { groupDetails } = groupDetail

    const { loading, posts } = postDetails
    const comments = useSelector((state) => state.comments)
    const { data } = comments

    const [commentsData, setCommentsData] = useState([])
    const [likes, setLikes] = useState(false);
    const [likeCount, setLikeCount] = useState(578);
    const [bookmarks, setBookmarks] = useState(false);
    // const [commentLikes, setCommentLikes] = useState(
    //     groupPostData.map((data) => ({
    //         id: data.id,
    //         likes: false,
    //         likeCount: data.likeCount || 0,
    //     }))
    // );
    const [activeMenuId, setActiveMenuId] = useState(null);
    const [repliesVisible, setRepliesVisible] = useState({});

    const menuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setActiveMenuId(null);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef]);

    const handleLikeClick = () => {
        setLikeCount(likes ? likeCount - 1 : likeCount + 1);
        setLikes(!likes);
    };

    const handleCommentLikeClick = (commentId) => {
        // setCommentLikes((prevCommentLikes) =>
        //     prevCommentLikes.map((comment) =>
        //         comment.id === commentId
        //             ? {
        //                 ...comment,
        //                 likes: !comment.likes,
        //                 likeCount: comment.likes ? comment.likeCount - 1 : comment.likeCount + 1,
        //             }
        //             : comment
        //     )
        // );
    };

    const toggleMenu = (commentId) => {
        setActiveMenuId((prevId) => (prevId === commentId ? null : commentId));
    };

    const toggleRepliesVisibility = async (id) => {

        await dispatch(getReply(id))

        setRepliesVisible((prev) => ({
            ...prev,
            [id]: !prev[id]
        }));
    };
    const fetchPostDetails = useCallback(async () => {
        try {
            // fetch post details
            await dispatch(getIndividualGroupPosts({ postId, groupId }))
            await dispatch(getComments(postId))
        } catch (error) {
            console.error("Failed to fetch post details data:", error);
        }
    }, [ dispatch ,postId, groupId]);

    // VIDEO PLAYER FUNCTIONALITY
    const [playing, setPlaying] = useState({});
    const [muted, setMuted] = useState(false);
    const [playedSeconds, setPlayedSeconds] = useState({});
    const [duration, setDuration] = useState({});

    const handlePlayPause = (id) => {
        setPlaying(prev => {
            const newState = { ...prev };
            Object.keys(newState).forEach(key => {
                newState[key] = false;
            });
            newState[id] = !prev[id]; // Toggle between play and pause
            return newState;
        });
    };

    // SOUND FUNCTIONALITY
    const handleMute = () => {
        setMuted(!muted);
    };

    const handleProgress = (id, state) => {
        setPlayedSeconds(prev => ({ ...prev, [id]: state.playedSeconds }));
    };

    const handleDuration = (id, duration) => {
        setDuration(prev => ({ ...prev, [id]: duration }));
    };

    const formatDuration = (seconds) => {
        const date = new Date(0);
        date.setSeconds(seconds);
        const timeString = date.toISOString().substr(14, 5);
        return timeString;
    };

    const handleDelete = async () => {
        try {
            await dispatch(deleteGroupPost({ postId, groupId }))
            navigate(-1)
        } catch (error) {
            console.error("Failed to delete post:", error);
        }
    }


    useEffect(() => {
        fetchPostDetails()
    }, [fetchPostDetails])

    useEffect(() => {
        if (data) {
            setCommentsData(data)
        }
    }, [data])

    return (
        <div className="pb-28">
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-700 text-[10px] uppercase font-bold">{label}</p>
            </div>
            <div className="bg-white sticky top-0 z-10">
                <div className="px-2 flex items-center justify-start gap-4 pt-1">
                    <IoCaretBackCircle
                        onClick={() => navigate(-1)}
                        className="text-black text-[25px] cursor-pointer"
                    />
                    <div className="flex flex-col leading-4">
                        <p className="font-bold text-[12px]">Group Post</p>
                        <p className="text-[12px] text-[#898989]">{groupDetails.name}</p>
                    </div>
                </div>
                <div className="h-[0.4px] bg-[#b2b2b2] w-full my-[3px]" />
            </div>
            <div className="px-2 py-1 border-b-[0.4px] border-[#b2b2b2]">
                {/* User name, tagname */}
                <div className="flex flex-col">
                    <div className="flex items-start justify-between relative">
                        <div className="flex items-start gap-2">
                            <img
                                alt="user"
                                className="h-8 w-8 rounded-full object-cover"
                                src={posts?.created_by?.avatar}
                            />
                            <div className="flex flex-col items-center leading-4">
                                <p className="text-black text-[12px] font-bold">{posts?.created_by?.first_name} {posts?.created_by?.last_name}</p>
                                <p className="text-[#5c5c5c] text-[12px] font-thin">{posts?.created_by?.username}</p>
                            </div>
                        </div>
                        <button className="text-white bg-black px-3 py-[3px] text-[12px] rounded-full mr-5">
                            Follow
                        </button>
                        <BsThreeDotsVertical
                            onClick={() => toggleMenu(0)} // 0 to represent post menu
                            className="text-[#5c5c5c] cursor-pointer absolute -right-[6px]"
                        />
                        {activeMenuId === 0 && (
                            <div className="absolute top-0 right-2 bg-[#d9d9d9] py-1 rounded-md text-[12px]" ref={menuRef}>
                                <p className="px-3 cursor-pointer border-b-[0.4px] border-[#b2b2b2]" onClick={() => navigate(`/group/${groupId}/comment/${posts.id}`)}>comment</p>
                                <p className="px-3 cursor-pointer border-b-[0.4px] border-[#b2b2b2]" onClick={()=>navigate(`/group/${groupId}/update/${posts.id}`)}>Edit</p>
                                <p className="px-3 cursor-pointer border-b-[0.4px] border-[#b2b2b2]" onClick={handleDelete}>Delete</p>
                                <p className="px-3 cursor-pointer border-b-[0.4px] border-[#b2b2b2]">Report</p>
                                <p className="px-3 cursor-pointer">Block</p>
                            </div>
                        )}
                    </div>
                    <p className="text-black text-[12px] my-1">
                        {posts?.body}
                    </p>
                </div>
                {/* Post image */}
                {posts?.image && <img
                    alt="post"
                    src={posts?.image}
                    className="w-full h-auto object-cover rounded-[6px]"
                />}
                {posts.video && (<div className="mt-1 rounded-sm relative" >
                    <ReactPlayer
                        onClick={() => navigate(`/group/${groupId}/postview/${posts.id}`)}
                        url={posts.video}
                        playing={playing[posts.id] || false}
                        muted={muted}
                        onProgress={(state) => handleProgress(posts.id, state)}
                        onDuration={(duration) => handleDuration(posts.id, duration)}
                        width="100%"
                        height="100%"
                        className="cursor-pointer"
                    />
                    <button
                        onClick={() => handlePlayPause(posts.id)}
                        className="absolute z-30 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#00000052] text-[18px] p-[6px] rounded-full"
                    >
                        {playing[posts.id] ? <FaPauseCircle className="text-[#ffffff84]" /> : <FaPlayCircle className="text-[#FF9900]" />}
                    </button>
                    <button
                        onClick={handleMute}
                        className="absolute z-30 bottom-2 right-2 text-[13px] text-white bg-[#0000008b] p-[4px] rounded-full"
                    >
                        {muted ? <GoMute /> : <GoUnmute />}
                    </button>
                    <button className="absolute bottom-2 left-2 bg-[#0000008b] text-[8px] text-white px-[4px] py-[2px] rounded-md">
                        {formatDuration(duration[posts.id] - playedSeconds[posts.id] || 0)}
                    </button>
                </div>
                )}


                {/* Like, Comment, Share, Bookmark */}
                <div className="flex items-center justify-between mt-2 text-[#4d4d4d]">
                    <div className="flex items-center cursor-pointer gap-1" onClick={() => navigate(`/group/${groupId}/comment/${posts.id}`)}>
                        <FaRegComment className="text-[14px]" />
                        <p className="text-[12px]">{posts.comments_count}</p>
                    </div>
                    <div className="flex items-center cursor-pointer gap-1" onClick={handleLikeClick}>
                        {likes ? <FaHeart className="text-[14px] text-red-500" /> : <FaRegHeart className="text-[14px]" />}
                        <p className="text-[12px]">{posts.likes_count}</p>
                    </div>
                    <div className="flex items-center cursor-pointer gap-1">
                        <LuRepeat2 className="text-[14px]" />
                        <p className="text-[12px]">{posts.reposts_count}</p>
                    </div>
                    <div className="flex items-center cursor-pointer gap-1">
                        <FiBarChart2 className="text-[14px]" />
                        <p className="text-[12px]">{posts.view_count}</p>
                    </div>
                    <div className="flex items-center gap-2">
                        {bookmarks ?
                            <FaBookmark
                                className="text-[12px] text-blue-500 cursor-pointer"
                                onClick={() => setBookmarks(!bookmarks)}
                            /> :
                            <FaRegBookmark
                                className="text-[12px] cursor-pointer"
                                onClick={() => setBookmarks(!bookmarks)}
                            />}
                        <AiOutlineShareAlt
                            className="text-[14px] cursor-pointer"
                            onClick={() => onShare("https://www.post.share.com")}
                        />
                    </div>
                </div>
            </div>
            {/* Post time */}
            <div className="px-2 py-1 border-b-[0.4px] border-[#b2b2b2]">
                <div className="flex items-center gap-1 text-[12px] text-[#4d4d4d]">
                    <p>7:56 AM</p>
                    <p>·</p>
                    <p>21 Jul 24</p>
                    <p>·</p>
                    <p className="text-[#000000] font-bold">13.2K Views</p>
                </div>
            </div>
            {commentsData?.map((data) => {
                // const commentLike = commentLikes.find(comment => comment.id === data.id);
                return (
                    <div className="py-1 border-b-[0.4px] border-[#b2b2b2]" key={data.id}>
                        <div className="px-2 flex items-start justify-between relative">
                            <div className="flex items-start gap-2">
                                <img
                                    alt="user"
                                    className="h-7 w-7 rounded-full object-cover"
                                    src={data.created_by.avatar}
                                />
                                <div className="flex flex-col leading-[14px]">
                                    <p className="text-black text-[12px] font-bold">{data.created_by.first_name} {data.created_by.last_name}</p>
                                    <p className="text-[#5c5c5c] text-[12px] font-thin">{data.created_by.username}</p>
                                </div>
                            </div>
                            <BsThreeDotsVertical
                                onClick={() => toggleMenu(data.id)}
                                className="text-[#5c5c5c] cursor-pointer absolute right-0"
                            />
                            {activeMenuId === data.id && (
                                <div className="absolute top-[-3px] right-[7px] bg-[#d9d9d9] py-1 rounded-md text-[12px]" ref={menuRef}>
                                    <p className="px-3 py-[1px] cursor-pointer border-b-[0.4px] border-[#b2b2b2]">Reply</p>
                                    <p className="px-3 py-[1px] cursor-pointer border-b-[0.4px] border-[#b2b2b2]">Report</p>
                                    <p className="px-3 py-[1px] cursor-pointer">Block</p>
                                </div>
                            )}
                        </div>
                        <div className="pt-1">
                            <p className="text-black text-[12px] px-2">{data.body}</p>
                            <div className="flex items-center justify-between pt-[5px] px-2">
                                <p
                                    className="text-[10px] font-bold text-light-blue-500 cursor-pointer"
                                    onClick={() => toggleRepliesVisibility(data.id)}
                                >
                                    {repliesVisible[data.id] ? "Hide replies" : "View replies"}
                                </p>
                                <div
                                    className="flex items-center cursor-pointer gap-1 text-[#4d4d4d]"
                                    onClick={() => handleCommentLikeClick(data.id)}
                                >
                                    {true ? <FaHeart className="text-[12px] text-red-500" /> : <FaRegHeart className="text-[12px]" />}
                                    <p className="text-[10px]">{123}</p>
                                </div>
                            </div>
                            {/* {repliesVisible[data.id] && (
                                <div className="mt-2 pl-4 bg-[#d8d8d8]">
                                    <div className="px-2 pt-1 flex items-start justify-between relative">
                                        <div className="flex items-start gap-2">
                                            <img
                                                alt="user"
                                                className="h-7 w-7 rounded-full object-cover"
                                                src={data.userProfileImage}
                                            />
                                            <div className="flex flex-col leading-[14px]">
                                                <p className="text-black text-[12px] font-bold">{data.userName}</p>
                                                <p className="text-[#5c5c5c] text-[12px] font-thin">{data.userTagname}</p>
                                            </div>
                                        </div>
                                        <BsThreeDotsVertical
                                            className="text-[#5c5c5c] cursor-pointer absolute right-0"
                                        />
                                    </div>
                                    <div className="py-1 px-2">
                                        <p className="text-black text-[12px]">{data.postContent}</p>
                                    </div>
                                </div>
                            )} */}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default GroupPostDetails;
