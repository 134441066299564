import React, { Fragment, useEffect, useRef, useState } from "react";
import { LuDot } from "react-icons/lu";
import { FaBookmark, FaHeart, FaPauseCircle, FaPlayCircle, FaRegComment, FaRegHeart } from "react-icons/fa";
import { LuRepeat2 } from "react-icons/lu";
import { FiBarChart2 } from "react-icons/fi";
import { AiOutlineShareAlt } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { removeSavedPost } from "../../redux/apislice/postSlice";
import { GoMute, GoUnmute } from "react-icons/go";
import ReactPlayer from "react-player";
import Skeleton from "react-loading-skeleton";
import toast, { Toaster } from "react-hot-toast";
import { getSavedPost } from "../../redux/apislice/savedSilce";
import { postLike } from "../../redux/apislice/homeSlice";
import { DecryptFn } from "../../hooks/DecryptFn";
import { createRepost } from "../../redux/apislice/repostSlice";

export default function Saved({ onShare }) {
   const menuRef = useRef();
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const [menuPostId, setMenuPostId] = useState(null);

   const savedPost = useSelector((state) => state.saved);
   const { saved, loading } = savedPost
   const { saved_posts } = saved
   const decryptUserId = localStorage.getItem("userId") ? DecryptFn(localStorage.getItem("userId")) : null;


   // VIDEO PLAYER FUNCTIONALITY
   const [playing, setPlaying] = useState({});
   const [muted, setMuted] = useState(false);
   const [playedSeconds, setPlayedSeconds] = useState({});
   const [duration, setDuration] = useState({});

   const handlePlayPause = (id) => {
      setPlaying(prev => {
         const newState = { ...prev };
         Object.keys(newState).forEach(key => {
            newState[key] = false;
         });
         newState[id] = !prev[id]; // Toggle between play and pause
         return newState;
      });
   };

   // MENU FUNCTIONALITY
   const toggleMenu = (commentId) => {
      if (menuPostId === commentId) {
         setMenuPostId(null); // Close menu if already open
      } else {
         setMenuPostId(commentId); // Open menu for this comment
      }
   };

   // SOUND FUNCTIONALITY
   const handleMute = () => {
      setMuted(!muted);
   };

   const handleProgress = (id, state) => {
      setPlayedSeconds(prev => ({ ...prev, [id]: state.playedSeconds }));
   };

   const handleDuration = (id, duration) => {
      setDuration(prev => ({ ...prev, [id]: duration }));
   };

   const formatDuration = (seconds) => {
      const date = new Date(0);
      date.setSeconds(seconds);
      const timeString = date.toISOString().substr(14, 5);
      return timeString;
   };

   const handleDeleteSavedPost = async (postId) => {
      if (postId) {
         dispatch(removeSavedPost(postId)).then((result) => {
            if (result.meta.requestStatus === 'fulfilled') {
               const { detail } = result.payload
               toast.success(detail);
               dispatch(getSavedPost())
            } else {
               console.error('Save action failed');
            }
         });
      } else {
         console.error('postId is undefined or null.');
      }
   };

   const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
         setMenuPostId(null); // Close menu if clicked outside
      }
   }
   const handleLike = async (postId) => {
      const response = await dispatch(postLike(postId));
      if (response.meta.requestStatus === 'fulfilled') {
         dispatch(getSavedPost())
      } else {
         console.error('Like action failed');
      }
   };

   const handleRepost = (postId) => {
      const payloadData = { original_post_id: postId }
      dispatch(createRepost(payloadData)).then((result) => {
         if (result.meta.requestStatus === 'fulfilled') {
            if (result.payload) {
               toast.success("Repost successful");
               dispatch(getSavedPost())
            } else {
               toast.error("Removed the repost");
               dispatch(getSavedPost())
            }
         } else {
            console.error('Repost action failed');
         }
      });
   }

   useEffect(() => {
      dispatch(getSavedPost())
   }, [dispatch])

   useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
         document.removeEventListener("mousedown", handleClickOutside);
      };
   }, []);
   return (
      <Fragment>
         <div className="px-0 mb-4">
            <Toaster position="top-right" />
            {loading ?
               [1, 2, 3].map((index) => (
                  <div className="mb-2 border border-gray-300 p-1" key={index}>
                     <div className="grid grid-cols-12">
                        <div className="col-span-2">
                           <Skeleton height={43} width={43} />
                        </div>
                        <div className="col-span-10">
                           <div className="flex flex-col">
                              <div className="flex justify-between items-center">
                                 <div className="flex gap-1">
                                    <Skeleton width={100} />
                                    <Skeleton width={45} />
                                 </div>
                                 <Skeleton width={28} />
                              </div>
                              <Skeleton width={100} />
                              <Skeleton count={1} />
                           </div>
                           <Skeleton height={125} />
                           <div className="flex justify-between items-center">
                              <Skeleton height={25} width={25} />
                              <Skeleton height={25} width={25} />
                              <Skeleton height={25} width={25} />
                              <Skeleton height={25} width={25} />
                              <Skeleton height={25} width={25} />
                           </div>
                        </div>
                     </div>
                  </div>
               )) :
               saved_posts?.map((data) => {
                  const like = data?.likes?.filter((data) => data?.created_by === decryptUserId)
                  const likeStatus = like?.length ? true : false
                  return (
                     <div className="px-2 py-1 grid grid-cols-12 border-b-[0.4px] border-[#b2b2b2]" key={data.id}>
                        <div className="col-span-2">
                           <img
                              alt={"profile"}
                              src={data?.created_by?.avatar}
                              className="h-8 w-8 rounded-full object-cover"
                           />
                        </div>
                        <div className="col-span-10 -ml-2">
                           <div className="flex flex-col">
                              <div className="flex items-center justify-between relative">
                                 <div className="flex flex-col items-start leading-4 truncate mr-3">
                                    <p className="text-black text-[12px] font-bold">{data?.created_by?.first_name} {data?.created_by?.last_name}</p>
                                    <div className="flex items-center">
                                       <p className="text-[#5c5c5c] text-[12px] font-thin">{data?.created_by?.username}</p>
                                       <LuDot className="text-[#5c5c5c] text-[12px]" />
                                       <p className="text-[#5c5c5c] text-[12px] font-thin">1h</p>
                                    </div>
                                 </div>
                                 <div>

                                 </div>
                                 <BsThreeDotsVertical
                                    className="text-[#5c5c5c] cursor-pointer absolute -right-2 top-[2px]"
                                    onClick={() => toggleMenu(data.id)}
                                 />
                                 {menuPostId === data.id && (
                                    <div ref={menuRef} className="absolute right-0 mt-4 z-50">
                                       <div className="bg-black flex flex-col rounded-[5px] w-14">
                                          <div className="flex gap-2 justify-center items-center p-1 cursor-pointer" onClick={() => handleDeleteSavedPost(data.id)}>
                                             <p className="text-[10px] text-white font-bold">unsaved</p>
                                          </div>
                                       </div>
                                    </div>
                                 )}

                              </div>
                              <p className="text-black text-[12px]">{data?.body}</p>
                           </div>
                           {/* IMAGE AND VIDEO */}
                           {data?.image && <img alt={"post"} src={data.image} className="w-full h-auto object-cover rounded-[6px]" />}

                           {data?.video &&
                              <div className="mt-1 rounded-sm relative">
                                 <ReactPlayer
                                    url={data?.video}
                                    playing={playing[data.id] || false}
                                    muted={muted}
                                    onProgress={(state) => handleProgress(data.id, state)}
                                    onDuration={(duration) => handleDuration(data.id, duration)}
                                    width="100%"
                                    height="100%"
                                 />
                                 <button
                                    onClick={() => handlePlayPause(data.id)}
                                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#00000052] text-[18px] p-[6px] rounded-full"
                                 >
                                    {playing[data.id] ? <FaPauseCircle className="text-[#ffffff84]" /> : <FaPlayCircle className="text-[#FF9900]" />}
                                 </button>
                                 <button
                                    onClick={handleMute}
                                    className="absolute bottom-2 right-2 text-[13px] text-white bg-[#0000008b] p-[4px] rounded-full"
                                 >
                                    {muted ? <GoMute /> : <GoUnmute />}
                                 </button>
                                 <button className="absolute bottom-2 left-2 bg-[#0000008b] text-[8px] text-white px-[4px] py-[2px] rounded-md">
                                    {formatDuration(duration[data.id] - playedSeconds[data.id] || 0)}
                                 </button>
                              </div>
                           }

                           <div className="flex items-center justify-between mt-2 text-[#4d4d4d]">
                              <div className="flex items-center cursor-pointer gap-1" onClick={() => navigate(`/post/comment/${data?.id}`)}>
                                 <FaRegComment className="text-[14px]" />
                                 <p className="text-[12px]">{data.comments_count}</p>
                              </div>
                              <div className="flex items-center cursor-pointer gap-1" onClick={() => handleLike(data.id)}>
                                 {likeStatus ? <FaHeart className="text-[13px]" color="red" /> : <FaRegHeart className="text-[14px]" />}
                                 <p className="text-[12px]">{data.likes_count}</p>
                              </div>
                              <div className="flex items-center cursor-pointer gap-1" onClick={() => handleRepost(data.id)}>
                                 <LuRepeat2 className="text-[14px]" />
                                 <p className="text-[12px]">{data.reposts_count}</p>
                              </div>
                              <div className="flex items-center cursor-pointer gap-1">
                                 <FiBarChart2 className="text-[14px]" />
                                 <p className="text-[12px]">{data.view_count}</p>
                              </div>
                              <div className="flex items-center gap-2">

                                 <FaBookmark
                                    onClick={() => handleDeleteSavedPost(data.id)}
                                    className="text-[12px] text-blue-500 cursor-pointer"
                                 />
                                 <AiOutlineShareAlt
                                    className="text-[14px] cursor-pointer"
                                    onClick={() => onShare("https://www.media.share.com")}
                                 />
                              </div>
                           </div>
                        </div>
                     </div>
                  )
               }

               )
            }
         </div>
         {saved_posts?.length === 0 &&
            <div className="h-[35vh]  overflow-hidden flex justify-center items-center">
               <p className="text-center text-[12px] text-[#5c5c5c] font-bold" >No saved post found</p>
            </div>
         }
      </Fragment>
   )
}

