import React, { Fragment, useEffect, useRef, useState } from 'react'
import SideMenu from '../components/SideMenu'
import { IoSearch } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getBlockedPosts, postUnblock } from '../redux/apislice/postBlockSlice'
import Skeleton from 'react-loading-skeleton'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { fetchProfile } from '../redux/apislice/profileSlice'
import { getAccountPost } from '../redux/apislice/postSlice'
import { LuDot } from 'react-icons/lu'
import { CgUnblock } from "react-icons/cg";

export default function BlockedPosts() {
    const navigate = useNavigate()
    const dispatch = useDispatch()


    // Fetching user profile
    const [userDetails, setUserDetails] = useState(null);
    const [menuPostId, setMenuPostId] = useState(null);
    const [searchShow, setSearchShow] = useState(false);
    const [blockedPosts, setBlockedPosts] = useState([]);
    const menuRef = useRef();

    const profile = useSelector(state => state.profile);
    const postBlock = useSelector(state => state.postBlock);
    const { data, loading } = postBlock;

    useEffect(() => {
        if (data?.blocked_posts) {
            setBlockedPosts(data?.blocked_posts);
        }
    }, [data?.blocked_posts])

    useEffect(() => {
        dispatch(fetchProfile());
        dispatch(getBlockedPosts());
    }, [dispatch]);

    useEffect(() => {
        if (profile.user) {
            setUserDetails(profile.user);
        }
    }, [profile]);

    // Get user pofile


    // MENU FUNCTIONALITY
    const toggleMenu = (postId) => {
        if (menuPostId === postId) {
            setMenuPostId(null); // Close menu if already open
        } else {
            setMenuPostId(postId); // Open menu for this comment
        }
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setMenuPostId(null); // Close menu if clicked outside
        }
    };

    const handleUnblockPost = async (postId) => {
        try {
            const response = await dispatch(postUnblock({ postId }));
            await dispatch(getBlockedPosts());
        } catch (error) {
            console.log(error)
        }
    };


    useEffect(() => {
        if (userDetails) {
            dispatch(getAccountPost(userDetails.id));
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dispatch, userDetails]);

    const lable = "C / FE / BLOCKED POSTS PAGE / V1 / DEC 3, 2024";


    return (
        <Fragment>
            <div className="sticky top-0 w-full z-50 bg-white">
                <div className="bg-[#d9d9d9] text-center">
                    <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p>
                </div>
                <div className=" flex items-center z-50 p-2 justify-between bg-black text-white gap-2">
                    <div className="flex items-center gap-4">
                        <SideMenu />
                        {!searchShow && <p>Blocked Posts</p>}
                    </div>
                    <div className="flex items-center gap-3">
                        {searchShow && <input
                            type="text"
                            placeholder="Search for groups"
                            className="w-full h-8 px-3 border-none transition-all duration-200 rounded-full  focus:outline-none bg-[#dedede] text-black "
                        />}
                        <IoSearch className="text-[19px] cursor-pointer" onClick={() => setSearchShow(!searchShow)} />
                    </div>
                </div>
            </div>
            <div className='grid grid-cols-12'>
                <div className="mb-24 col-span-12">
                    {loading ?
                        [1, 2, 3].map((index) => (
                            <div className="mb-2 border border-gray-300 p-1" key={index}>
                                <div className="grid grid-cols-12">
                                    <div className="col-span-2">
                                        <Skeleton height={43} width={43} />
                                    </div>
                                    <div className="col-span-10">
                                        <div className="flex flex-col">
                                            <div className="flex justify-between items-center">
                                                <div className="flex gap-1">
                                                    <Skeleton width={100} />
                                                    <Skeleton width={45} />
                                                </div>
                                                <Skeleton width={28} />
                                            </div>
                                            <Skeleton width={100} />
                                            <Skeleton count={1} />
                                        </div>
                                        <Skeleton height={125} />
                                        <div className="flex justify-between items-center">
                                            <Skeleton height={25} width={25} />
                                            <Skeleton height={25} width={25} />
                                            <Skeleton height={25} width={25} />
                                            <Skeleton height={25} width={25} />
                                            <Skeleton height={25} width={25} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) : blockedPosts && blockedPosts.length > 0 ? blockedPosts?.map((item) => {
                            if (item.type === "post") {
                                return (
                                    <div className="mb-2 border-b-[0.4px] border-[#b2b2b2] px-2 py-1" key={item.data.id}>
                                        <div className="flex justify-between">
                                            <div className="flex justify-start gap-2">
                                                <img
                                                    alt="profile"
                                                    src={item.data.created_by.avatar}
                                                    className="h-8 w-8 rounded-full object-cover border border-gray-300"
                                                />

                                                <div className="flex flex-col items-start leading-4 truncate mr-3">
                                                    <p className="text-black text-[12px] font-bold">{item.data.created_by.first_name} {item.data.created_by?.last_name}</p>
                                                    <div className="flex items-center">
                                                        <p className="text-[#5c5c5c] text-[12px] font-thin">{item.data.created_by?.username}</p>
                                                        <LuDot className="text-[#5c5c5c] text-[12px]" />
                                                        <p className="text-[#5c5c5c] text-[12px] font-thin">1h</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="relative">
                                                <BsThreeDotsVertical className="text-[20px] md:text-[15px] cursor-pointer" onClick={() => toggleMenu(item.data.id)} />
                                                {menuPostId === item.data.id && (
                                                    <div ref={menuRef} className="absolute right-0 mt-4 z-40">
                                                        <div className="bg-black flex flex-col rounded-[5px] w-auto">
                                                            <div className="flex gap-2 justify-between items-center p-1 cursor-pointer" >
                                                                <p className="text-[10px] text-white font-bold" onClick={() => handleUnblockPost(item.data.id)}>Unblock</p>
                                                                <CgUnblock className="text-[0.7rem] text-white" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <p className="text-[16px] md:text-[12px] leading-[13px] mt-3 md:mt-2">
                                            {item.data.body}
                                        </p>
                                        <div className="flex justify-between items-end mt-2 md:mt-0">
                                            <div onClick={() => navigate(`/postview/${item.data.id}`)}>
                                                {item.data.image &&
                                                    <img
                                                        alt="postImg"
                                                        src={item.data.image}
                                                        className="w-full h-auto object-cover rounded-sm"
                                                    />
                                                }
                                                {item.data.video &&
                                                    <video
                                                        src={item.data.video}
                                                        className="w-full h-auto object-cover rounded-sm"
                                                    />
                                                }
                                            </div>
                                            {/* <button className="text-[15px] md:text-[10px] bg-black text-white rounded-md px-2 py-1" onClick={() => navigate(`/postview/${data.id}`)}>
                        View Details
                     </button> */}
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div className="mb-2 border-b-[0.4px] border-[#b2b2b2] px-2 py-1" key={item.data.id}>
                                        <div className="flex justify-between">
                                            <div className="flex justify-start gap-2">
                                                <img
                                                    alt="profile"
                                                    src={item.data.created_by.avatar}
                                                    className="h-8 w-8 rounded-full object-cover border border-gray-300"
                                                />

                                                <div className="flex flex-col items-start leading-4 truncate mr-3">
                                                    <p className="text-black text-[12px] font-bold">{item.data.created_by.first_name} {item.data.created_by?.last_name}</p>
                                                    <div className="flex items-center">
                                                        <p className="text-[#5c5c5c] text-[12px] font-thin">{item.data.created_by?.username}</p>
                                                        <LuDot className="text-[#5c5c5c] text-[12px]" />
                                                        <p className="text-[#5c5c5c] text-[12px] font-thin">1h</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="relative">
                                                <BsThreeDotsVertical className="text-[20px] md:text-[15px] cursor-pointer" onClick={() => toggleMenu(item.data.id)} />
                                                {menuPostId === item.data.id && (
                                                    <div ref={menuRef} className="absolute right-0 mt-4 z-40">
                                                        <div className="bg-black flex flex-col rounded-[5px] w-auto">
                                                            <div className="flex gap-2 justify-between items-center p-1 cursor-pointer" >
                                                                <p className="text-[10px] text-white font-bold" onClick={() => handleUnblockPost(item.data.id)}>Unblock</p>
                                                                <CgUnblock className="text-[0.7rem] text-white" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <p className="text-[16px] md:text-[12px] leading-[13px] mt-3 md:mt-2">
                                            {item.data.body}
                                        </p>
                                        <div className="flex justify-between items-end mt-2 md:mt-0">
                                            <div onClick={() => navigate(`/postview/${item.data.id}`)}>
                                                {item.data.image &&
                                                    <img
                                                        alt="postImg"
                                                        src={item.data.image}
                                                        className="w-full h-auto object-cover rounded-sm"
                                                    />
                                                }
                                                {item.data.video &&
                                                    <video
                                                        src={item.data.video}
                                                        className="w-full h-auto object-cover rounded-sm"
                                                    />
                                                }
                                            </div>
                                            {/* <button className="text-[15px] md:text-[10px] bg-black text-white rounded-md px-2 py-1" onClick={() => navigate(`/postview/${data.id}`)}>
                        View Details
                     </button> */}
                                        </div>
                                    </div>)
                            }
                        })
                            :
                            <p className="flex justify-center items-center mt-10 font-bold text-[12px]">
                                No Post Yet
                            </p>
                    }
                </div>
            </div>
        </Fragment>
    )
}


