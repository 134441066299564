import CryptoJS from 'crypto-js';
// Decrypt data using AES decryption
export const DecryptFn = (encryptedData) => {
    try {
        if (!encryptedData) {
            throw new Error('Encrypted data is null or undefined');
        }
        const bytes = CryptoJS.AES.decrypt(encryptedData, "secret_key");
        const decrypted = bytes.toString(CryptoJS.enc.Utf8);
        if (!decrypted) {
            throw new Error('Failed to decrypt data. Possibly incorrect key or corrupted data.');
        }
        return decrypted;
    } catch (error) {
        console.error('Decryption error:', error.message);
        return null; // Return null or a fallback value
    }
};