import React from "react";
import { useNavigate } from "react-router-dom";

const ComingSoon = () => {
    const navigate = useNavigate();
    return (
        <div className="px-5 bg-[conic-gradient(at_bottom_right,_var(--tw-gradient-stops))] from-blue-700 via-blue-800 to-gray-900 flex flex-col h-full items-center pt-[10rem]">
            <p className="text-[2rem] text-center uppercase font-bold text-white">
                coming soon
            </p>
            <button 
                onClick={() => navigate(-1)}
                className="bg-gradient-to-bl hover:bg-gradient-to-tr from-[#ffffff] via-[#000000] to-[#515152] text-white text-[12px] rounded-full px-[2rem] py-[6px] mt-[1rem] transition-all duration-500"
            >
                GO BACK
            </button>
        </div>
    )
}

export default ComingSoon