import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IoCaretBackCircle, IoSearch } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { getInvitedMembers, inviteMember } from "../../../redux/apislice/group/userGroupSlice";
import Skeleton from "react-loading-skeleton";

const AccountOwnerGroupInviteMember = () => {
    const label = "C-FE-ACCOUNTOWNER GROUP-INVITE MEMBER-V1-JULY 22, 2024";

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const { groupId } = params;
    const [searchValue, setSearchValue] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const { user } = useSelector(state => state.profile);
    const { inviteMembers, loading } = useSelector(state => state.userGroup);


    const handleInvite = async (id) => {
        const user = filteredData.find(data => data.id === id);
        if (user && user.added) {
            console.log('User is already added');
            return;
        }
        const data = {
            user_ids: [id]
        };
        try {
            // Call an API to invite
            const response = await dispatch(inviteMember({ groupId, data }));
            if (response.payload.message) {
                setFilteredData(filteredData.map(data => {
                    if (data.id === id && !data.added) {
                        return { ...data, added: true };
                    }
                    return data;
                }));
            }
        } catch (error) {
            console.error(error?.message || 'Failed to invite');
        }
    }


    const fetchLists = useCallback(async () => {
        try {
            await dispatch(getInvitedMembers({ groupId })).unwrap()
        } catch (err) {
            console.error(err.message || 'Failed to fetch lists');
        }
    }, [dispatch ,groupId]);

    useEffect(() => {
        fetchLists();
    }, [fetchLists]);

    useEffect(() => {
        if (searchValue === '') {
            setFilteredData(inviteMembers?.users);
        } else {
            setFilteredData(
                inviteMembers?.users?.filter((data) =>
                    data?.first_name?.toLowerCase().includes(searchValue.toLowerCase()) ||
                    data?.last_name?.toLowerCase().includes(searchValue.toLowerCase()) ||
                    data?.username?.toLowerCase().includes(searchValue.toLowerCase())
                )
            );
        }
    }, [searchValue, inviteMembers]);

    return (
        <div className="bg-white h-full overflow-y-scroll pb-[6.5rem]">
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-700 text-[10px] uppercase font-bold">{label}</p>
            </div>
            <div className="sticky top-0 w-full bg-black z-50">
                <div className="px-2 flex justify-between items-center gap-3 py-1 border-b-[0.4px] border-[#242424]">
                    <IoCaretBackCircle
                        onClick={() => navigate(-1)}
                        className="h-8 w-8 cursor-pointer text-white"
                    />
                    <div className="relative w-full">
                        <input
                            type="text"
                            value={searchValue}
                            placeholder="Search for people"
                            onChange={(e) => setSearchValue(e.target.value)}
                            className="w-full focus:outline-none rounded-full pl-3 pr-9 py-1 text-white bg-[#2e2e2e]"
                        />
                        {searchValue.length >= 1 && (
                            <IoSearch
                                className="absolute top-1/2 right-1 transform -translate-y-1/2 text-[24px] text-black bg-white rounded-full p-1 cursor-pointer"
                            />
                        )}
                    </div>
                </div>
            </div>
            {loading ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((data) => (
                <div key={data} className="grid grid-cols-12 px-2">
                    <div className="col-span-2">
                        <Skeleton circle width={30} height={30} />
                    </div>
                    <div className="col-span-10">
                        <div className="flex justify-between items-start">
                            <div className="flex flex-col leading-[16px]">
                                <div className="flex items-center gap-2">
                                    <Skeleton width={100} height={10} />
                                </div>
                                <Skeleton width={150} height={10} />
                            </div>
                            <Skeleton width={45} height={20} style={{ borderRadius: "30px" }} />
                        </div>
                    </div>
                </div>
            )) : filteredData?.length > 0 ? (
                filteredData?.map((data) => (
                    <div className="grid grid-cols-12 px-2 py-1 border-b-[0.4px] border-[#b2b2b2]" key={data.id}>
                        <div className="col-span-2">
                            <img
                                alt={data.first_name}
                                src={data.avatar_url}
                                className="h-8 w-8 object-cover rounded-full cursor-pointer"
                            />
                        </div>
                        <div className="col-span-10">
                            <div className="flex justify-between items-start">
                                <div className="flex flex-col leading-[16px]">
                                    <div className="flex items-center gap-2">
                                        <p className="text-black text-[14px]">{data.first_name} {data.last_name}</p>
                                    </div>
                                    <p className="text-[#5c5c5c] text-[12px]">{data.username}</p>
                                </div>
                                {!user.id !== data.id && <button onClick={() => handleInvite(data.id)} className="px-3 py-[2px] text-[12px] hover:font-bold font-normal rounded-full bg-black text-white">
                                    {data.added ? "added" : "add"}
                                </button>}
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <div className="text-center py-4">
                    <p className="text-gray-700 overflow-x-scroll">No results for "{searchValue}"</p>
                </div>
            )}
        </div>
    )
}

export default AccountOwnerGroupInviteMember;

