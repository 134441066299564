import React, { useState, useEffect, useRef } from "react";
import { ForYouData } from "../data";
import { LuDot } from "react-icons/lu";
import { LuRepeat2 } from "react-icons/lu";
import { CiBookmark } from "react-icons/ci";
import { FiBarChart2 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { AiOutlineShareAlt } from "react-icons/ai";
import { IoCaretBackCircle } from "react-icons/io5";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaRegComment, FaRegHeart } from "react-icons/fa";
import ShareModal from "../components/common/ShareModal";
import { createRepost } from "../redux/apislice/repostSlice";
import { useDispatch } from "react-redux";
import toast, { Toaster } from "react-hot-toast";

const ForYouPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [visibleMenuId, setVisibleMenuId] = useState(null);
    const menuRef = useRef(null);
    const iconRef = useRef(null);
    const [sharePost, setSharePost] = useState(false);

    const toggleMenu = (id) => {
        setVisibleMenuId((prevId) => (prevId === id ? null : id));
    };

    const handleClickOutside = (event) => {
        if (
            menuRef.current &&
            !menuRef.current.contains(event.target) &&
            iconRef.current &&
            !iconRef.current.contains(event.target)
        ) {
            setVisibleMenuId(null);
        }

    };

    const handleRepost = (postId) => {
        const payloadData = { original_post_id: postId }
        dispatch(createRepost(payloadData)).then((result) => {
            if (result.meta.requestStatus === 'fulfilled') {
                if (result.payload) {

                    toast.success("Repost successful");
                } else {
                    toast.error("Removed the repost");
                }

            } else {
                toast.error('Repost action failed');
            }
        });
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="mb-24">
            <Toaster position="top-right" />
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-700 text-[10px] uppercase font-bold">
                    C / FE / FOR YOU / V1 / AUG 14, 2024
                </p>
              
            </div>

            <div className="sticky top-0 bg-[#FF9900] border-b-[0.4px] border-[#b2b2b2] z-50">
                <div className="grid grid-cols-12 px-2 py-[6px]">
                    <div className="col-span-2">
                        <IoCaretBackCircle
                            onClick={() => navigate(-1)}
                            className="h-6 w-6 cursor-pointer text-black"
                        />
                    </div>
                    <div className="col-span-8 flex items-center justify-center">
                        <p className="text-[16px] font-semibold text-[#000000]">Your Feed</p>
                    </div>
                </div>
            </div>

            {ForYouData.map((data) => (
                <div
                    className="px-2 py-1 grid grid-cols-12 border-b-[0.4px] border-[#b2b2b2]"
                    key={data.id}
                >
                    <div className="col-span-2">
                        <img
                            alt={data.userName}
                            src={data.userProfileImage}
                            className="h-8 w-8 rounded-full object-cover"
                        />
                    </div>
                    <div className="col-span-10 -ml-2">
                        <div className="flex flex-col">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center gap-2">
                                    <p className="text-black text-[12px] font-bold">{data.userName}</p>
                                    <div className="flex items-center">
                                        <p className="text-[#5c5c5c] text-[12px] font-thin">{data.userTagname}</p>
                                        <LuDot className="text-[#5c5c5c] text-[12px]" />
                                        <p className="text-[#5c5c5c] text-[12px] font-thin">{data.postTime}</p>
                                    </div>
                                </div>
                                <div className="relative" ref={iconRef}>
                                    <BsThreeDotsVertical
                                        className="text-[#5c5c5c] cursor-pointer absolute -right-2 -top-2"
                                        onClick={() => toggleMenu(data.id)}
                                    />
                                    {visibleMenuId === data.id && (
                                        <div className="absolute top-[-8px] bg-[#ebebeb] rounded-md right-[-2px]" ref={menuRef}>
                                            <p className="px-2 py-[4px] hover:text-red-600 text-[10px] border-b-[0.4px] border-[#b2b2b2] whitespace-nowrap">
                                                menu 01
                                            </p>
                                            <p className="px-2 py-[4px] hover:text-red-600 text-[10px] border-b-[0.4px] border-[#b2b2b2] whitespace-nowrap">
                                                menu 02
                                            </p>
                                            <p className="px-2 py-[4px] hover:text-red-600 text-[10px] whitespace-nowrap">
                                                menu 03
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <p className="text-black text-[12px]">{data.postContent}</p>
                        </div>
                        {data.postImage === null ? null : (
                            <img
                                alt={data.postContent}
                                src={data.postImage}
                                className="w-full h-auto object-cover rounded-[6px]"
                            />
                        )}
                        <div className="flex items-center justify-between mt-2 text-[#4d4d4d]">
                            <div className="flex items-center cursor-pointer gap-1">
                                <FaRegComment className="text-[14px]" />
                                <p className="text-[12px]">{data.postComments}</p>
                            </div>
                            <div className="flex items-center cursor-pointer gap-1">
                                <FaRegHeart className="text-[14px]" />
                                <p className="text-[12px]">{data.postLikes}</p>
                            </div>
                            <div className="flex items-center cursor-pointer gap-1">
                                <LuRepeat2 className="text-[14px]" />
                                <p className="text-[12px]">{data.postRepost}</p>
                            </div>
                            <div className="flex items-center cursor-pointer gap-1">
                                <FiBarChart2 className="text-[14px]" />
                                <p className="text-[12px]">{data.postImpression}</p>
                            </div>
                            <div className="flex items-center gap-2">
                                <CiBookmark className="text-[14px] cursor-pointer" onClick={() => handleRepost("345679")} />
                                <AiOutlineShareAlt className="text-[14px] cursor-pointer" onClick={() => setSharePost(true)} />
                            </div>
                        </div>
                    </div>
                </div>
            ))}

            {sharePost && <ShareModal onClose={() => setSharePost(false)} />}
        </div>
    );
};

export default ForYouPage;
