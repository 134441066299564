import React, { Fragment } from "react";
import Header from "./Header";
import { MdCancel } from "react-icons/md";
import { FcOk, FcSynchronize } from "react-icons/fc";
import { previousOrders } from "../../data";

const PastOrders = () => {
   const lable = "C / FE / PAST ORDER PAGE / V1 / MAR 30, 2024";

   return (
      <Fragment>
         <div className="bg-[#d9d9d9] text-center">
            <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p>
         </div>
         <Header />
         <div className="px-2 mb-24">
            <p className="text-[16px] font-bold cursor-pointer text-gray-900 hover:text-black mb-1">
               Previous Orders
            </p>

            {previousOrders.map((data) => (
               <div className="border border-gray-300 mb-4" key={data.id}>
                  <div className="grid grid-cols-12">
                     <div className="col-span-4">
                        <div className="flex flex-col p-[5px]">
                           <p className="text-[10px] line-clamp-2 leading-3 pb-1">{data.productName}</p>
                           <img
                              alt={data.productName}
                              src={data.productImg}
                              className="w-full h-[5.3rem] rounded object-cover img-shadow"
                           />
                        </div>
                     </div>
                     <div className="col-span-8 border-l border-gray-300">
                        <div className="flex flex-col p-[5px]">
                           <p className="text-[13px]">Order Placed: <span className="font-bold text-gray-900">{data.date}</span></p>
                           <p className="text-[13px]">Total: <span className="font-bold text-gray-900">${data.productPrice}</span></p>
                           <p className="text-[13px]">Shipped To: <span className="font-bold text-gray-900">{data.customerName}</span></p>
                           <p className="text-[13px]">Order No: <span className="font-bold text-gray-900">#{data.productOrderNumber}</span></p>
                           {data.status === "Delivered" &&
                              <p className="flex items-center text-[13px] font-bold text-green-700">
                                 <FcOk className="mr-[4px] text-[14px]"/> Delivered
                              </p>
                           }
                           {data.status === "Refunded" &&
                              <p className="flex items-center text-[13px] font-bold text-gray-600">
                                 <FcSynchronize className="mr-[4px] text-[14px]"/> Refunded
                              </p>
                           }
                           {data.status === "Cancelled" &&
                              <p className="flex items-center text-[13px] font-bold text-red-600">
                                 <MdCancel className="mr-[4px] text-[14px]"/> <span>Cancelled</span>
                              </p>
                           }
                           <p className="text-[10px] font-bold cursor-pointer text-[#47d1ff] hover:text-[#36b5f0]">See More</p>
                        </div>
                     </div>
                  </div>
               </div>
            ))}
         </div>
      </Fragment>
   )
}

export default PastOrders