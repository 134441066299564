import React from 'react'
import { FaMicrophone } from 'react-icons/fa6';
import { IoCaretBackCircle } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

export default function NewsSeeMore() {
    const lable = "C / FE /  NEWS SEE MORE PAGE / V1 / AUG 10, 2024";
    const navigate = useNavigate();

    const uniquePosts = [
        {
            id: 1,
            img: "https://plus.unsplash.com/premium_photo-1699577272317-a64e1eaaa9c8?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDExNXxfaGItZGw0US00VXx8ZW58MHx8fHx8",
            headline: "Breaking News: Market Reaches New Highs",
        },
        {
            id: 2,
            img: "https://plus.unsplash.com/premium_photo-1664033881643-d9911fbf3d5e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDY5fF9oYi1kbDRRLTRVfHxlbnwwfHx8fHw%",
            headline: "Tech Industry Sees Major Innovations in AI",
        },
        {
            id: 3,
            img: "https://images.unsplash.com/photo-1478144849792-57dda02c07f7?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDExMXxfaGItZGw0US00VXx8ZW58MHx8fHx8",
            headline: "Global Summit Addresses Climate Change Solutions",

        },
        {
            id: 1,
            img: "https://plus.unsplash.com/premium_photo-1699577272317-a64e1eaaa9c8?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDExNXxfaGItZGw0US00VXx8ZW58MHx8fHx8",
            headline: "Breaking News: Market Reaches New Highs",
        },
        {
            id: 2,
            img: "https://plus.unsplash.com/premium_photo-1664033881643-d9911fbf3d5e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDY5fF9oYi1kbDRRLTRVfHxlbnwwfHx8fHw%",
            headline: "Tech Industry Sees Major Innovations in AI",
        },
        {
            id: 3,
            img: "https://images.unsplash.com/photo-1478144849792-57dda02c07f7?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDExMXxfaGItZGw0US00VXx8ZW58MHx8fHx8",
            headline: "Global Summit Addresses Climate Change Solutions",

        },
        {
            id: 1,
            img: "https://plus.unsplash.com/premium_photo-1699577272317-a64e1eaaa9c8?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDExNXxfaGItZGw0US00VXx8ZW58MHx8fHx8",
            headline: "Breaking News: Market Reaches New Highs",
        },
        {
            id: 2,
            img: "https://plus.unsplash.com/premium_photo-1664033881643-d9911fbf3d5e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDY5fF9oYi1kbDRRLTRVfHxlbnwwfHx8fHw%",
            headline: "Tech Industry Sees Major Innovations in AI",
        },
        {
            id: 3,
            img: "https://images.unsplash.com/photo-1478144849792-57dda02c07f7?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDExMXxfaGItZGw0US00VXx8ZW58MHx8fHx8",
            headline: "Global Summit Addresses Climate Change Solutions",

        },
        {
            id: 1,
            img: "https://plus.unsplash.com/premium_photo-1699577272317-a64e1eaaa9c8?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDExNXxfaGItZGw0US00VXx8ZW58MHx8fHx8",
            headline: "Breaking News: Market Reaches New Highs",
        },
        {
            id: 2,
            img: "https://plus.unsplash.com/premium_photo-1664033881643-d9911fbf3d5e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDY5fF9oYi1kbDRRLTRVfHxlbnwwfHx8fHw%",
            headline: "Tech Industry Sees Major Innovations in AI",
        },
        {
            id: 3,
            img: "https://images.unsplash.com/photo-1478144849792-57dda02c07f7?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDExMXxfaGItZGw0US00VXx8ZW58MHx8fHx8",
            headline: "Global Summit Addresses Climate Change Solutions",

        },
    ];
    return (
        <div>
            <div className="text-center bg-[#d9d9d9]">
                <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p>
            </div>
            <div className='grid grid-cols-12 bg-white mt-2 px-2 sticky top-0 z-50 p-2'>
                <div className='col-span-1 flex justify-start items-center'>
                    <IoCaretBackCircle className="text-[1.3rem] text-black cursor-pointer" onClick={() => navigate(-1)} />
                </div>
                <div className={`col-span-11 relative`}>
                    <input type="text" placeholder="Search..." className="w-full border-2 focus:outline-none focus:ring-0 rounded-full bg-white px-4 py-1"
                    />
                    <FaMicrophone className="absolute right-2 top-[10px] text-[1rem] text-gray-500" />
                </div>
            </div>
            <div className='grid grid-cols-12 mt-2 mb-28 gap-4 px-2'>
                {uniquePosts?.map((data) => (
                    <div className='col-span-6 w-36 rounded-xl  flex justify-center items-center px-1'>
                        <div className='h-44 relative w-full cursor-pointer' onClick={() => navigate("/news/read")}>
                            <img src={data.img} alt="" className='w-full h-full object-cover rounded-xl' />
                            <div className='bg-gray-200 absolute bottom-0 h-14.5 w-full rounded-xl'>
                                <p className='text-[12px] p-2 font-bold line-clamp-3'>{data.headline}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
