import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IoCaretBackCircle, IoSearch } from "react-icons/io5";
import { LuDot } from "react-icons/lu";
import { FaPauseCircle, FaPlayCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import ReactPlayer from "react-player";
import { GoMute, GoUnmute } from "react-icons/go";
import { groupPostSearch } from "../../redux/apislice/group/userGroupSlice";
import BeatLoader from "react-spinners/BeatLoader";
import { TimeFormat } from "../../hooks/TimeFormat";


const GroupJoinedSearch = () => {
    const label = "C / FE / JOINED GROUP / SEARCH / V1 / JULY 22, 2024";

    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const { groupId } = params;
    const [searchValue, setSearchValue] = useState('');
    const [showResults, setShowResults] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const groupPosts = useSelector(state => state.userGroup);
    const { loading } = groupPosts;
    const [debouncedQuery, setDebouncedQuery] = useState(searchValue);
    const debounceTimeoutRef = useRef(null);

    // VIDEO PLAYER FUNCTIONALITY
    const [playing, setPlaying] = useState({});
    const [muted, setMuted] = useState(false);
    const [playedSeconds, setPlayedSeconds] = useState({});
    const [duration, setDuration] = useState({});
    // const [initialRender, setInitialRender] = useState(true)


    const handleSearch = () => {
        setShowResults(true);
    };



    const handlePlayPause = (id) => {
        setPlaying(prev => {
            const newState = { ...prev };
            Object.keys(newState).forEach(key => {
                newState[key] = false;
            });
            newState[id] = !prev[id]; // Toggle between play and pause
            return newState;
        });
    };




    useEffect(() => {
        // Clear the previous timeout
        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
        }

        // Set a new timeout
        debounceTimeoutRef.current = setTimeout(() => {
            setDebouncedQuery(searchValue);
        }, 1000); // Adjust the debounce delay as needed (500ms in this example)

        // Cleanup function to clear the timeout if the component unmounts
        return () => {
            if (debounceTimeoutRef.current) {
                clearTimeout(debounceTimeoutRef.current);
            }
        };
    }, [searchValue]);



    useEffect(() => {
        const fetchData = async () => {
            const data = { q: debouncedQuery };
            if (debouncedQuery) {
                try {
                    const response = await dispatch(groupPostSearch({ data, groupId }));
                    setFilteredData(response.payload.posts);
                } catch (error) {
                    console.log("Error", error);
                }
            }
        };

        fetchData()
    }, [debouncedQuery, dispatch, groupId]);


    // SOUND FUNCTIONALITY
    const handleMute = () => {
        setMuted(!muted);
    };

    const handleProgress = (id, state) => {
        setPlayedSeconds(prev => ({ ...prev, [id]: state.playedSeconds }));
    };

    const handleDuration = (id, duration) => {
        setDuration(prev => ({ ...prev, [id]: duration }));
    };

    const formatDuration = (seconds) => {
        const date = new Date(0);
        date.setSeconds(seconds);
        const timeString = date.toISOString().substr(14, 5);
        return timeString;
    };


    return (
        <div className="bg-white h-full overflow-y-scroll pb-[6.5rem]">
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-700 text-[10px] uppercase font-bold">{label}</p>
            </div>

            <div className="sticky top-0 w-full bg-black z-50">
                <div className="px-2 flex justify-between items-center gap-3 py-1 border-b-[0.4px] border-[#242424]">
                    <IoCaretBackCircle
                        onClick={() => navigate(-1)}
                        className="h-8 w-8 cursor-pointer text-white"
                    />
                    <div className="relative w-full">
                        <input
                            type="text"
                            value={searchValue}
                            placeholder="Search Nature Water"
                            onChange={(e) => {
                                setSearchValue(e.target.value);
                                setShowResults(true); // Clear results on input change
                            }}
                            className="w-full focus:outline-none rounded-full pl-3 pr-9 py-1 text-white bg-[#2e2e2e]"
                        />
                        {searchValue.length >= 1 && (
                            <IoSearch
                                onClick={handleSearch}
                                className="absolute top-1/2 right-1 transform -translate-y-1/2 text-[24px] text-black bg-white rounded-full p-1 cursor-pointer"
                            />
                        )}
                    </div>
                </div>
            </div>

            {showResults ?
                (loading ? <div className="col-span-12 flex justify-center items-center mt-4">
                    <BeatLoader
                        loading={loading} color="black"
                    />
                </div> :
                    filteredData.length > 0 ? (
                        filteredData.map((data) => (
                            <div className="px-2 py-1 grid grid-cols-12 border-b-[0.4px] border-[#b2b2b2]" key={data.id}>
                                <div className="col-span-2">
                                    <img
                                        alt={data.created_by.first_name}
                                        src={data.created_by.avatar}
                                        className="h-8 w-8 rounded-full object-cover"
                                    />
                                </div>
                                <div className="col-span-10 -ml-2">
                                    <div className="flex flex-col">
                                        <div className="flex items-center justify-between">
                                            <div className="flex items-center gap-2">
                                                <p className="text-black text-[12px] font-bold">{data.created_by.first_name}</p>
                                                <div className="flex items-center">
                                                    <p className="text-[#5c5c5c] text-[12px] font-thin">{data.created_by.username}</p>
                                                    <LuDot className="text-[#5c5c5c] text-[12px]" />
                                                    <p className="text-[#5c5c5c] text-[12px] font-thin">{TimeFormat(data?.created_at)}</p>
                                                </div>
                                            </div>

                                        </div>
                                        <p className="text-black text-[12px]">{data.body}</p>
                                    </div>
                                    {data.image === null ? null :
                                        <img
                                            alt={data.body}
                                            src={data.image}
                                            className="w-full h-auto object-cover rounded-[6px] cursor-pointer"
                                            onClick={() => navigate(`/group/${groupId}/postview/${data?.id}`)}
                                        />
                                    }
                                    {data.video && (
                                        <div className="mt-1 rounded-sm relative" >
                                            <ReactPlayer
                                                onClick={() => navigate(`/group/${groupId}/postview/${data?.id}`)}
                                                url={data.video}
                                                playing={playing[data?.id] || false}
                                                muted={muted}
                                                onProgress={(state) => handleProgress(data?.id, state)}
                                                onDuration={(duration) => handleDuration(data?.id, duration)}
                                                width="100%"
                                                height="100%"
                                                className="cursor-pointer"
                                            />
                                            <button
                                                onClick={() => handlePlayPause(data?.id)}
                                                className="absolute z-30 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#00000052] text-[18px] p-[6px] rounded-full"
                                            >
                                                {playing[data?.id] ? <FaPauseCircle className="text-[#ffffff84]" /> : <FaPlayCircle className="text-[#FF9900]" />}
                                            </button>
                                            <button
                                                onClick={handleMute}
                                                className="absolute z-30 bottom-2 right-2 text-[13px] text-white bg-[#0000008b] p-[4px] rounded-full"
                                            >
                                                {muted ? <GoMute /> : <GoUnmute />}
                                            </button>
                                            <button className="absolute bottom-2 left-2 bg-[#0000008b] text-[8px] text-white px-[4px] py-[2px] rounded-md">
                                                {formatDuration(duration[data?.id] - playedSeconds[data?.id] || 0)}
                                            </button>
                                        </div>
                                    )}
                                    {/* <div className="flex items-center justify-between mt-2 text-[#4d4d4d]">
                                    <div className="flex items-center cursor-pointer gap-1">
                                        <FaRegComment className="text-[14px]" />
                                        <p className="text-[12px]">{data.comments_count}</p>
                                    </div>
                                    <div className="flex items-center cursor-pointer gap-1">
                                        <FaRegHeart className="text-[14px]" />
                                        <p className="text-[12px]">{data.likes_count}</p>
                                    </div>
                                    <div className="flex items-center cursor-pointer gap-1">
                                        <LuRepeat2 className="text-[14px]" />
                                        <p className="text-[12px]">{data.reposts_count}</p>
                                    </div>
                                    <div className="flex items-center cursor-pointer gap-1">
                                        <FiBarChart2 className="text-[14px]" />
                                        <p className="text-[12px]">{data.view_count}</p>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <CiBookmark className="text-[14px] cursor-pointer" />
                                        <AiOutlineShareAlt className="text-[14px] cursor-pointer" />
                                    </div>
                                </div> */}
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="text-center text-gray-700 py-4">No results found for "{searchValue}"</p>
                    )
                ) : (
                    <p className="text-center text-gray-700 py-4">Type to search for posts</p>
                )}
        </div>
    );
};

export default GroupJoinedSearch;
