import React, { useEffect, useCallback, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { IoCaretBackCircle } from "react-icons/io5";
import { Avatar } from "@material-tailwind/react";
import { getCreatedGroups, groupSearch } from "../../redux/apislice/group/userGroupSlice";
import { useDispatch, useSelector } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";

const responsive = {
   desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3.5,
      slidesToSlide: 1
   },
   tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 1
   },
   mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      slidesToSlide: 1
   }
};

const GroupSearch = () => {
   const lable = "C / FE / GROUP SEARCH / V1 / MAY 22, 2024";

   const navigate = useNavigate();
   const dispatch = useDispatch()
   const groupList = useSelector((state) => state.userGroup)
   const { data, loading } = groupList
   const [query, setQuery] = useState('');
   const [debouncedQuery, setDebouncedQuery] = useState(query);
   const debounceTimeoutRef = useRef(null);
   const [groupDataList, setGroupDataList] = useState([])
   const [activeTab , setActiveTab] = useState("All")

   useEffect(() => { 
      setGroupDataList(data)
   }, [data])


   const groupCategory = [
      { id: 1, name: "All" },
      { id: 2, name: "Professional" },
      { id: 3, name: "Educational" },
      { id: 4, name: "Community" },
      { id: 5, name: "Event" },
      { id: 6, name: "Marketplace" },
      { id: 7, name: "Gaming" },
      { id: 8, name: "Health & Fitness" },
      { id: 9, name: "Travel & Adventure" },
      { id: 10, name: "Art & Creativity" },
      { id: 11, name: "Environmental & Sustainability" },
      { id: 12, name: "Religious or Spiritual" },
      { id: 13, name: "Political or Advocacy" },
      { id: 14, name: "Language Learning" },
      { id: 15, name: "Book Club" },
      { id: 16, name: "Music & Entertainment" },
      { id: 17, name: "Animal Lovers" },
      { id: 18, name: "Automobile" },
      { id: 19, name: "Finance & Investment" },
      { id: 20, name: "Entrepreneurship" },
      { id: 21, name: "Hiking & Outdoor" },
      { id: 22, name: "Fashion & Beauty" },
      { id: 23, name: "Science & Technology" },
      { id: 24, name: "Health and Hygiene" },
      { id: 25, name: "Photography" },
      { id: 26, name: "Food & Cooking" },
      { id: 27, name: "Parenting" }
   ]

   const handleChange = (e) => {
      setQuery(e.target.value);
   };

   const filterGroups = (value)=>{
      setActiveTab(value)
      if(value === "All"){
         setGroupDataList(data)
      }else{
         setGroupDataList((prev)=> (Array.isArray(prev) ? prev : [])?.filter((data)=> data.type.toLowerCase().includes(value.toLowerCase())))
      }
   }

   useEffect(() => {
      // Clear the previous timeout
      if (debounceTimeoutRef.current) {
         clearTimeout(debounceTimeoutRef.current);
      }

      // Set a new timeout
      debounceTimeoutRef.current = setTimeout(() => {
         setDebouncedQuery(query);
      }, 1000); // Adjust the debounce delay as needed (500ms in this example)

      // Cleanup function to clear the timeout if the component unmounts
      return () => {
         if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
         }
      };
   }, [query]);

   const fetchGroupList = useCallback(async () => {
      try {
         const response = await dispatch(getCreatedGroups())
         return response.data;
      } catch (error) {
         return (error);
      }
   }, [dispatch])

   useEffect(() => {
      fetchGroupList()
   }, [fetchGroupList])



   useEffect(() => {
      const fetchData = async () => {
         const data = { q: debouncedQuery };
         if (debouncedQuery) {
            try {
               const response = await dispatch(groupSearch({ data }));
               const data1 = await response.json();
            } catch (error) {
               console.log("Error", error);
            }
         }
      };

      if (!debouncedQuery) {
         fetchGroupList()
      }

      fetchData()
   }, [debouncedQuery, dispatch , fetchGroupList]);


   return (
      <div className="mb-28">
         <div className="sticky top-0 bg-white z-50">
            <div className="bg-[#d9d9d9] text-center">
               <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p>
            </div>
            <div className="flex items-center gap-2 px-2 mt-1">
               <IoCaretBackCircle
                  onClick={() => navigate(-1)}
                  className="h-8 w-8 cursor-pointer"
               />
               <input
                  type="text"
                  placeholder="Search for groups"
                  value={query}
                  onChange={handleChange}
                  className="w-full h-8 px-3 border-none transition-all duration-200 rounded-full text-white focus:outline-none focus:bg-[#dedede] focus:text-black bg-black"
               />
            </div>
            <hr className="my-1 h-[0.5px] bg-gray-400 border-0" />

            <div className="px-1">
               <Carousel
                  arrows={true}
                  swipeable={true}
                  draggable={true}
                  showDots={false}
                  responsive={responsive}
                  ssr={true}
                  infinite={false}
                  slidesToSlide={1}
                  removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
               >
                  {groupCategory.map((data) => (
                     <div className={`${activeTab === data.name ? "bg-gray-500":"bg-black"} rounded-md px-[6px] py-[3px] cursor-pointer my-1 text-center w-[5rem] overflow-hidden`} key={data.id} onClick={()=> filterGroups(data.name)}>
                        <p className="text-[10px] text-white hover:text[#] line-clamp-1">
                           {data.name}
                        </p>
                     </div>
                  ))}
               </Carousel>
            </div>
         </div>

         {
            loading ?
               <div className="col-span-12 flex justify-center items-center">
                  <BeatLoader
                     loading={loading} color="black"
                  />
               </div> :

               <div className="mt-2 mb-5">
                  {(Array.isArray(groupDataList) ? groupDataList : [])?.map((item) => (
                     <div className="flex gap-2 px-2 mb-2" key={item.id}>
                        <img
                           alt={item.name}
                           src={item.image}
                           className="w-[6rem] h-[6rem] img-shadow rounded-lg object-cover cursor-pointer"
                        />
                        <div className="flex flex-col justify-between">
                           <div className="flex flex-col">
                              <div className="flex flex-col leading-3">
                                 <p className="text-[12px] line-clamp-1">{item.name}</p>
                                 <p className="text-[10px] text-gray-500">{item.members_count} Members</p>
                              </div>
                              <button className="text-[10px] px-2 py-[2px] font-bold rounded-md text-white bg-black w-[50px] mt-1">
                                 Join
                              </button>
                           </div>

                           <div className="flex items-center -space-x-4">
                              {item.latest_members_avatars.map((member, index) => (
                                 <Avatar
                                    key={index}
                                    variant="circular"
                                    alt={"members"}
                                    className="border-2 border-white h-8 w-8 cursor-pointer hover:z-10 focus:z-10"
                                    src={member.avatar}
                                 />
                              ))}
                           </div>
                        </div>
                     </div>
                  ))}


                  {Array.isArray(groupDataList) && groupDataList?.length === 0 &&
                     <div className="text-center mt-5">
                        <p className="text-black">No group found</p>
                     </div>
                  }
               </div>
         }

      </div>
   )
}

export default GroupSearch