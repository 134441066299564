import React, { Fragment, useCallback, useEffect } from "react";
import SideMenu from "../SideMenu";
import { IoSearch } from "react-icons/io5";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Avatar } from "@material-tailwind/react";
import { MdOutlineGroupAdd } from "react-icons/md";
// import { suggestedGroups } from "../../data";
import { useNavigate } from "react-router-dom";
import { getJoinedGroupList } from "../../redux/apislice/group/joinedGroupSlice";
import { useDispatch, useSelector } from "react-redux";
import { groupSuggestions } from "../../redux/apislice/group/groupSuggestionSlice";
import { joinGroupRequest } from "../../redux/apislice/group/userGroupSlice";

const responsive = {
   desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1
   },
   tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 1
   },
   mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      slidesToSlide: 1
   }
};

const GroupMain = () => {
   const lable = "C / FE / GROUPMAIN PAGE / V2 / MAY 13, 2024";

   const navigate = useNavigate();
   const dispatch = useDispatch();

   const joinedGroups = useSelector((state) => state.joinedGroups.groupList);
   const suggestedGroups = useSelector((state) => state.suggestedGroups);
   const { suggestionGroupList } = suggestedGroups
   const { joined_groups = [] } = joinedGroups;


   const fetchJoinedGroups = useCallback(async () => {
      try {
         await dispatch(getJoinedGroupList()).unwrap();
         await dispatch(groupSuggestions()).unwrap();

      } catch (error) {
         console.log("Error", error);
      }
   }, [dispatch]);

   const handleJoinGroup = async (groupId) => {
      try {
         await dispatch(joinGroupRequest({ groupId })).unwrap();
         // fetchJoinedGroups();
         navigate(`/group/joined/home/${groupId}`);
      } catch (error) {
         console.log("Error", error);
      }
   }


   useEffect(() => {
      fetchJoinedGroups();
   }, [fetchJoinedGroups]);

   return (
      <Fragment>
         <div className="sticky top-0 bg-white z-50">
            <div className="bg-[#d9d9d9] text-center">
               <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p>
            </div>

            <div className="flex items-center justify-between gap-2 px-2 mt-1">
               <div className="flex items-center gap-4">
                  <SideMenu />
                  <p>Groups</p>
               </div>
               <div className="flex items-center gap-3">
                  <IoSearch className="text-[19px] cursor-pointer" onClick={() => navigate("/group/search")} />
                  <MdOutlineGroupAdd className="text-[23px] cursor-pointer" onClick={() => navigate("/group/create")} />
               </div>
            </div>
            <hr className="my-1 h-[0.5px] bg-gray-300 border-0" />
            {suggestionGroupList?.recommended_groups?.length !== 0 && <div className="mt-1">
               <p className="px-1 text-[12px] font-bold">Joined Groups</p>
               <Carousel
                  arrows={true}
                  swipeable={true}
                  draggable={true}
                  showDots={false}
                  responsive={responsive}
                  ssr={true}
                  infinite={true}
                  removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                  className="pt-1"
               >
                  {joined_groups?.map((data) => (
                     <div className="px-[4px] select-none relative" key={data.id} onClick={() => navigate(`/group/joined/home/${data.id}`)}>
                        <img
                           alt={data.name}
                           src={data.image}
                           className="w-full h-[3rem] rounded-tl-xl rounded-tr-xl object-cover cursor-pointer"
                        />
                        <div className="bg-black">
                           <p className="text-[10px] text-white p-1 line-clamp-1">{data.name}</p>
                        </div>
                        {data?.notifications &&
                           <div className="absolute top-0 right-0 h-3 w-3 rounded-full bg-red-500 border-[2px] border-[#e3e3e3]">{data.members_count}</div>
                        }
                     </div>
                  ))}
               </Carousel>

               <hr className="my-1 h-[0.5px] bg-gray-400 border-0" />
            </div>}
         </div>

         {suggestionGroupList?.recommended_groups?.length > 0 && <div className="mt-2 mb-5">
            <p className="px-2 mb-1 text-[12px] font-bold">Suggested Groups</p>
            {suggestionGroupList?.recommended_groups?.length > 0 && suggestionGroupList?.recommended_groups?.map((data) => (
               <div className="flex gap-2 px-2 mb-2" key={data.id}>
                  <img
                     alt={data.name}
                     src={data.image}
                     className="w-[6rem] h-[6rem] img-shadow rounded-lg object-cover cursor-pointer"
                  />
                  <div className="flex flex-col justify-between">
                     <div className="flex flex-col">
                        <div className="flex flex-col leading-3">
                           <p className="text-[12px] line-clamp-1">{data.name}</p>
                           <p className="text-[10px] text-gray-500">{data?.members} Members</p>
                        </div>
                        <button onClick={() => handleJoinGroup(data.id)} className="text-[10px] px-2 py-[2px] font-bold rounded-md text-white bg-black w-[50px] mt-1">
                           Join
                        </button>
                     </div>

                     <div className="flex items-center -space-x-4">
                        {data?.latest_members_avatars?.map((member, index) => (
                           <Avatar
                              key={index}
                              variant="circular"
                              alt={"memberProfile"}
                              className="border-2 border-white h-8 w-8 cursor-pointer hover:z-10 focus:z-10"
                              src={member.avatar}
                           />
                        ))}
                     </div>
                  </div>
               </div>
            ))}
         </div>}
         {suggestionGroupList?.recommended_groups?.length === 0 && <div className="mt-2 mb-5">
            <p className="px-2 mb-1 text-[12px] font-bold">Joined Groups</p>
            {joined_groups?.length > 0 && joined_groups?.map((data) => (
               <div className="flex gap-2 px-2 mb-2" key={data.id} onClick={() => navigate(`/group/joined/home/${data.id}`)}>
                  <img
                     alt={data.name}
                     src={data.image}
                     className="w-[6rem] h-[6rem] img-shadow rounded-lg object-cover cursor-pointer"
                  />
                  <div className="flex flex-col gap-2">
                     <div className="flex flex-col">
                        <div className="flex flex-col leading-3">
                           <p className="text-[12px] line-clamp-1">{data.name}</p>
                           <p className="text-[10px] text-gray-500">{data?.members_count} Members</p>
                        </div>
                     </div>

                     <div className="flex items-center -space-x-4">
                        {data?.latest_members_avatars?.map((member, index) => (
                           <Avatar
                              key={index}
                              variant="circular"
                              alt={"memberProfile"}
                              className="border-2 border-white h-8 w-8 cursor-pointer hover:z-10 focus:z-10"
                              src={member.avatar}
                           />
                        ))}
                     </div>
                  </div>
               </div>
            ))}
         </div>
         }

        {
            joined_groups?.length === 0 && suggestionGroupList?.recommended_groups?.length === 0 &&
            <div className="flex justify-center items-center">
               <p className="text-[12px] text-black">No groups available</p>
            </div>
         }
      </Fragment>
   )
}

export default GroupMain