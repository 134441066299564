export const post = [
   {
      id: 1,
      firstName: "John",
      lastName: "Smith 🧑‍⚕️",
      userName: "@johnsmith",
      profileImage: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      postMessage: "Excited for the upcoming game!",
      postImage: "https://images.unsplash.com/photo-1511512578047-dfb367046420?q=80&w=1771&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      comments: "150k",
      likes: "20k",
      repost: "15k",
      viewed: "300k",
      bookmarked: "12k",
      share: "8.5K",
      postTimeAgo: "4h ago",
      bio: "Fanatic sports enthusiast. Can't miss a game for the world!",
      commentMessage: "Totally agree, can't wait!"
   },
   {
      id: 2,
      firstName: "Emily",
      lastName: "Johnson 🧑‍🔧",
      userName: "@emilyjohnson",
      profileImage: "https://images.unsplash.com/photo-1580489944761-15a19d654956?q=80&w=1961&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      postMessage: "What a nail-biter!",
      postImage: "https://images.unsplash.com/photo-1551958219-acbc608c6377?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      comments: "100k",
      likes: "30k",
      repost: "10k",
      viewed: "250k",
      bookmarked: "8k",
      share: "520",
      postTimeAgo: "2 days ago",
      bio: "Sports enthusiast and commentator. Always ready to analyze the game!",
      commentMessage: "Absolutely thrilling, isn't it?"
   },
   {
      id: 3,
      firstName: "Michael",
      lastName: "Williams 👮",
      userName: "@michaelwilliams",
      profileImage: "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      postMessage: "Go team go!",
      postImage: "https://images.unsplash.com/photo-1548686304-89d188a80029?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      comments: "120k",
      likes: "25k",
      repost: "12k",
      viewed: "280k",
      bookmarked: "10k",
      share: "895",
      postTimeAgo: "20h ago",
      bio: "Passionate about sports and victory. Believe in the power of teamwork!",
      commentMessage: "Absolutely, teamwork makes the dream work!"
   },
   {
      id: 4,
      firstName: "Jessica",
      lastName: "Brown 💃",
      userName: "@jessicabrown",
      profileImage: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      postMessage: "Such an intense match!",
      postImage: "https://images.unsplash.com/photo-1534423861386-85a16f5d13fd?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      comments: "90k",
      likes: "35k",
      repost: "8k",
      viewed: "200k",
      bookmarked: "6k",
      share: "50K",
      postTimeAgo: "3 days ago",
      bio: "Die-hard sports fan. Living and breathing every moment of the game!",
      commentMessage: "Absolutely thrilling, isn't it?"
   },
   {
      id: 5,
      firstName: "David",
      lastName: "Davis 🏸",
      userName: "@daviddavis",
      profileImage: "https://images.unsplash.com/photo-1474176857210-7287d38d27c6?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      postMessage: "Can't wait to watch the game!",
      postImage: "https://images.unsplash.com/photo-1493711662062-fa541adb3fc8?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      comments: "80k",
      likes: "15k",
      repost: "10k",
      viewed: "180k",
      bookmarked: "5k",
      share: "10K",
      postTimeAgo: "5 days ago",
      bio: "Devoted sports aficionado. Game nights are the best nights!",
      commentMessage: "Absolutely thrilling, isn't it?"
   },
   {
      id: 6,
      firstName: "Sarah",
      lastName: "Miller 💻",
      userName: "@sarahmiller",
      profileImage: "https://images.unsplash.com/photo-1470441623172-c47235e287ee?q=80&w=2072&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      postMessage: "Let's bring home the trophy!",
      postImage: "https://images.unsplash.com/photo-1637635753233-b45f6539136d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      comments: "110k",
      likes: "18k",
      repost: "9k",
      viewed: "220k",
      bookmarked: "7k",
      share: "35K",
      postTimeAgo: "10 days ago",
      bio: "Cheering for victory! Let's make history!",
      commentMessage: "Totally agree, can't wait!"
   },
   {
      id: 7,
      firstName: "Ryan",
      lastName: "Taylor ♟️",
      userName: "@ryantaylor",
      profileImage: "https://images.unsplash.com/photo-1535467221272-12fb327de525?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      postMessage: "Hoping for a win!",
      postImage: "https://images.unsplash.com/photo-1637203725059-53f993d6ae02?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      comments: "70k",
      likes: "22k",
      repost: "7k",
      viewed: "160k",
      bookmarked: "4k",
      share: "960",
      postTimeAgo: "15h ago",
      bio: "Believes in the power of team spirit. Always cheering for the underdog!",
      commentMessage: "Absolutely thrilling, isn't it?"
   },
   {
      id: 8,
      firstName: "Amanda",
      lastName: "Anderson 😋",
      userName: "@amandaanderson",
      profileImage: "https://images.unsplash.com/photo-1505159401534-f62f81037389?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      postMessage: "Cheering for the team!",
      postImage: "https://images.unsplash.com/photo-1553778263-73a83bab9b0c?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      comments: "95k",
      likes: "28k",
      repost: "6k",
      viewed: "190k",
      bookmarked: "6k",
      share: "85K",
      postTimeAgo: "16h ago",
      bio: "Enthusiastic supporter of the team. Let's make every game memorable!",
      commentMessage: "Absolutely thrilling, isn't it?"
   },
   {
      id: 9,
      firstName: "Matthew",
      lastName: "Garcia 🎤",
      userName: "@matthewgarcia",
      profileImage: "https://images.unsplash.com/photo-1551628723-952088378fd3?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      postMessage: "Let's go, team!",
      postImage: "https://images.unsplash.com/photo-1529247901658-3137e59ba087?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      comments: "85k",
      likes: "17k",
      repost: "5k",
      viewed: "170k",
      bookmarked: "3k",
      share: "4.1K",
      postTimeAgo: "1h ago",
      bio: "Cheering for every player on the team. Let's win this!",
      commentMessage: "Absolutely thrilling, isn't it?"
   },
   {
      id: 10,
      firstName: "Jennifer",
      lastName: "Martinez 💵",
      userName: "@jennifermartinez",
      profileImage: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      postMessage: "Excited to see the game!",
      postImage: "https://images.unsplash.com/photo-1504305754058-2f08ccd89a0a?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      comments: "75k",
      likes: "19k",
      repost: "4k",
      viewed: "150k",
      bookmarked: "3k",
      share: "102K",
      postTimeAgo: "3h ago",
      bio: "Passionate about sports and the community it creates. Let's enjoy the game together!",
      commentMessage: "Totally agree, can't wait!"
   }
];

export const whatsHappening = [
   {
      id: 1,
      topic: "Trending in Sports",
      hashTag: "#Ronaldo",
      posts: "9,678",
   },
   {
      id: 2,
      topic: "Trending in News",
      hashTag: "#Club America",
      posts: "15.1K",
   },
   {
      id: 3,
      topic: "Trending in USA",
      hashTag: "#Moscow",
      posts: "105K",
   },
   {
      id: 4,
      topic: "Trending in UK",
      hashTag: "#WPLFinal",
      posts: "6,017",
   },
   {
      id: 5,
      topic: "Trending in USA",
      hashTag: "#Trump and Musk",
      posts: "34.1K",
   },
];

export const whoToFollow = [
   {
      id: 1,
      firstName: "Emily",
      lastName: "Johnson 🧑‍🔧",
      userName: "@emilyjohnson",
      profileImage: "https://images.unsplash.com/photo-1580489944761-15a19d654956?q=80&w=1961&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
   },
   {
      id: 2,
      firstName: "David",
      lastName: "Davis 🏸",
      userName: "@daviddavis",
      profileImage: "https://images.unsplash.com/photo-1474176857210-7287d38d27c6?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
   },
   {
      id: 3,
      firstName: "Ryan",
      lastName: "Taylor ♟️",
      userName: "@ryantaylor",
      profileImage: "https://images.unsplash.com/photo-1535467221272-12fb327de525?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
   },
];

export const notifications = [
   {
      id: 1,
      day: "Today",
      updates: [
         {
            id: 1,
            time: "10:41 am",
            notify: "Jack Devis “commented” on your post “Really it is cool video I enjoyed it”",
            profileImage: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
         },
         {
            id: 2,
            time: "11:12 pm",
            notify: "Neena simon “replied” to your message “I think we should meet in this week”",
            profileImage: "https://images.unsplash.com/photo-1645378999013-95abebf5f3c1?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
         },
      ]
   },
   {
      id: 2,
      day: "Yesterday",
      updates: [
         {
            id: 1,
            time: "08:10 am",
            notify: "Sophia Anderson 'posted' in a group you're in 'Discussion: Future of Technology",
            profileImage: "https://images.unsplash.com/photo-1645378999488-63138abdecd2?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
         },
         {
            id: 2,
            time: "07:50 am",
            notify: "Chris Williams 'tagged' you in a photo 'Throwback to our road trip!",
            profileImage: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
         },
         {
            id: 3,
            time: "02:58 pm",
            notify: "Emily White 'mentioned' you in a comment 'Great job on the presentation!",
            profileImage: "https://images.unsplash.com/photo-1484863137850-59afcfe05386?q=80&w=1771&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
         },
         {
            id: 4,
            time: "11:26 pm",
            notify: "Michael Brown 'shared' your post '10 Tips for Healthy Living",
            profileImage: "https://images.unsplash.com/photo-1645378999496-33c8c2afe38d?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
         },
      ]
   }
];

export const biggestDiscountProducts = [
   {
      id: 1,
      imgUrl: "https://m.media-amazon.com/images/I/81CgtwSII3L._SX679_.jpg",
      name: "IPhone 15 Pro",
      description: "FORGED IN TITANIUM — iPhone 15 Pro has a strong and light aerospace-grade titanium design with a textured matte-glass back. It also features a Ceramic Shield front thats tougher than any smartphone glass. And its splash, water, and dust resistant.",
      price: 1541.55,
      discount: 10,
      category: "Electronics"
   },
   {
      id: 2,
      imgUrl: "https://m.media-amazon.com/images/I/719C6bJv8jL._SX679_.jpg",
      name: "MacBook Air",
      description: "STRIKINGLY THIN DESIGN – The redesigned MacBook Air is more portable than ever and weighs just 1.24 kg (2.7 pounds). It’s the ultra-capable laptop that lets you work, play or create just about anything — anywhere.",
      price: 1384.00,
      discount: 9,
      category: "Electronics"
   },
   {
      id: 3,
      imgUrl: "https://m.media-amazon.com/images/I/81gC7frRJyL._SX679_.jpg",
      name: "iPad Pro 11",
      description: "iPad Pro is the ultimate iPad experience, with the astonishing performance of the M2 chip, superfast wireless connectivity and next-generation Apple Pencil experience. Plus powerful productivity features in iPadOS.",
      price: 921.45,
      discount: 11.5,
      category: "Electronics"
   },
   {
      id: 4,
      imgUrl: "https://m.media-amazon.com/images/I/71zFyzO8iEL._SL1500_.jpg",
      name: "Titan Analog White Dial Men's Watch",
      description: "EDGE CERAMICS - WHITE",
      price: 481.75,
      discount: 22,
      category: "Accessories"
   },
   {
      id: 5,
      imgUrl: "https://m.media-amazon.com/images/I/61F0JO6kw+L._SX679_.jpg",
      name: "4 Seater Leatherrate Tufted Sofa",
      description: "Modern Chesterfield Design: Designed with the traditional modern Chesterfield style in mind, this piece hits all the key elements, with deep button tufting, nailhead accents, scrolled arms, and stylish legs.",
      price: 421.57,
      discount: 18,
      category: "Furniture"
   },
   {
      id: 6,
      imgUrl: "https://m.media-amazon.com/images/I/71fCp3BBCUL._SL1500_.jpg",
      name: "Memoria London Blue",
      description: "Necklace Sets For Women & Girls Silver Tennis Necklace For Her Single Line Stone Wedding Silver Necklaces Set Valentine Day Jewelry",
      price: 807.35,
      discount: 15,
      category: "Jewelry"
   },
   {
      id: 7,
      imgUrl: "https://m.media-amazon.com/images/I/91pn8t8xiCL._SL1500_.jpg",
      name: "Legendary Whitetails",
      description: "Men's Standard Camp Night Berber Lined Hooded Flannel Shirt Jacket, Arrowood Barnwood Plaid, Small",
      price: 293.14,
      discount: 5.5,
      category: "Clothing"
   },
];


export const limitedTimeDiscountProducts = [
   {
      id: 1,
      imgUrl: "https://m.media-amazon.com/images/I/81Os1SDWpcL._SX679_.jpg",
      name: "iPhone 15 Pro Max - 256GB",
      description: "IPhone 15 Pro Max has a strong and light aerospace-grade titanium design with a textured matte-glass back. It also features a Ceramic Shield front that’s tougher than any smartphone glass. And it’s splash, water, and dust resistant.",
      price: 1791.76,
      discount: 5.5,
   },
   {
      id: 2,
      imgUrl: "https://m.media-amazon.com/images/I/71ZP6U9sWTL._SX679_.jpg",
      name: "IPhone 15 Plus",
      description: "IPhone 15 Plus features a durable color-infused glass and aluminum design. It’s splash, water, and dust resistant. The Ceramic Shield front is tougher than any smartphone glass. And the 6.7 Super Retina XDR display",
      price: 1094.91,
      discount: 15,
   },
   {
      id: 3,
      imgUrl: "https://m.media-amazon.com/images/I/71v2jVh6nIL._SX679_.jpg",
      name: "IPhone 15 (256 GB) - Pink",
      description: "48MP MAIN CAMERA WITH 2X TELEPHOTO — The 48MP Main camera shoots in super-high resolution. So it’s easier than ever to take standout photos with amazing detail. The 2x optical-quality Telephoto lets you frame the perfect close-up.",
      price: 974.57,
      discount: 4,
   },
   {
      id: 4,
      imgUrl: "https://m.media-amazon.com/images/I/611mRs-imxL._SX679_.jpg",
      name: "IPhone 14 (256 GB) - (Product) RED",
      description: "A15 Bionic chip with 5-core GPU for lightning-fast performance. Superfast 5G cellular",
      price: 794.17,
      discount: 13,
   },
   {
      id: 5,
      imgUrl: "https://m.media-amazon.com/images/I/71REplb5oZL._SX679_.jpg",
      name: "IPhone 15 (512 GB) - Yellow",
      description: "The superfast chip powers advanced features like computational photography, fluid Dynamic Island transitions, and Voice Isolation for phone calls. And A16 Bionic is incredibly efficient to help deliver great all-day battery life.",
      price: 1322.46,
      discount: 19,
   },
];

export const multiPackDiscountProducts = [
   {
      id: 1,
      imgUrl: "https://m.media-amazon.com/images/I/71zj-tRx4mL._SX679_.jpg",
      name: "MSI Gaming Raider GE66",
      description: "12th Generation Intel Core i7-12700H Up To 4.70 GHz",
      price: 4993.56,
      discount: 25,
   },
   {
      id: 2,
      imgUrl: "https://m.media-amazon.com/images/I/618d5bS2lUL._SX679_.jpg",
      name: "Apple 2023 MacBook Pro",
      description: "The Apple M3 Pro chip, with a 12-core CPU and 18-core GPU using hardware-accelerated ray tracing, delivers amazing performance for demanding workflows like manipulating gigapixel panoramas or compiling millions of lines of code. M3 Max, with an up to 16-core CPU and up to 40-core GPU, drives extreme performance for the most advanced workflows like rendering intricate 3D content or developing transformer models with billions of parameters.",
      price: 3007.04,
      discount: 30,
   },
   {
      id: 3,
      imgUrl: "https://m.media-amazon.com/images/I/513N7JQ6xPL._SX679_.jpg",
      name: "Alienware m18 R1",
      description: "Intel i9-13980HX (up to 5.60 GHz. 36MB Cache, 24 Cores) RAM: 64GB, 2x32GB, DDR5, 4800MHz // Storage: 1TB SSD",
      price: 5426.75,
      discount: 80,
   },
   {
      id: 4,
      imgUrl: "https://m.media-amazon.com/images/I/71XwXWxa6QL._SX679_.jpg",
      name: "Galaxy Z Fold5 5G",
      description: "Galaxy Z Fold5 does a lot in one hand with its 15.73 cm(6.2-inch) Cover Screen. Unfolded, the 19.21 cm(7.6-inch) Main Screen lets you really get into the zone. Pushed-back bezels and the Under Display Camera means there's more screen and no black dot getting between you and the breathtaking Infinity Flex Display.",
      price: 1985.43,
      discount: 65,
   },
   {
      id: 5,
      imgUrl: "https://m.media-amazon.com/images/I/61RZDb2mQxL._SX679_.jpg",
      name: "S23 5G - Samsung Galaxy",
      description: "More light for your night - Get ready for a Gallery full of epic night shots everyone will want. Nightography's enhanced AI keeps details clear, so low light photos and videos will be bright and colorful from dusk to dawn and back again.",
      price: 782.13,
      discount: 35,
   },
   {
      id: 6,
      imgUrl: "https://m.media-amazon.com/images/I/71XwXWxa6QL._SX679_.jpg",
      name: "Galaxy Z Fold5 5G",
      description: "Galaxy Z Fold5 does a lot in one hand with its 15.73 cm(6.2-inch) Cover Screen. Unfolded, the 19.21 cm(7.6-inch) Main Screen lets you really get into the zone. Pushed-back bezels and the Under Display Camera means there's more screen and no black dot getting between you and the breathtaking Infinity Flex Display.",
      price: 1985.43,
      discount: 65,
   },
   {
      id: 7,
      imgUrl: "https://m.media-amazon.com/images/I/61RZDb2mQxL._SX679_.jpg",
      name: "S23 5G - Samsung Galaxy",
      description: "More light for your night - Get ready for a Gallery full of epic night shots everyone will want. Nightography's enhanced AI keeps details clear, so low light photos and videos will be bright and colorful from dusk to dawn and back again.",
      price: 782.13,
      discount: 35,
   }
];

export const bestSellInCloth = [
   {
      id: 1,
      imgUrl: "https://m.media-amazon.com/images/I/91HHQYXzlQL._SX569_.jpg",
      name: "U.S. POLO ASSN. Men's T-Shirt",
      description: "This polo is crafted from cotton blend and features a short button placket and short sleeves.",
      price: 18,
      discount: 50,
   },
   {
      id: 2,
      imgUrl: "https://m.media-amazon.com/images/I/912O2qijqGL._SX569_.jpg",
      name: "U.S. POLO ASSN. Men's Shirt",
      description: "This playful checked shirt is cut from fine cotton blend to help you relax and move.",
      price: 21,
      discount: 22,
   },
   {
      id: 3,
      imgUrl: "https://m.media-amazon.com/images/I/71rZOUyTCkL._SY741_.jpg",
      name: "Allen Solly Men's T-Shirt",
      description: "Ace weekend dressing in this Blue Print Polo Neck T-shirt from Allen Solly by Allen Solly.",
      price: 24,
      discount: 15,
   },
   {
      id: 4,
      imgUrl: "https://m.media-amazon.com/images/I/61IqBh-wWYL._SY741_.jpg",
      name: "Allen Solly Men's Shirt",
      description: "Update your look with this Blue Check Slim Fit shirt from Allen Solly by Allen Solly and enjoy the attention.",
      price: 19,
      discount: 12,
   },
   {
      id: 5,
      imgUrl: "https://m.media-amazon.com/images/I/91z9nf9Oz5L._SX679_.jpg",
      name: "Lee Men's Yellow Slim Fit Shirt",
      description: "Get vacation-ready with this pale yellow striped shirt from Lee. Made from 100% cotton, it is comfortable and breathable. The slim fit and full sleeves make it perfect for casual wear. ",
      price: 32,
      discount: 25,
   },
   {
      id: 6,
      imgUrl: "https://m.media-amazon.com/images/I/91nr9CZxh5L._SX679_.jpg",
      name: "Lee Men's Shirt",
      description: "This blue Lee shirt is sure to brighten up your wardrobe. Made from 100% cotton, it is comfortable and breathable. The classic checked pattern and half sleeves give it a stylish look. Add a denim jacket and a pair of chinos to complete the outfit.",
      price: 28,
      discount: 30,
   },
   {
      id: 7,
      imgUrl: "https://m.media-amazon.com/images/I/81ygioRYsRL._SX679_.jpg",
      name: "Lee Men's Shirt",
      description: "Add a spot of fun to your daily wear wardrobe with this Lee checked shirt in a sunny yellow. It is made from 100% cotton for comfortable wear. The half sleeves and checked pattern make it perfect for an effortless vibe. Team it up with tinted jeans and sandals for a relaxed look.",
      price: 32,
      discount: 40,
   },
];

export const bestSellInTech = [
   {
      id: 1,
      imgUrl: "https://m.media-amazon.com/images/I/71YVhrFnCJL._SX679_.jpg",
      name: "Microwave Oven",
      description: "Samsung 32L, Slim Fry, Convection Microwave Oven with Tandoor and Curd making",
      price: 239.86,
      discount: 15,
   },
   {
      id: 2,
      imgUrl: "https://m.media-amazon.com/images/I/71yL9n+L4-L._SX679_.jpg",
      name: "Samsung Split AC with inverter compressor",
      description: "Samsung 1.5 Ton 5 Star (5-in-1 Convertible Wind-Free Cooling, 2023 Model, AR18CYNAMWK",
      price: 887.35,
      discount: 36,
   },
   {
      id: 3,
      imgUrl: "https://m.media-amazon.com/images/I/614fkA7aVEL._SY741_.jpg",
      name: "Samsung AI Enabled Smart Refrigerator",
      description: "Samsung 653 L, 3 Star, Frost Free, Double Door, Convertible 5-in-1 Digital Inverter, Side By Side AI Enabled Smart Refrigerator with WiFi (RS76CG8003S9HL, Silver, Refined Inox, 2024 Model)",
      price: 1355.19,
      discount: 30,
   },
   {
      id: 4,
      imgUrl: "https://m.media-amazon.com/images/I/71cDdw7Vk7L._SX679_.jpg",
      name: "Front Load Washing Machine",
      description: "LG 8 Kg 5 Star Inverter Direct Drive Touch Panel Fully Automatic Front Load Washing Machine (FHM1408BDM, Steam for Hygiene, In-Built Heater, 6 Motion DD, Middle Black)",
      price: 575.53,
      discount: 27,
   },
   {
      id: 5,
      imgUrl: "https://m.media-amazon.com/images/I/81wl4-fUE9L._SX522_.jpg",
      name: "JBL Partybox 110",
      description: "JBL Partybox 110, Wireless Bluetooth Party Speaker, 160W Monstrous Pro Sound, Dynamic Light Show, Upto 12Hrs Playtime, Built-in Powerbank, Guitar & Mic Input, PartyBox App, Splashproof (Black)",
      price: 431.73,
      discount: 22,
   },
   {
      id: 6,
      imgUrl: "https://m.media-amazon.com/images/I/81N4+wEy+kL._SX522_.jpg",
      name: "Sony Bravia 4K Ultra HD Smart LED Google TV",
      description: "Sony Bravia 164 cm (65 inches) 4K Ultra HD Smart LED Google TV KD-65X74L (Black)",
      price: 1677.79,
      discount: 46,
   },
   {
      id: 7,
      imgUrl: "https://m.media-amazon.com/images/I/511KgtXIs5L._SX679_.jpg",
      name: "Hindware Smart - kitchen chimney",
      description: "Hindware Smart Appliances Skyla Neo 60 Cm kitchen chimney comes with Autoclean technology and maximum suction power 1350 m3/hr having filterless and motion sensor technology (Black, 60cm)",
      price: 395.64,
      discount: 55,
   },
];

export const previousOrders = [
   {
      id: 1,
      status: "Delivered",
      productName: "Hindware Smart - kitchen chimney",
      productImg: "https://m.media-amazon.com/images/I/511KgtXIs5L._SX679_.jpg",
      productPrice: "178",
      productOrderNumber: "520784",
      customerName: "John Smith",
      date: "Mar 25, 2024"
   },
   {
      id: 2,
      status: "Refunded",
      productName: "MSI Gaming Raider GE66",
      productImg: "https://m.media-amazon.com/images/I/71zj-tRx4mL._SX679_.jpg",
      productPrice: "3745",
      productOrderNumber: "978156",
      customerName: "John Smith",
      date: "Mar 27, 2024"
   },
   {
      id: 3,
      status: "Cancelled",
      productName: "IPhone 15 Pro",
      productImg: "https://m.media-amazon.com/images/I/81CgtwSII3L._SX679_.jpg",
      productPrice: "1387",
      productOrderNumber: "499225",
      customerName: "John Smith",
      date: "Mar 30, 2024"
   },
   {
      id: 4,
      status: "Delivered",
      productName: "Allen Solly Men's T-Shirt",
      productImg: "https://m.media-amazon.com/images/I/71rZOUyTCkL._SY741_.jpg",
      productPrice: "20",
      productOrderNumber: "859426",
      customerName: "John Smith",
      date: "Mar 28, 2024"
   },
   {
      id: 5,
      status: "Delivered",
      productName: "Lee Men's Shirt",
      productImg: "https://m.media-amazon.com/images/I/81ygioRYsRL._SX679_.jpg",
      productPrice: "19",
      productOrderNumber: "785402",
      customerName: "John Smith",
      date: "Mar 20, 2024"
   },
];

export const messageChat = [
   {
      id: 1,
      message: "Hi",
      messageTime: "2:05 PM",
      imageMedia: null,
      videoMedia: null,
      seen: null,
      isSender: true
   },
   {
      id: 2,
      message: "Hy",
      messageTime: "2:05 PM",
      imageMedia: null,
      videoMedia: null,
      seen: true,
      isSender: false
   },
   {
      id: 3,
      message: "How are you ?",
      messageTime: "2:06 PM",
      imageMedia: null,
      videoMedia: null,
      seen: null,
      isSender: true
   },
   {
      id: 4,
      message: "Yes all good, thanks for asking 😊, what about you!",
      messageTime: "2:07 PM",
      imageMedia: null,
      videoMedia: null,
      seen: true,
      isSender: false
   },
   {
      id: 5,
      message: "Am also good, Look at this.",
      messageTime: "2:08 PM",
      imageMedia: null,
      videoMedia: null,
      seen: null,
      isSender: true
   },
   {
      id: 6,
      message: null,
      messageTime: "2:08 PM",
      imageMedia: "https://images.unsplash.com/photo-1714659777146-5e908e89cfc0?q=80&w=1925&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      videoMedia: null,
      seen: null,
      isSender: true
   },
   {
      id: 7,
      message: "Nice",
      messageTime: "2:10 PM",
      imageMedia: null,
      videoMedia: null,
      seen: true,
      isSender: false
   },
   {
      id: 8,
      message: "It's looking very good, wait i have something to show you!!!",
      messageTime: "2:10 PM",
      imageMedia: null,
      videoMedia: null,
      seen: true,
      isSender: false
   },
   {
      id: 9,
      message: "Look",
      messageTime: "2:12 PM",
      imageMedia: "https://images.unsplash.com/photo-1708693111643-bcdfdc2b5f66?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      videoMedia: null,
      seen: true,
      isSender: false
   },
   {
      id: 10,
      message: "Cool",
      messageTime: "2:15 PM",
      imageMedia: null,
      videoMedia: null,
      seen: false,
      isSender: true
   },
   {
      id: 11,
      message: null,
      messageTime: "2:18 PM",
      imageMedia: null,
      videoMedia: "https://docs.material-tailwind.com/demo.mp4",
      seen: true,
      isSender: false
   },
];

export const joinedGroups = [
   {
      id: 1,
      imgUrl: "https://images.unsplash.com/photo-1524441952603-cdc2993d53eb?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      name: "The Nexus Squad",
      notifications: true
   },
   {
      id: 2,
      imgUrl: "https://images.unsplash.com/photo-1634116273986-686b0dadb799?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      name: "Hungry kitties",
      notifications: false
   },
   {
      id: 3,
      imgUrl: "https://images.unsplash.com/photo-1691737831091-3ea2bce65803?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      name: "Ferrari Group",
      notifications: true
   },
   {
      id: 4,
      imgUrl: "https://images.unsplash.com/photo-1485846234645-a62644f84728?q=80&w=2059&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      name: "Every Movie",
      notifications: true
   },
   {
      id: 5,
      imgUrl: "https://cdn.pixabay.com/photo/2024/10/04/15/22/ai-generated-9096698_1280.jpg",
      name: "Minds in Motion",
      notifications: false
   }
];

export const suggestedGroups = [
   {
      id: 1,
      imgUrl: "https://th.bing.com/th/id/OIP.SGVRKqgwdQNs2Z0Zt8Mb0QHaEo?rs=1&pid=ImgDetMain",
      name: "BMW Motorsport",
      members: "129K",
      membersDeatils: [
         {
            id: 1,
            name: "Emily Johnson",
            profileImage: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
         },
         {
            id: 2,
            name: "Michael Davis",
            profileImage: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
         },
         {
            id: 3,
            name: "Jessica Thompson",
            profileImage: "https://images.unsplash.com/photo-1500917293891-ef795e70e1f6?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
         }
      ]
   },
   {
      id: 2,
      imgUrl: "https://plus.unsplash.com/premium_photo-1683141154082-324d296f3c66?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      name: "Traders United",
      members: "78K",
      membersDeatils: [
         {
            id: 1,
            name: "Christopher Rodriguez",
            profileImage: "https://images.unsplash.com/photo-1527980965255-d3b416303d12?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
         },
         {
            id: 2,
            name: "Sarah Anderson",
            profileImage: "https://plus.unsplash.com/premium_photo-1664298528358-790433ba0815?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
         },
         {
            id: 3,
            name: "Joshua Martinez",
            profileImage: "https://images.unsplash.com/photo-1463453091185-61582044d556?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
         }
      ]
   },
   {
      id: 3,
      imgUrl: "https://plus.unsplash.com/premium_photo-1673108852141-e8c3c22a4a22?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      name: "Food Paradise",
      members: "842K",
      membersDeatils: [
         {
            id: 1,
            name: "Ashley Wilson",
            profileImage: "https://images.unsplash.com/photo-1557862921-37829c790f19?q=80&w=1771&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
         },
         {
            id: 2,
            name: "Matthew Taylor",
            profileImage: "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
         },
         {
            id: 3,
            name: "Jennifer Clark",
            profileImage: "https://images.unsplash.com/photo-1581803118522-7b72a50f7e9f?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
         }
      ]
   },
   {
      id: 4,
      imgUrl: "https://images.unsplash.com/photo-1581833971358-2c8b550f87b3?q=80&w=1771&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      name: "Retro Anime",
      members: "85.8K",
      membersDeatils: [
         {
            id: 1,
            name: "David White",
            profileImage: "https://images.unsplash.com/photo-1576110598658-096ae24cdb97?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
         },
         {
            id: 2,
            name: "Brittany Jackson",
            profileImage: "https://images.unsplash.com/photo-1542178243-bc20204b769f?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
         },
         {
            id: 3,
            name: "Daniel Harris",
            profileImage: "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
         }
      ]
   }
   ,
   {
      id: 5,
      imgUrl: "https://images.unsplash.com/photo-1704352527439-899e6de844ec?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      name: "DRIVERS",
      members: "752.89K",
      membersDeatils: [
         {
            id: 1,
            name: "Samantha Carter",
            profileImage: "https://plus.unsplash.com/premium_photo-1688350839154-1a131bccd78a?q=80&w=1769&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
         },
         {
            id: 2,
            name: "Ryan Miller",
            profileImage: "https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
         },
         {
            id: 3,
            name: "Amanda Lee",
            profileImage: "https://images.unsplash.com/photo-1558898479-33c0057a5d12?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
         }
      ]
   }
];

export const groupPostData = [
   {
      id: 1,
      userName: "Sara Lee",
      userTagname: "@saralee",
      userProfileImage: "https://images.unsplash.com/photo-1532074205216-d0e1f4b87368?q=80&w=1941&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      postTime: "1d",
      postContent: "Nature walk this morning. So refreshing!",
      postImage: null,
      postLikes: 45,
      postComments: 20,
      postRepost: 15,
      postImpression: "5k",
   },
   {
      id: 2,
      userName: "John Smith",
      userTagname: "@johnsmith",
      userProfileImage: "https://images.unsplash.com/photo-1678286742832-26543bb49959?q=80&w=1888&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      postTime: "4h",
      postContent: "Loving the beach vibes!",
      postImage: "https://cdn.pixabay.com/photo/2013/02/21/19/06/drink-84533_1280.jpg",
      postLikes: 30,
      postComments: 10,
      postRepost: 8,
      postImpression: "3k",
   },
   {
      id: 3,
      userName: "Emily Johnson",
      userTagname: "@emilyjohn",
      userProfileImage: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      postTime: "2h",
      postContent: "Night list",
      postImage: "https://cdn.pixabay.com/photo/2021/11/30/00/54/sweden-6834164_1280.jpg",
      postLikes: 12,
      postComments: 5,
      postRepost: 3,
      postImpression: "1.5k",
   },
   {
      id: 4,
      userName: "Michael Brown",
      userTagname: "@mikebrown",
      userProfileImage: "https://images.unsplash.com/photo-1672863601285-253fc82db868?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      postTime: "3d",
      postContent: "Sunset view from the hills",
      postImage: null,
      postLikes: 60,
      postComments: 25,
      postRepost: 20,
      postImpression: "7k",
   },
   {
      id: 5,
      userName: "Lisa Wong",
      userTagname: "@lisawong",
      userProfileImage: "https://images.unsplash.com/photo-1529626455594-4ff0802cfb7e?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      postTime: "5d",
      postContent: "Mountain adventure! 🏔️",
      postImage: "https://cdn.pixabay.com/photo/2020/07/14/16/18/snow-5404785_1280.jpg",
      postLikes: 75,
      postComments: 35,
      postRepost: 25,
      postImpression: "10k",
   }
];

export const groupUserData = [
   {
      id: 1,
      userName: "Sara Lee",
      userTagname: "@saralee",
      userProfileImage: "https://images.unsplash.com/photo-1499887142886-791eca5918cd?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      userAbout: "Passionate about sustainable living and organic farming. Dedicated to making the world a greener place, one plant at a time.",
      moderators: true,
   },
   {
      id: 2,
      userName: "John Smith",
      userTagname: "@johnsmith",
      userProfileImage: "https://images.unsplash.com/photo-1678286742832-26543bb49959?q=80&w=1888&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      userAbout: "Tech enthusiast and avid gamer. Loves exploring new technologies and spending hours in immersive game worlds.",
      moderators: false,
   },
   {
      id: 3,
      userName: "Emily Johnson",
      userTagname: "@emilyjohn",
      userProfileImage: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      userAbout: "Lover of all things art and design. Enjoys creating beautiful visual stories and bringing ideas to life.",
      moderators: false,
   },
   {
      id: 4,
      userName: "Michael Brown",
      userTagname: "@mikebrown",
      userProfileImage: "https://images.unsplash.com/photo-1672863601285-253fc82db868?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      userAbout: "Fitness coach and nutrition expert. Helps people achieve their fitness goals through personalized plans and motivation.",
      moderators: false,
   },
   {
      id: 5,
      userName: "Lisa Wong",
      userTagname: "@lisawong",
      userProfileImage: "https://images.unsplash.com/photo-1529626455594-4ff0802cfb7e?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      userAbout: "Travel blogger and adventure seeker. Explores new places, cultures, and experiences to share with her followers.",
      moderators: true,
   },
   {
      id: 6,
      userName: "David Clark",
      userTagname: "@davidclark",
      userProfileImage: "https://plus.unsplash.com/premium_photo-1671656349322-41de944d259b?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      userAbout: "History buff and bookworm. Loves delving into historical events and discovering how they shape the present.",
      moderators: false,
   },
   {
      id: 7,
      userName: "Sophia Martinez",
      userTagname: "@sophiamartinez",
      userProfileImage: "https://images.unsplash.com/photo-1517677129300-07b130802f46?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      userAbout: "Photographer capturing the world's beauty. Strives to show the unseen and tell stories through her lens.",
      moderators: true,
   },
   {
      id: 8,
      userName: "James Wilson",
      userTagname: "@jameswilson",
      userProfileImage: "https://images.unsplash.com/photo-1544005313-94ddf0286df2?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      userAbout: "Engineer with a passion for robotics. Enjoys building and programming robots to solve real-world problems.",
      moderators: false,
   },
   {
      id: 9,
      userName: "Olivia Green",
      userTagname: "@oliviagreen",
      userProfileImage: "https://images.unsplash.com/photo-1532073150508-0c1df022bdd1?q=80&w=1981&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      userAbout: "Chef experimenting with global cuisines. Blends traditional techniques with modern twists to create unique dishes.",
      moderators: true,
   },
   {
      id: 10,
      userName: "William Johnson",
      userTagname: "@willjohnson",
      userProfileImage: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      userAbout: "Music producer and DJ. Creates beats that make people move and feel the rhythm.",
      moderators: false,
   },
   {
      id: 11,
      userName: "Ava Taylor",
      userTagname: "@avataylor",
      userProfileImage: "https://images.unsplash.com/photo-1529626455594-4ff0802cfb7e?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      userAbout: "Graphic designer and illustrator. Passionate about visual storytelling and bringing ideas to life through art.",
      moderators: false,
   },
   {
      id: 12,
      userName: "Lucas Walker",
      userTagname: "@lucaswalker",
      userProfileImage: "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      userAbout: "Software developer and coding mentor. Enjoys teaching others and contributing to open source projects.",
      moderators: true,
   },
   {
      id: 13,
      userName: "Mia Roberts",
      userTagname: "@miaroberts",
      userProfileImage: "https://images.unsplash.com/photo-1542596594-649edbc13630?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      userAbout: "Entrepreneur and business strategist. Helps startups grow and achieve their full potential through innovative strategies.",
      moderators: false,
   },
   {
      id: 14,
      userName: "Charlotte King",
      userTagname: "@charlotteking",
      userProfileImage: "https://images.unsplash.com/photo-1544005313-94ddf0286df2?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      userAbout: "Marketing expert with a creative flair. Develops campaigns that capture attention and drive engagement.",
      moderators: true,
   },
   {
      id: 15,
      userName: "Daniel Lee",
      userTagname: "@daniellee",
      userProfileImage: "https://images.unsplash.com/photo-1517841905240-472988babdf9?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      userAbout: "Outdoor enthusiast and nature photographer. Captures the beauty of the natural world through his camera.",
      moderators: false,
   }
];

export const commonPostData = [
   {
      id: 1,
      userName: "Sara Lee",
      userTagname: "@saralee",
      userProfileImage: "https://images.unsplash.com/photo-1532074205216-d0e1f4b87368?q=80&w=1941&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      postTime: "1h",
      postContent: "Nature walk this morning. So refreshing! Go outside from your and take some fresh air!!",
      postImage: null,
      postLikes: 45,
      postComments: 20,
      postRepost: 15,
      postImpression: "5k",
   },
   {
      id: 2,
      userName: "John Smith",
      userTagname: "@johnsmith",
      userProfileImage: "https://images.unsplash.com/photo-1678286742832-26543bb49959?q=80&w=1888&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      postTime: "4h",
      postContent: "Look at this ninja mask!",
      postImage: "https://images.unsplash.com/photo-1656538576466-eab5e63478f6?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      postLikes: 30,
      postComments: 10,
      postRepost: 8,
      postImpression: "3k",
   },
   {
      id: 3,
      userName: "Emily Johnson",
      userTagname: "@emilyjohn",
      userProfileImage: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      postTime: "10h",
      postContent: "Night list",
      postImage: "https://images.unsplash.com/photo-1511919884226-fd3cad34687c?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      postLikes: 12,
      postComments: 5,
      postRepost: 3,
      postImpression: "1.5k",
   },
   {
      id: 4,
      userName: "Michael Brown",
      userTagname: "@mikebrown",
      userProfileImage: "https://images.unsplash.com/photo-1672863601285-253fc82db868?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      postTime: "3d",
      postContent: "Sunset view from the hills",
      postImage: null,
      postLikes: 60,
      postComments: 25,
      postRepost: 20,
      postImpression: "7k",
   },
   {
      id: 5,
      userName: "Lisa Wong",
      userTagname: "@lisawong",
      userProfileImage: "https://images.unsplash.com/photo-1529626455594-4ff0802cfb7e?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      postTime: "5d",
      postContent: "Mountain adventure! 🏔️",
      postImage: "https://images.unsplash.com/photo-1721297014475-42f2e99d3271?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      postLikes: 75,
      postComments: 35,
      postRepost: 25,
      postImpression: "10k",
   }
];

export const ForYouData = [
   {
      id: 1,
      userName: "Alex Turner",
      userTagname: "@alexturner",
      userProfileImage: "https://images.unsplash.com/photo-1517841905240-472988babdf9?q=80&w=2000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      postTime: "2h",
      postContent: "Finally finished my book. What a journey!",
      postImage: null,
      postLikes: 65,
      postComments: 30,
      postRepost: 12,
      postImpression: "8k",
   },
   {
      id: 2,
      userName: "Mia Garcia",
      userTagname: "@miagarcia",
      userProfileImage: "https://images.unsplash.com/photo-1544005313-94ddf0286df2?q=80&w=1888&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      postTime: "1d",
      postContent: "Homemade pasta for dinner tonight. Yum!",
      postImage: "https://images.unsplash.com/photo-1585672840829-d4ed3abbfb27?q=80&w=1902&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      postLikes: 150,
      postComments: 45,
      postRepost: 35,
      postImpression: "15k",
   },
   {
      id: 3,
      userName: "Chris Evans",
      userTagname: "@chrisevans",
      userProfileImage: "https://images.unsplash.com/photo-1531477566015-0d112e6d65b0?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      postTime: "3d",
      postContent: "Morning jog by the river. Feeling energized!",
      postImage: null,
      postLikes: 95,
      postComments: 40,
      postRepost: 28,
      postImpression: "12k",
   },
   {
      id: 4,
      userName: "Olivia Lee",
      userTagname: "@olivialee",
      userProfileImage: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=80&w=2000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      postTime: "4h",
      postContent: "Exploring the city’s hidden gems.",
      postImage: "https://images.unsplash.com/photo-1477959858617-67f85cf4f1df?q=80&w=1888&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      postLikes: 80,
      postComments: 25,
      postRepost: 20,
      postImpression: "10k",
   },
   {
      id: 5,
      userName: "Daniel Kim",
      userTagname: "@danielkim",
      userProfileImage: "https://images.unsplash.com/photo-1599566150163-29194dcaad36?q=80&w=2000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      postTime: "5d",
      postContent: "Lazy Sunday with a cup of coffee.",
      postImage: "https://images.unsplash.com/photo-1456324463128-7ff6903988d8?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      postLikes: 110,
      postComments: 55,
      postRepost: 40,
      postImpression: "18k",
   }
];


export const trendingPostData = [
   {
      category: "Entertainment",
      sale_price: "30.95",
      name: "Movie Kit",
      description: "A complete kit for an ultimate movie night experience.",
      original_price: "40.95",
      image: "https://images.unsplash.com/photo-1478720568477-152d9b164e26?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8bW92aWUlMjBraXR8ZW58MHx8MHx8fDA%3D",
      offer_percentage: 75, ratings: "4.5"
   },
   {
      category: "Home",
      sale_price: "74",
      name: "Gardening Whatchamacallit",
      description: "Essential tools and gadgets for your gardening needs.",
      original_price: "89.99",
      image: "https://images.unsplash.com/photo-1615671524827-c1fe3973b648?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8R2FyZGVuaW5nJTIwV2hhdGNoYW1hY2FsbGl0fGVufDB8fDB8fHww",
      offer_percentage: 75, ratings: "4.7"
   },
   {
      category: "Activity",
      sale_price: "32.99",
      name: "Camping Equipment",
      description: "Reliable equipment for your next outdoor adventure.",
      original_price: "39.99",
      image: "https://images.unsplash.com/photo-1632088817790-2426c8535ef3?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fGNhbXBpbmclMjBlcXVpcG1lbnR8ZW58MHx8MHx8fDA%3D",
      offer_percentage: 75, ratings: "4.3"
   },
   {
      category: "Health",
      sale_price: "72.95",
      name: "Vitamin Kit",
      description: "A comprehensive kit with essential vitamins for daily health.",
      original_price: "89.95",
      image: "https://plus.unsplash.com/premium_photo-1664456804212-1592c91329bf?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8Vml0YW1pbiUyMEtpdHxlbnwwfHwwfHx8MA%3D%3D",
      offer_percentage: 75, ratings: "4.8"
   },
   {
      category: "Automotive",
      sale_price: "80.99",
      name: "Truck Gadget",
      description: "Innovative gadget to enhance your truck's functionality.",
      original_price: "99.99",
      image: "https://images.unsplash.com/photo-1591419478162-a4dd21b7ec0a?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8VHJ1Y2slMjBHYWRnZXR8ZW58MHx8MHx8fDA%3D",
      offer_percentage: 75, ratings: "4.6"
   },
   {
      category: "Beauty",
      sale_price: "73.95",
      name: "Skin Care Component",
      description: "Premium components for flawless skin care.",
      original_price: "89.95",
      image: "https://images.unsplash.com/photo-1556228852-6bf19d1b1fc5?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8U2tpbiUyMENhcmUlMjBDb21wb25lbnR8ZW58MHx8MHx8fDA%3D",
      offer_percentage: 75, ratings: "4.4"
   },
   {
      category: "Home",
      sale_price: "74",
      name: "Gardening Whatchamacallit",
      description: "Essential tools and gadgets for your gardening needs.",
      original_price: "89.99",
      image: "https://images.unsplash.com/photo-1615671524827-c1fe3973b648?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8R2FyZGVuaW5nJTIwV2hhdGNoYW1hY2FsbGl0fGVufDB8fDB8fHww",
      offer_percentage: 75, ratings: "4.7"
   },
   {
      category: "Activity",
      sale_price: "32.99",
      name: "Camping Equipment",
      description: "Reliable equipment for your next outdoor adventure.",
      original_price: "39.99",
      image: "https://images.unsplash.com/photo-1632088817790-2426c8535ef3?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fGNhbXBpbmclMjBlcXVpcG1lbnR8ZW58MHx8MHx8fDA%3D",
      offer_percentage: 75, ratings: "4.3"
   },
   {
      category: "Health",
      sale_price: "72.95",
      name: "Vitamin Kit",
      description: "A comprehensive kit with essential vitamins for daily health.",
      original_price: "89.95",
      image: "https://plus.unsplash.com/premium_photo-1664456804212-1592c91329bf?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8Vml0YW1pbiUyMEtpdHxlbnwwfHwwfHx8MA%3D%3D",
      offer_percentage: 75, ratings: "4.8"
   },
   {
      category: "Automotive",
      sale_price: "80.99",
      name: "Truck Gadget",
      description: "Innovative gadget to enhance your truck's functionality.",
      original_price: "99.99",
      image: "https://images.unsplash.com/photo-1591419478162-a4dd21b7ec0a?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8VHJ1Y2slMjBHYWRnZXR8ZW58MHx8MHx8fDA%3D",
      offer_percentage: 75, ratings: "4.6"
   },
   {
      category: "Beauty",
      sale_price: "73.95",
      name: "Skin Care Component",
      description: "Premium components for flawless skin care.",
      original_price: "89.95",
      image: "https://images.unsplash.com/photo-1556228852-6bf19d1b1fc5?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8U2tpbiUyMENhcmUlMjBDb21wb25lbnR8ZW58MHx8MHx8fDA%3D",
      offer_percentage: 75, ratings: "4.4"
   }
]


 export const groupsTypes = [
   { id: 1, name: "Professional" },
   { id: 2, name: "Educational" },
   { id: 3, name: "Community" },
   { id: 4, name: "Event" },
   { id: 5, name: "Marketplace" },
   { id: 6, name: "Gaming" },
   { id: 7, name: "Health & Fitness" },
   { id: 8, name: "Travel & Adventure" },
   { id: 9, name: "Art & Creativity" },
   { id: 10, name: "Environmental & Sustainability" },
   { id: 11, name: "Religious or Spiritual" },
   { id: 12, name: "Political or Advocacy" },
   { id: 13, name: "Language Learning" },
   { id: 14, name: "Book Club" },
   { id: 15, name: "Music & Entertainment" },
   { id: 16, name: "Animal Lovers" },
   { id: 17, name: "Automobile" },
   { id: 18, name: "Finance & Investment" },
   { id: 19, name: "Entrepreneurship" },
   { id: 20, name: "Hiking & Outdoor" },
   { id: 21, name: "Fashion & Beauty" },
   { id: 22, name: "Science & Technology" },
   { id: 23, name: "Health and Hygiene" },
   { id: 24, name: "Photography" },
   { id: 25, name: "Food & Cooking" },
   { id: 26, name: "Parenting" }
]
