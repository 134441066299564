import React, { Fragment, useEffect, useRef, useState } from "react";
import { post } from "../data";
import { LuDot } from "react-icons/lu";
import Carousel from "react-multi-carousel";
import { PiRecordFill } from "react-icons/pi";
import { FaMicrophone } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import MainSearch from "../components/searchMain/MainSearch";
import { IoCaretBackCircle, IoClose } from "react-icons/io5";
import NewsSearch from "../components/searchMain/NewsSearch";
import HealthSearch from "../components/searchMain/HealthSearch";
import PopularSearch from "../components/searchMain/PopularSearch";
import EntertainmentSearch from "../components/searchMain/EntertainmentSearch";
import { clearRecentSearch, clearSearchHistory, deleteSearchHistory, getSearchHistory, searchQuery } from "../redux/apislice/mainSearchSlice";
import { useDispatch, useSelector } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";


export default function SearchMainPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState("Main");
    const [search, setSearch] = useState(null);
    const [query, setQuery] = useState('');
    const [isListening, setIsListening] = useState(false);
    const [debouncedQuery, setDebouncedQuery] = useState(query);
    const debounceTimeoutRef = useRef(null);

    const mainSearch = useSelector((state) => state.mainSearch)
    const profile = useSelector((state) => state.profile)

    const [searchHistory, setSearchHistory] = useState([])
    const [searchData, setSearchData] = useState([])

    useEffect(() => {
        setSearchData(mainSearch.data)
    }, [mainSearch.data])


    useEffect(() => {
        setSearchHistory(mainSearch.history)
    }, [mainSearch.history])
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2.5,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 3,
            slidesToSlide: 1
        }
    };

    const Category = [
        { id: 2, name: "Popular" },
        { id: 9, name: "Health" },
        { id: 10, name: "News" },
        { name: "Entertainment", id: 19 },
    ];

    //api calls 

    const getHistory = async () => {
        try {
            const response = await dispatch(getSearchHistory())
            const data = await response.json();
      
        } catch (error) {
            console.log("Error", error);
        }
    }


    const handleFocus = () => {
        setSearch(true);
        getHistory()
    };

    const handleBlur = () => {
        setSearch(false);
        setQuery("")
        setDebouncedQuery("")
        // dispatch(clearRecentSearch());

    };

    const handleVoiceInput = () => {
        if (!('webkitSpeechRecognition' in window)) {
            alert('Your browser does not support speech recognition.');
            return;
        }

        const recognition = new window.webkitSpeechRecognition();
        recognition.continuous = false;
        recognition.interimResults = false;
        recognition.lang = 'en-US';

        recognition.onstart = () => {
            setIsListening(true);
        };

        recognition.onresult = (event) => {
            const speechResult = event.results[0][0].transcript;
            setQuery(speechResult);
            setIsListening(false);
            recognition.stop();
        };

        recognition.onerror = (event) => {
            console.error('Speech recognition error:', event.error);
            setIsListening(false);
            recognition.stop();
        };

        recognition.onend = () => {
            setIsListening(false);
        };

        recognition.start();
    };

    // const handleChange = async (e) => {
    //     setQuery(e.target.value);
    //     try {
    //         const response = await dispatch(searchQuery({ data: { q: query } }))
    //         const data = await response.json();
    //         console.log(data);
    //     } catch (error) {
    //         console.log("Error", error);
    //     }

    // };



    const handleChange = (e) => {
        setQuery(e.target.value);
    };

    useEffect(() => {
        // Clear the previous timeout
        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
        }

        // Set a new timeout
        debounceTimeoutRef.current = setTimeout(() => {
            setDebouncedQuery(query);
        }, 1000); // Adjust the debounce delay as needed (500ms in this example)

        // Cleanup function to clear the timeout if the component unmounts
        return () => {
            if (debounceTimeoutRef.current) {
                clearTimeout(debounceTimeoutRef.current);
            }
        };
    }, [query]);

    /* clear all history */
    const handleClearHistory = async () => {
        try {
            await dispatch(clearSearchHistory())
            setSearchHistory([])
        } catch (error) {
            console.log("Error", error);
        }
    }

    /* delete single history  */
    const handleDeleteHistory = async (id) => {
        try {
            await dispatch(deleteSearchHistory({ id }))
            setSearchHistory((prev) => prev.filter((data) => data.id !== id))
        } catch (error) {
            console.log("Error", error);
        }
    }


    const handleNavigate = (user_id) => {
        if (user_id === profile?.user?.id) {
            navigate("/account");
        } else {
            navigate(`/otheruser/account/${user_id}`);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const data = { q: debouncedQuery };
            if (debouncedQuery) {
                try {
                    const response = await dispatch(searchQuery({ data }));
                    const data1 = await response.json();
            
                } catch (error) {
                    console.log("Error", error);
                }
            }
        };
        fetchData()
        return (()=> dispatch(clearRecentSearch()))
    }, [debouncedQuery, dispatch]);

    return (
        <Fragment>
            <div className="text-center bg-[#d9d9d9]">
                <p className="text-red-700 text-[10px] uppercase font-bold">
                    C / FE / SEARCH {activeTab} PAGE / V2 / SEP 16, 2024
                </p>
             
            </div>

            <div className="grid grid-cols-12 bg-white mt-2 px-2">
                <div className="col-span-1 flex justify-start items-center">
                    <IoCaretBackCircle
                        onClick={() => navigate(-1)}
                        className="text-[1.5rem] text-black cursor-pointer"
                    />
                </div>
                <div className={`${search ? "col-span-10" : "col-span-11"} relative ml-2`}>
                    <input
                        type="text"
                        value={query}
                        // onBlur={handleBlur}
                        onFocus={handleFocus}
                        onChange={handleChange}
                        placeholder="Search..."
                        className="w-full border-[1px] border-gray-400 focus:outline-none placeholder:text-[14px] focus:ring-0 rounded-full bg-white px-2 py-[3px] pr-6"
                    />
                    {isListening ?
                        <PiRecordFill
                            className="absolute right-2 top-[8px] text-[1rem] cursor-pointer text-red-500"
                        />
                        :
                        <FaMicrophone
                            onClick={handleVoiceInput}
                            className="absolute right-2 top-[8px] text-[1rem] cursor-pointer text-gray-500"
                        />
                    }

                </div>
                {search &&
                    <div onClick={handleBlur} className="col-span-1 flex justify-end items-center">
                        <IoClose className="text-red-700 text-[1.3rem] cursor-pointer" />
                    </div>
                }
            </div>

            {!search &&
                <>
                    <div className="grid grid-cols-12 bg-white mt-1 px-2 sticky top-0 z-50">
                        <div className="col-span-12">
                            <div className="ml-[-1.5px]">
                                <Carousel
                                    arrows={true}
                                    swipeable={true}
                                    draggable={true}
                                    showDots={false}
                                    responsive={responsive}
                                    ssr={true}
                                    infinite={false}
                                    slidesToSlide={1}
                                    removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                                >
                                    {Category.map((data) => (
                                        <div className={`w-auto px-[6px] mx-1 py-[3px] cursor-pointer my-1 text-center text-gray-800 rounded-full ${activeTab === data.name ? "bg-blue-500 text-white rounded-full" : "bg-black rounded-full text-white"}`} key={data.id}>
                                            <p
                                                onClick={() => setActiveTab(data.name)}
                                                className={`cursor-pointer text-[12px] px-1`}
                                            >
                                                {data.name}
                                            </p>
                                        </div>
                                    ))}
                                </Carousel>
                            </div>

                        </div>
                    </div>
                    <hr className="w-full mt-[2px] mb-[2px] h-[0.5px] bg-[#c9c9c9] border-0" />
                </>
            }
            {search && <div className="grid grid-cols-12 bg-white mt-2 px-2">
                {mainSearch.loading ?
                    <div className="col-span-12 flex justify-center items-center">
                        <BeatLoader
                            loading={mainSearch.loading} color="black"
                        />
                    </div>
                    : <div className="col-span-12 flex flex-wrap gap-4 overflow-y-scroll">
                        <div className="mt-1 w-full">
                            {searchData?.map((data) => (
                                <div
                                    key={data.id}
                                    className="flex gap-2 mb-1 cursor-pointer py-1"
                                >
                                    <img
                                        alt={data.first_name}
                                        src={data.avatar}
                                        className="h-8 w-8 rounded-full object-cover"
                                        onClick={() => handleNavigate(data.id)}
                                    />
                                    <div className="flex flex-col">
                                        <div className="flex items-center" >
                                            <div className="flex flex-col items-start">
                                                <div className="flex items-center">
                                                    <p className="text-sm font-bold" onClick={() => handleNavigate(data.id)}>{data.first_name}</p>
                                                </div>
                                                <p className="text-xs mt-[-2px] text-gray-500" onClick={() => handleNavigate(data.id)}>{data.username}</p>
                                            </div>
                                        </div>
                                        {/* <p className="text-[10px] text-gray-600 leading-3 line-clamp-2 mr-4">{data.postMessage}</p> */}
                                    </div>
                                </div>
                            ))}
                            {!searchData?.length && <p className="text-[10px] flex justify-center w-full items-center  text-center text-gray-600 mt-2">Search No results for "{query}"</p>}
                        </div>
                    </div>}
            </div>
            }

            {search && searchData.length === 0 &&
                <div className="grid grid-cols-12 bg-white mt-2 px-2">
                    {searchHistory?.length > 0 && <>
                        <h2 className="col-span-4 font-bold">History</h2>
                        <div className="col-span-4"></div>
                        <div className="col-span-4 text-[12px] text-light-blue-600 flex justify-end items-end cursor-pointer" onClick={handleClearHistory}> Clear all</div>

                        <div className="col-span-12 gap-2 mt-1">
                            {
                                searchHistory.map((data) => (
                                    <div className="flex mt-1 justify-between items-center">
                                        <p
                                            key={data.id}
                                            className="bg-gray-300 rounded-full px-3 py-1 text-[12px] cursor-pointer relative"
                                        >
                                            {data.query}
                                        </p>
                                        <IoClose onClick={() => handleDeleteHistory(data.id)} className="text-[1rem] cursor-pointer" />
                                    </div>

                                ))
                            }
                        </div>
                    </>}
                </div>
            }

            {search && searchData.length === 0 &&
                <div className="grid grid-cols-12 bg-white mt-2 px-2 mb-24">
                    <h2 className="col-span-12 font-bold mt-1">Recommended</h2>
                    <div className="col-span-12 flex flex-wrap gap-4 overflow-y-scroll">
                        <div className="mt-1">
                            {post.map((data) => (
                                <div
                                    key={data.id}
                                    className="flex gap-2 mb-1 cursor-pointer py-1"
                                >
                                    <img
                                        alt={data.userName}
                                        src={data.profileImage}
                                        className="h-8 w-8 rounded-full object-cover"
                                    />
                                    <div className="flex flex-col">
                                        <div className="flex items-center" >
                                            <div className="flex flex-col items-start">
                                                <div className="flex items-center">
                                                    <p className="text-sm font-bold" >{data.firstName}</p>
                                                    <LuDot className="h-3 w-3 text-gray-600" />
                                                    <p className="text-[10px] text-light-blue-600">{data.postTimeAgo}</p>
                                                </div>
                                                <p className="text-xs mt-[-2px] text-gray-500">{data.userName}</p>
                                            </div>
                                        </div>
                                        <p className="text-[10px] text-gray-600 leading-3 line-clamp-2 mr-4">{data.postMessage}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            }

            {activeTab === "Main" && !search && <MainSearch />}
            {activeTab === "Popular" && !search && <PopularSearch />}
            {activeTab === "Health" && !search && <HealthSearch />}
            {activeTab === "News" && !search && <NewsSearch />}
            {activeTab === "Entertainment" && !search && <EntertainmentSearch />}
        </Fragment>
    )
}

