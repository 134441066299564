import React, { Fragment, useEffect, useState } from "react";
import { LuDot } from "react-icons/lu";
import { FaRegComment, FaRegHeart } from "react-icons/fa";
import { LuRepeat2 } from "react-icons/lu";
import { CiBookmark } from "react-icons/ci";
import { FiBarChart2 } from "react-icons/fi";
import { AiOutlineShareAlt } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { groupPostData } from "../../../data";

const AccountOwnerGroupMedia = ({ onShare, mediaData, getRelativeTime }) => {
    const navigate = useNavigate();
    const [filterData, setFilterData] = useState([]);

    useEffect(() => {
        setFilterData(mediaData);
    }, [mediaData]);

    return (
        <Fragment>
            <div className="px-0">
                {filterData?.map((data) => (
                    <div className="px-2 py-1 grid grid-cols-12 border-b-[0.4px] border-[#b2b2b2]" key={data.id}>
                        <div className="col-span-2">
                            <img
                                alt={data.created_by.first_name}
                                src={data.created_by.avatar}
                                className="h-8 w-8 rounded-full object-cover"
                            />
                        </div>
                        <div className="col-span-10 -ml-2">
                            <div className="flex flex-col">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center gap-2">
                                        <p className="text-black text-[12px] font-bold">{data.created_by.first_name} {data.created_by.last_name}</p>
                                        <div className="flex items-center">
                                            <p className="text-[#5c5c5c] text-[12px] font-thin">{data.created_by.username}</p>
                                            <LuDot className="text-[#5c5c5c] text-[12px]" />
                                            <p className="text-[#5c5c5c] text-[12px] font-thin">{getRelativeTime(data.created_at)}</p>
                                        </div>
                                    </div>
                                    <BsThreeDotsVertical
                                        className="text-[#5c5c5c] cursor-pointer absolute right-0"
                                    />
                                </div>
                                <p className="text-black text-[12px]">{data.body}</p>
                            </div>
                            <img
                                alt={data.image}
                                src={data.image}
                                className="w-full h-auto object-cover rounded-[6px]"
                            />
                            <div className="flex items-center justify-between mt-2 text-[#4d4d4d]">
                                <div className="flex items-center cursor-pointer gap-1" onClick={() => navigate("/account/owner/group/comment")}>
                                    <FaRegComment className="text-[14px]" />
                                    <p className="text-[12px]">{data.postComments}</p>
                                </div>
                                <div className="flex items-center cursor-pointer gap-1">
                                    <FaRegHeart className="text-[14px]" />
                                    <p className="text-[12px]">{data.postLikes}</p>
                                </div>
                                <div className="flex items-center cursor-pointer gap-1">
                                    <LuRepeat2 className="text-[14px]" />
                                    <p className="text-[12px]">{data.postRepost}</p>
                                </div>
                                <div className="flex items-center cursor-pointer gap-1">
                                    <FiBarChart2 className="text-[14px]" />
                                    <p className="text-[12px]">{data.postImpression}</p>
                                </div>
                                <div className="flex items-center gap-2">
                                    <CiBookmark className="text-[14px] cursor-pointer" />
                                    <AiOutlineShareAlt
                                        className="text-[14px] cursor-pointer"
                                        onClick={() => onShare("https://www.media.share.com")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}

                {filterData.length === 0 && (
                    <div className="flex justify-center items-start h-[30vh]">
                        <p className="text-[#5c5c5c] text-[14px]">No Media available</p>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default AccountOwnerGroupMedia;

